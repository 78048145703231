/*!****************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/styles.scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************/
@charset "UTF-8";
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: rgb(5.2, 44, 101.2);
  --bs-secondary-text-emphasis: rgb(43.2, 46.8, 50);
  --bs-success-text-emphasis: rgb(10, 54, 33.6);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(102, 77.2, 2.8);
  --bs-danger-text-emphasis: rgb(88, 21.2, 27.6);
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: rgb(206.6, 226, 254.6);
  --bs-secondary-bg-subtle: rgb(225.6, 227.4, 229);
  --bs-success-bg-subtle: rgb(209, 231, 220.8);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(255, 242.6, 205.4);
  --bs-danger-bg-subtle: rgb(248, 214.6, 217.8);
  --bs-light-bg-subtle: rgb(251.5, 252, 252.5);
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: rgb(158.2, 197, 254.2);
  --bs-secondary-border-subtle: rgb(196.2, 199.8, 203);
  --bs-success-border-subtle: rgb(163, 207, 186.6);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(255, 230.2, 155.8);
  --bs-danger-border-subtle: rgb(241, 174.2, 180.6);
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: rgb(10.4, 88, 202.4);
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: rgb(109.8, 168, 253.8);
  --bs-secondary-text-emphasis: rgb(166.8, 172.2, 177);
  --bs-success-text-emphasis: rgb(117, 183, 152.4);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(255, 217.8, 106.2);
  --bs-danger-text-emphasis: rgb(234, 133.8, 143.4);
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: rgb(2.6, 22, 50.6);
  --bs-secondary-bg-subtle: rgb(21.6, 23.4, 25);
  --bs-success-bg-subtle: rgb(5, 27, 16.8);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(51, 38.6, 1.4);
  --bs-danger-bg-subtle: rgb(44, 10.6, 13.8);
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: rgb(7.8, 66, 151.8);
  --bs-secondary-border-subtle: rgb(64.8, 70.2, 75);
  --bs-success-border-subtle: rgb(15, 81, 50.4);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(153, 115.8, 4.2);
  --bs-danger-border-subtle: rgb(132, 31.8, 41.4);
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(109.8, 168, 253.8);
  --bs-link-hover-color: rgb(138.84, 185.4, 254.04);
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 226, 254.6);
  --bs-table-border-color: rgb(165.28, 180.8, 203.68);
  --bs-table-striped-bg: rgb(196.27, 214.7, 241.87);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 203.4, 229.14);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 209.05, 235.505);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(225.6, 227.4, 229);
  --bs-table-border-color: rgb(180.48, 181.92, 183.2);
  --bs-table-striped-bg: rgb(214.32, 216.03, 217.55);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(203.04, 204.66, 206.1);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(208.68, 210.345, 211.825);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(209, 231, 220.8);
  --bs-table-border-color: rgb(167.2, 184.8, 176.64);
  --bs-table-striped-bg: rgb(198.55, 219.45, 209.76);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(188.1, 207.9, 198.72);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(193.325, 213.675, 204.24);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(165.28, 195.52, 201.6);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(255, 242.6, 205.4);
  --bs-table-border-color: rgb(204, 194.08, 164.32);
  --bs-table-striped-bg: rgb(242.25, 230.47, 195.13);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(229.5, 218.34, 184.86);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(235.875, 224.405, 189.995);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgb(248, 214.6, 217.8);
  --bs-table-border-color: rgb(198.4, 171.68, 174.24);
  --bs-table-striped-bg: rgb(235.6, 203.87, 206.91);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 193.14, 196.02);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 198.505, 201.465);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: rgb(198.4, 199.2, 200);
  --bs-table-striped-bg: rgb(235.6, 236.55, 237.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 224.1, 225);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 230.325, 231.25);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: rgb(77.4, 80.6, 83.8);
  --bs-table-striped-bg: rgb(44.1, 47.9, 51.7);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgb(55.2, 58.8, 62.4);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgb(49.65, 53.35, 57.05);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: rgb(134, 182.5, 254);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgb(134, 182.5, 254);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23dee2e6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgb(134, 182.5, 254);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10h8%27/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgb%28134, 182.5, 254%29%27/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 0.25%29%27/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(182.4, 211.5, 254.4);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
       appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(182.4, 211.5, 254.4);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(11.05, 93.5, 215.05);
  --bs-btn-hover-border-color: rgb(10.4, 88, 202.4);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(10.4, 88, 202.4);
  --bs-btn-active-border-color: rgb(9.75, 82.5, 189.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(91.8, 99.45, 106.25);
  --bs-btn-hover-border-color: rgb(86.4, 93.6, 100);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(86.4, 93.6, 100);
  --bs-btn-active-border-color: rgb(81, 87.75, 93.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(21.25, 114.75, 71.4);
  --bs-btn-hover-border-color: rgb(20, 108, 67.2);
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(20, 108, 67.2);
  --bs-btn-active-border-color: rgb(18.75, 101.25, 63);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(49.3, 209.95, 242.25);
  --bs-btn-hover-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(61.4, 212.6, 243);
  --bs-btn-active-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 202.3, 44.2);
  --bs-btn-hover-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 205.4, 56.6);
  --bs-btn-active-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(187, 45.05, 58.65);
  --bs-btn-hover-border-color: rgb(176, 42.4, 55.2);
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(176, 42.4, 55.2);
  --bs-btn-active-border-color: rgb(165, 39.75, 51.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(210.8, 211.65, 212.5);
  --bs-btn-hover-border-color: rgb(198.4, 199.2, 200);
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(198.4, 199.2, 200);
  --bs-btn-active-border-color: rgb(186, 186.75, 187.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(66.3, 69.7, 73.1);
  --bs-btn-hover-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(77.4, 80.6, 83.8);
  --bs-btn-active-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2833, 37, 41, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27rgb%285.2, 44, 101.2%29%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgb%28109.8, 168, 253.8%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgb%28109.8, 168, 253.8%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
          text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
          text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/**************************************
 CSS Index  
****************************

/--- Default Styles -----/

01.Variables
02.Mixins
03.Reset
04.Typography
05.Extend
06.Animations
07.Shortcode
08.Spacing
09.Common
10.Forms
11.Sal
12.Text Animation
13.Themebg

/--- Header Styles -----/

01.Headerdefault
02.Nav
03.Header Top
04.HeaderTopBar
05.Mobilemenu

/--- Elements -----/

01.Breadcrumb
02.Sectiontitle
03.Button
04.Service
05.Card
06.Counter
07.Progressbar
08.Accordion
09.Social Share
10.Team
11.Portfolio
12.Testimonial
13.Slickslide
14.Timeline
15.Call to Action
16.Tab
17.Pricing
18.Split
19.Vedio
20.Gallery
21.Contact
22.Brand
23.404
24.Advance Tab
25.Advancepricing
26.Contact Form
27.Swicher
28.Backto Top
29.About
30.Newsletter

/--- Blog -----/
01.Blog
02.Sidebar
03.Blog Details

/--- Footer -----/
01.Footer
02.Copyright

/--- Template -----/
01.Banner
02.Portfoliodetails
03.Splash
04.Lightmode

***************************************/
/**************************************
    Default Styles
***************************************/
/*-- Variables --*/
:root {
  --color-primary: #059DFF;
  --color-primary-rgba: rgb(5, 157, 255);
  --color-primary-alt: #059DFF;
  --color-primary-light: #059DFF;
  --color-primary-lightest: #059DFF;
  --color-secondary: #FB5343;
  --color-tertiary: #6549D5;
  --color-tertiary-alt: #6549D5;
  --color-pink: #E33FA1;
  --color-light-green: #36C6F0;
  --color-primary-darker: #011827;
  --color-tertiary-darker: #150b40;
  --color-secondary-darker: #190301;
  --color-light-green-darker: #001f27;
  --color-pink-darker: #2f011d;
  --color-heading: #ffffff;
  --color-body: #acacac;
  --color-dark: #060606;
  --color-darker: rgba(6, 6, 6, 0.7);
  --color-darkest: rgba(0, 0, 0, 0.3);
  --color-black: #000000;
  --color-blacker: rgba(15, 15, 17, 0);
  --color-blackest: #0f0f11;
  --color-border: hsla(0,0%,100%,0.1);
  --color-lessdark: #00000054;
  --color-gray: #65676B;
  --color-midgray: #878787;
  --color-light: #E4E6EA;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-white: #ffffff;
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;
  --border-width: 2px;
  --radius-small: 6px;
  --radius: 10px;
  --radius-big: 16px;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --shadow-primary: 0 0 20px 5px rgba(251,83,67,0.2);
  --shadow-light: 1px 1px 6px rgba(0,0,0,0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0,0,0,0.01);
  --transition: 0.3s;
  --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);
  --font-primary: "Inter", sans-serif;
  --secondary-font: "Inter", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;
  --line-height-b1: 1.67;
  --line-height-b2: 1.7;
  --line-height-b3: 1.6;
  --line-height-b4: 1.3;
  --h1: 56px;
  --h2: 44px;
  --h3: 36px;
  --h4: 24px;
  --h5: 18px;
  --h6: 16px;
}

/**
 * Reset Styels
 */
* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

a {
  color: var(--color-heading);
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: var(--color-primary);
}

a:focus {
  outline: none;
}

address {
  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

mark, .mark {
  background: var(--color-primary);
  color: #ffffff;
}

code,
kbd,
pre,
samp {
  font-size: var(--font-size-b3);
  -webkit-hyphens: none;
  hyphens: none;
  color: var(--color-primary);
}

kbd,
ins {
  color: #ffffff;
}

pre,
pre.wp-block-code {
  font-family: "Courier 10 Pitch", Courier, monospace !important;
  font-size: 15px !important;
  margin: 40px 0 !important;
  overflow: auto;
  padding: 20px !important;
  white-space: pre !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: var(--color-body) !important;
  background: var(--color-light);
  border-radius: 4px;
}

small, .small {
  font-size: smaller;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin-top: 0;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

dt {
  font-weight: bold;
  color: var(--color-heading);
}

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li > ul,
li > ol {
  margin: 0;
}

ol ul {
  margin-bottom: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid var(--color-border);
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  padding: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

caption,
th,
td {
  font-weight: normal;
}

table thead th {
  font-weight: 700;
}

td,
.wp-block-calendar tfoot td {
  border: 1px solid var(--color-border);
  padding: 7px 10px;
}

del {
  color: #333;
}

ins {
  background: rgba(56, 88, 246, 0.6);
  text-decoration: none;
  padding: 0 5px;
}

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
  opacity: 0.6;
}

table a,
table a:link,
table a:visited {
  text-decoration: underline;
}

dt {
  font-weight: bold;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

caption {
  caption-side: top;
}

kbd {
  background: var(--heading-color);
}

dfn,
cite,
em {
  font-style: italic;
}

/* BlockQuote  */
blockquote,
q {
  -webkit-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  font-size: var(--font-size-b1);
  font-style: italic;
  font-weight: var(--p-light);
  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small,
blockquote .small {
  font-size: var(--font-size-b3);
  font-weight: normal;
}

blockquote strong,
blockquote b {
  font-weight: 700;
}

/**
 * Typography
 */
* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  overflow: hidden;
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  color: var(--color-body);
  font-weight: var(--p-regular);
  background-color: var(--color-dark);
  margin: 0;
  padding: 0;
}

h1,
.h1,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 20px;
}

h1,
.h1,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  font-family: var(--secondary-font);
  line-height: 1.4074;
  color: var(--color-heading);
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 1.19;
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: 1.23;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 1.4;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.25;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.24;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
  transition: 0.5s;
}

a {
  color: var(--color-body);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 38px;
  }
  h2,
  .h2 {
    font-size: 32px;
  }
  h3,
  .h3 {
    font-size: 28px;
  }
  h4,
  .h4 {
    font-size: 24px;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 34px;
  }
  h2,
  .h2 {
    font-size: 28px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
  font-weight: var(--p-bold);
}

h6,
.h6 {
  font-weight: var(--p-medium);
}

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 30px;
}
@media only screen and (max-width: 767px) {
  p {
    margin: 0 0 20px;
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
  }
}
p.has-large-font-size {
  line-height: 1.5;
  font-size: 36px;
}
p.has-medium-font-size {
  font-size: 24px;
  line-height: 36px;
}
p.has-small-font-size {
  font-size: 13px;
}
p.has-very-light-gray-color {
  color: var(--color-white);
}
p.has-background {
  padding: 20px 30px;
}
p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}
p.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}
p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}
p.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 40px;
  width: 100%;
}

table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--color-heading);
}

var {
  font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: square;
  margin-bottom: 30px;
  padding-left: 20px;
}
ul.liststyle.bullet li {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-body);
  position: relative;
  padding-left: 30px;
}
@media only screen and (max-width: 767px) {
  ul.liststyle.bullet li {
    padding-left: 19px;
  }
}
ul.liststyle.bullet li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: var(--color-body);
  left: 0;
  top: 10px;
}
ul.liststyle.bullet li + li {
  margin-top: 8px;
}
ul li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-body);
  font-weight: 400;
}
ul li a {
  text-decoration: none;
  color: var(--color-body);
}
ul li a:hover {
  color: var(--color-primary);
}
ul ul {
  margin-bottom: 0;
}

ol {
  margin-bottom: 30px;
}
ol li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  color: var(--color-gray);
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}
ol li a {
  color: var(--heading-color);
  text-decoration: none;
}
ol li a:hover {
  color: var(--color-primary);
}
ol ul {
  padding-left: 30px;
}

hr {
  display: block;
  height: 0;
  margin: 15px 0;
  border-top: 1px solid var(--color-border);
  background: transparent;
}

kbd {
  background: var(--color-primary);
}

/*=====================
All Extend Here
=======================*/
.color-primary {
  color: var(--color-primary) !important;
}

.color-primary-light {
  color: var(--color-primary-light) !important;
}

.color-primary-alt {
  color: var(--color-primary-alt) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.color-tertiary {
  color: var(--color-tertiary) !important;
}

.color-tertiary-alt {
  color: var(--color-tertiary-alt) !important;
}

.color-light-green {
  color: var(--color-light-green) !important;
}

.color-pink {
  color: var(--color-pink) !important;
}

.color-primary-darker {
  color: var(--color-primary-darker) !important;
}

.color-secondary-darker {
  color: var(--color-secondary-darker) !important;
}

.color-light-green-darker {
  color: var(--color-light-green-darker) !important;
}

.color-pink-darker {
  color: var(--color-pink-darker) !important;
}

.color-heading {
  color: var(--color-heading) !important;
}

.color-body {
  color: var(--color-body) !important;
}

.color-dark {
  color: var(--color-dark) !important;
}

.color-darker {
  color: var(--color-darker) !important;
}

.color-darkest {
  color: var(--color-darkest) !important;
}

.color-black {
  color: var(--color-black) !important;
}

.color-blacker {
  color: var(--color-blacker) !important;
}

.color-blackest {
  color: var(--color-blackest) !important;
}

.color-border {
  color: var(--color-border) !important;
}

.color-gray {
  color: var(--color-gray) !important;
}

.color-midgray {
  color: var(--color-midgray) !important;
}

.color-light {
  color: var(--color-light) !important;
}

.color-lighter {
  color: var(--color-lighter) !important;
}

.color-lightest {
  color: var(--color-lightest) !important;
}

.color-white {
  color: var(--color-white) !important;
}

[data-overlay]:before {
  background: var(--color-primary);
}

/* Radius */
.filter-button-default button, .rwt-card .inner .thumbnail .card-image img, .rwt-card .inner .thumbnail .card-image a, .radius-small {
  border-radius: var(--radius-small);
}

.radius, .post-scale {
  border-radius: var(--radius);
}

.radius-big {
  border-radius: var(--radius-big);
}

/* Font Weight */
.w-300 {
  font-weight: 300 !important;
}

.w-400 {
  font-weight: 400 !important;
}

.w-500 {
  font-weight: 500 !important;
}

.w-600 {
  font-weight: 600 !important;
}

.w-700 {
  font-weight: 700 !important;
}

.w-800 {
  font-weight: 800 !important;
}

.w-900 {
  font-weight: 900 !important;
}

/* Shadows */
.shadow-primary {
  box-shadow: var(--shadow-primary);
}

.shadow-light {
  box-shadow: var(--shadow-light);
}

.shadow-lighter {
  box-shadow: var(--shadow-lighter);
}

/*=============== Style Css =============*/
.rn-meta-list, .advance-pricing .pricing-right .plan-offer-list, .advance-brand, .breadcrumb-inner ul.page-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.tagcloud a,
.wp-block-tag-cloud a, .rbt-single-widget.widget_recent_comments ul li span, .rbt-single-widget.widget_recent_entries ul li span, .rbt-single-widget.widget_archive ul li span, .rbt-single-widget.widget_categories ul li span, .rbt-single-widget.widget_meta ul li span, .rbt-single-widget.widget_pages ul li span, .rbt-single-widget.widget_nav_menu ul li span, .rbt-single-widget.widget_recent_comments ul li a, .rbt-single-widget.widget_recent_entries ul li a, .rbt-single-widget.widget_archive ul li a, .rbt-single-widget.widget_categories ul li a, .rbt-single-widget.widget_meta ul li a, .rbt-single-widget.widget_pages ul li a, .rbt-single-widget.widget_nav_menu ul li a, .rn-address .inner p a, .rn-slick-dot .slick-dots li button::after, .filter-button-default button, .rwt-card .inner .content .title a, .rwt-card .inner .thumbnail .card-image img, .rwt-card, .card-box .inner .content .title a, .service.service__style--2 .content p, .service.service__style--2 .content .title a, .service.service__style--2 .content .title, .service.gallery-style .content p, .service.gallery-style .content .title a, .service.gallery-style .content .title, .service.service__style--1 .content p, .service.service__style--1 .content .title a, .service.service__style--1 .content .title, .service.service__style--1 .icon, .mainmenu-nav .mainmenu li.has-droupdown .submenu li a, .form-group textarea, .form-group input, #scrollUp, ul li a, ol li a {
  transition: var(--transition);
}

.bg_image, .bgImagePosition {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*----------------------
    Animation Css  
-----------------------*/
.post-scale {
  overflow: hidden;
}
.post-scale img {
  transition: 0.5s;
}
.post-scale:hover img {
  transform: scale(1.1);
}

@keyframes customOne {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
@keyframes customTwo {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(100px, 0px);
  }
  100% {
    transform: translate(50px, 50px);
  }
}
.customOne {
  animation: customOne 2s infinite;
}

/* ------------------------
    Custom Animation 01 
----------------------------*/
@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}
/*------------------------
	slidefadeinup
--------------------------*/
@keyframes slideFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.slideFadeInUp {
  animation-name: slideFadeInUp;
}

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/
@keyframes moveVertical {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/
@keyframes scrollDown {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
/*==============================
 *  Utilities
=================================*/
/* 
font-family: 'Inter', sans-serif;
*/
@media only screen and (max-width: 767px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 15px;
    padding-left: 15px;
  }
}

iframe {
  width: 100%;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.fix {
  overflow: hidden;
}

/*===============================
    Background Color 
=================================*/
.bg-color-primary {
  background: var(--color-primary);
}

.bg-color-secondary {
  background: var(--color-secondary);
}

.bg-color-tertiary {
  background: var(--color-tertiary);
}

.bg-color-grey {
  background: #F0F2F5;
}

.bg-color-white {
  background: #FFFFFF;
}

.bg-color-black {
  background: #1A1A1A;
}

.bg-color-extra03 {
  background: var(--color-extra03);
}

.bg-color-lessdark {
  background: var(--color-lessdark);
}

.bg-color-2 {
  -webkit-backdrop-filter: saturate(180%) blur(8px);
          backdrop-filter: saturate(180%) blur(8px);
  background-color: var(--color-darker);
  background-image: linear-gradient(180deg, var(--color-black), transparent);
}

/*===========================
    Background Image 
=============================*/
.bg_image_fixed {
  background-attachment: fixed !important;
}

.bg_image--1 {
  background-image: url(/build/images/bg-image-1.e12a7844.jpg);
}

.bg_image--2 {
  background-image: url(/build/images/bg-image-2.7cb92a1c.jpg);
}

.bg_image--3 {
  background-image: url(/build/images/bg-image-3.e12a7844.jpg);
}

.bg_image--4 {
  background-image: url(/build/images/bg-image-4.650964e5.jpg);
}

.bg_image--5 {
  background-image: url(/build/images/bg-image-5.e12a7844.jpg);
}

.bg_image--6 {
  background-image: url(/build/images/bg-image-6.e12a7844.jpg);
}

.bg_image--7 {
  background-image: url(/build/images/bg-image-7.e12a7844.jpg);
}

.bg_image--8 {
  background-image: url(/build/images/bg-image-8.e12a7844.jpg);
}

.bg_image--9 {
  background-image: url(/build/images/bg-image-9.e12a7844.jpg);
}

.bg_image--10 {
  background-image: url(/build/images/bg-image-10.e12a7844.jpg);
}

.bg_image--11 {
  background-image: url(/build/images/bg-image-11.e12a7844.jpg);
}

.bg_image--12 {
  background-image: url(/build/images/bg-image-12.e12a7844.jpg);
}

.bg_image--13 {
  background-image: url(/build/images/bg-image-13.e12a7844.jpg);
}

.bg_image--14 {
  background-image: url(/build/images/bg-image-14.e12a7844.jpg);
}

.bg_image--15 {
  background-image: url(/build/images/bg-image-15.e12a7844.jpg);
}

.bg_image--16 {
  background-image: url(/build/images/bg-image-16.e12a7844.jpg);
}

.bg_image--17 {
  background-image: url(/build/images/bg-image-17.e12a7844.jpg);
}

.bg_image--18 {
  background-image: url(/build/images/bg-image-18.e12a7844.jpg);
}

.bg_image--19 {
  background-image: url(/build/images/bg-image-19.e12a7844.jpg);
}

.bg_image--20 {
  background-image: url(/build/images/bg-image-20.c6090744.jpg);
}

.bg_image--21 {
  background-image: url(/build/images/bg-image-21.e7aedb51.jpg);
}

.bg_image--22 {
  background-image: url(/build/images/bg-image-22.e12a7844.jpg);
}

.bg_image--23 {
  background-image: url(/build/images/bg-image-23.e7aedb51.jpg);
}

.bg_image--24 {
  background-image: url(/build/images/bg-image-24.a40a86bd.jpg);
}

.bg_image--25 {
  background-image: url(/build/images/bg-image-25.a40a86bd.jpg);
}

.bg_image--26 {
  background-image: url(/build/images/bg-image-26.a40a86bd.jpg);
}

.bg_image--27 {
  background-image: url(/build/images/bg-image-27.a40a86bd.jpg);
}

.bg_image--28 {
  background-image: url(/build/images/bg-image-28.a40a86bd.jpg);
}

.bg_image--29 {
  background-image: url(/build/images/bg-image-29.a40a86bd.jpg);
}

.bg_image--30 {
  background-image: url(/build/images/bg-image-30.a40a86bd.jpg);
}

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%;
}

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: 0px;
  margin-right: 0px;
}
.row--0 > [class*=col] {
  padding-left: 0px;
  padding-right: 0px;
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;
}
.row--5 > [class*=col] {
  padding-left: 5px;
  padding-right: 5px;
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row--10 > [class*=col] {
  padding-left: 10px;
  padding-right: 10px;
}

.row--15 {
  margin-left: -15px;
  margin-right: -15px;
}
.row--15 > [class*=col] {
  padding-left: 15px;
  padding-right: 15px;
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--20 > [class*=col], .row--20 > [class*=col-] {
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 > [class*=col], .row--20 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 > [class*=col], .row--20 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 > [class*=col], .row--20 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--20 > [class*=col], .row--20 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--25 > [class*=col], .row--25 > [class*=col-] {
  padding-left: 25px;
  padding-right: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--30 > [class*=col], .row--30 > [class*=col-] {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--45 {
  margin-left: -45px;
  margin-right: -45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--45 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--45 > [class*=col], .row--45 > [class*=col-] {
  padding-left: 45px;
  padding-right: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--45 > [class*=col], .row--45 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--45 > [class*=col], .row--45 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--45 > [class*=col], .row--45 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--45 > [class*=col], .row--45 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--40 {
  margin-left: -40px;
  margin-right: -40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--40 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--40 > [class*=col], .row--40 > [class*=col-] {
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--40 > [class*=col], .row--40 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 > [class*=col], .row--40 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 > [class*=col], .row--40 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--40 > [class*=col], .row--40 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--60 {
  margin-left: -60px;
  margin-right: -60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--60 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--60 > [class*=col], .row--60 > [class*=col-] {
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--60 > [class*=col], .row--60 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--60 > [class*=col], .row--60 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--60 > [class*=col], .row--60 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--60 > [class*=col], .row--60 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*=============================
	Overlay styles 
==============================*/
[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
  width: 70px;
  height: 80px;
  right: 100px;
  bottom: 60px;
  text-align: center;
  z-index: 9811 !important;
  text-decoration: none;
  background: #fff;
  line-height: 80px;
  color: #757589;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  background: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}
#scrollUp::before {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0, 2, 72, 0.1);
  content: "";
  position: absolute;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
  filter: blur(50px);
}
#scrollUp::after {
  background: #ffffff;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
}
@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 30px;
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
}
#scrollUp span.text {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}
@media only screen and (max-width: 767px) {
  #scrollUp span.text {
    margin-top: 3px;
  }
}
#scrollUp span.text::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent var(--color-primary) transparent;
  position: absolute;
  content: "";
  left: 50%;
  top: 21%;
  transform: translateX(-50%);
}
#scrollUp:hover span.text {
  color: var(--color-primary);
}

/*--------------------
    Contact Form 
----------------------*/
.form-message {
  margin-bottom: 0;
  text-align: center;
}
.form-message.error {
  margin-top: 20px;
  color: #f80707;
}
.form-message.success {
  margin-top: 20px;
  color: #0d8d2d;
}

.slick-gutter-15 {
  margin: 0 -15px;
}
.slick-gutter-15 .slick-slide {
  padding: 0 15px;
}

/*=========================
    Section Separation 
==========================*/
.mt_dec--30 {
  margin-top: -30px;
}

.mt_dec--10 {
  margin-top: -10px;
}

.rn-section-gap {
  padding: 120px 0 !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gap {
    padding: 80px 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .rn-section-gap {
    padding: 60px 0 !important;
  }
}

.rn-section-gapBottom {
  padding-bottom: 120px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gapBottom {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .rn-section-gapBottom {
    padding-bottom: 60px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--165,
  .pb--85 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb--165,
  .pb--85 {
    padding-bottom: 60px !important;
  }
}

.rn-section-gapTop {
  padding-top: 120px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gapTop {
    padding-top: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .rn-section-gapTop {
    padding-top: 60px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120 {
    padding: 80px 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .ptb--120 {
    padding: 60px 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb--165,
  .pb--120,
  .pb--85,
  .pb--165 {
    padding-bottom: 60px !important;
  }
}

@media only screen and (max-width: 767px) {
  .pb--70 {
    padding-bottom: 60px !important;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.pt--260 {
  padding-top: 260px !important;
}

.pt--250 {
  padding-top: 250px !important;
}

.pb--240 {
  padding-bottom: 240px !important;
}

.pb--220 {
  padding-bottom: 220px !important;
}

.plr--270 {
  padding: 0 270px !important;
}

.plr--300 {
  padding: 0 300px !important;
}

.plr--340 {
  padding: 0 340px !important;
}

.ptb--300 {
  padding: 300px 0 !important;
}

.ptb--340 {
  padding: 340px 0 !important;
}

.ptb--450 {
  padding: 450px 0 !important;
}

.plr_dec--15 {
  margin: 0 -15px !important;
}

.pt--300 {
  padding-top: 300px !important;
}

.ptb--5 {
  padding: 5px 0 !important;
}

.plr--5 {
  padding: 0 5px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.mr--5 {
  margin-right: 5px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.ptb--10 {
  padding: 10px 0 !important;
}

.plr--10 {
  padding: 0 10px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ptb--15 {
  padding: 15px 0 !important;
}

.plr--15 {
  padding: 0 15px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ptb--20 {
  padding: 20px 0 !important;
}

.plr--20 {
  padding: 0 20px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ptb--25 {
  padding: 25px 0 !important;
}

.plr--25 {
  padding: 0 25px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ptb--30 {
  padding: 30px 0 !important;
}

.plr--30 {
  padding: 0 30px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ptb--35 {
  padding: 35px 0 !important;
}

.plr--35 {
  padding: 0 35px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ptb--40 {
  padding: 40px 0 !important;
}

.plr--40 {
  padding: 0 40px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ptb--45 {
  padding: 45px 0 !important;
}

.plr--45 {
  padding: 0 45px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ptb--50 {
  padding: 50px 0 !important;
}

.plr--50 {
  padding: 0 50px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ptb--55 {
  padding: 55px 0 !important;
}

.plr--55 {
  padding: 0 55px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ptb--60 {
  padding: 60px 0 !important;
}

.plr--60 {
  padding: 0 60px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ptb--65 {
  padding: 65px 0 !important;
}

.plr--65 {
  padding: 0 65px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ptb--70 {
  padding: 70px 0 !important;
}

.plr--70 {
  padding: 0 70px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ptb--75 {
  padding: 75px 0 !important;
}

.plr--75 {
  padding: 0 75px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ptb--80 {
  padding: 80px 0 !important;
}

.plr--80 {
  padding: 0 80px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ptb--85 {
  padding: 85px 0 !important;
}

.plr--85 {
  padding: 0 85px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ptb--90 {
  padding: 90px 0 !important;
}

.plr--90 {
  padding: 0 90px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ptb--95 {
  padding: 95px 0 !important;
}

.plr--95 {
  padding: 0 95px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ptb--100 {
  padding: 100px 0 !important;
}

.plr--100 {
  padding: 0 100px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.ptb--105 {
  padding: 105px 0 !important;
}

.plr--105 {
  padding: 0 105px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.ptb--110 {
  padding: 110px 0 !important;
}

.plr--110 {
  padding: 0 110px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.ptb--115 {
  padding: 115px 0 !important;
}

.plr--115 {
  padding: 0 115px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.ptb--120 {
  padding: 120px 0 !important;
}

.plr--120 {
  padding: 0 120px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.ptb--125 {
  padding: 125px 0 !important;
}

.plr--125 {
  padding: 0 125px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.ptb--130 {
  padding: 130px 0 !important;
}

.plr--130 {
  padding: 0 130px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.ptb--135 {
  padding: 135px 0 !important;
}

.plr--135 {
  padding: 0 135px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.ptb--140 {
  padding: 140px 0 !important;
}

.plr--140 {
  padding: 0 140px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.ptb--145 {
  padding: 145px 0 !important;
}

.plr--145 {
  padding: 0 145px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.ptb--150 {
  padding: 150px 0 !important;
}

.plr--150 {
  padding: 0 150px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.ptb--155 {
  padding: 155px 0 !important;
}

.plr--155 {
  padding: 0 155px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.ptb--160 {
  padding: 160px 0 !important;
}

.plr--160 {
  padding: 0 160px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.ptb--165 {
  padding: 165px 0 !important;
}

.plr--165 {
  padding: 0 165px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.ptb--170 {
  padding: 170px 0 !important;
}

.plr--170 {
  padding: 0 170px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.ptb--175 {
  padding: 175px 0 !important;
}

.plr--175 {
  padding: 0 175px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.ptb--180 {
  padding: 180px 0 !important;
}

.plr--180 {
  padding: 0 180px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.ptb--185 {
  padding: 185px 0 !important;
}

.plr--185 {
  padding: 0 185px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.ptb--190 {
  padding: 190px 0 !important;
}

.plr--190 {
  padding: 0 190px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.ptb--195 {
  padding: 195px 0 !important;
}

.plr--195 {
  padding: 0 195px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.ptb--200 {
  padding: 200px 0 !important;
}

.plr--200 {
  padding: 0 200px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0 !important;
  }
  .plr_lp--5 {
    padding: 0 5px !important;
  }
  .pt_lp--5 {
    padding-top: 5px !important;
  }
  .pb_lp--5 {
    padding-bottom: 5px !important;
  }
  .pl_lp--5 {
    padding-left: 5px !important;
  }
  .pr_lp--5 {
    padding-right: 5px !important;
  }
  .mt_lp--5 {
    margin-top: 5px !important;
  }
  .mb_lp--5 {
    margin-bottom: 5px !important;
  }
  .ptb_lp--10 {
    padding: 10px 0 !important;
  }
  .plr_lp--10 {
    padding: 0 10px !important;
  }
  .pt_lp--10 {
    padding-top: 10px !important;
  }
  .pb_lp--10 {
    padding-bottom: 10px !important;
  }
  .pl_lp--10 {
    padding-left: 10px !important;
  }
  .pr_lp--10 {
    padding-right: 10px !important;
  }
  .mt_lp--10 {
    margin-top: 10px !important;
  }
  .mb_lp--10 {
    margin-bottom: 10px !important;
  }
  .ptb_lp--15 {
    padding: 15px 0 !important;
  }
  .plr_lp--15 {
    padding: 0 15px !important;
  }
  .pt_lp--15 {
    padding-top: 15px !important;
  }
  .pb_lp--15 {
    padding-bottom: 15px !important;
  }
  .pl_lp--15 {
    padding-left: 15px !important;
  }
  .pr_lp--15 {
    padding-right: 15px !important;
  }
  .mt_lp--15 {
    margin-top: 15px !important;
  }
  .mb_lp--15 {
    margin-bottom: 15px !important;
  }
  .ptb_lp--20 {
    padding: 20px 0 !important;
  }
  .plr_lp--20 {
    padding: 0 20px !important;
  }
  .pt_lp--20 {
    padding-top: 20px !important;
  }
  .pb_lp--20 {
    padding-bottom: 20px !important;
  }
  .pl_lp--20 {
    padding-left: 20px !important;
  }
  .pr_lp--20 {
    padding-right: 20px !important;
  }
  .mt_lp--20 {
    margin-top: 20px !important;
  }
  .mb_lp--20 {
    margin-bottom: 20px !important;
  }
  .ptb_lp--25 {
    padding: 25px 0 !important;
  }
  .plr_lp--25 {
    padding: 0 25px !important;
  }
  .pt_lp--25 {
    padding-top: 25px !important;
  }
  .pb_lp--25 {
    padding-bottom: 25px !important;
  }
  .pl_lp--25 {
    padding-left: 25px !important;
  }
  .pr_lp--25 {
    padding-right: 25px !important;
  }
  .mt_lp--25 {
    margin-top: 25px !important;
  }
  .mb_lp--25 {
    margin-bottom: 25px !important;
  }
  .ptb_lp--30 {
    padding: 30px 0 !important;
  }
  .plr_lp--30 {
    padding: 0 30px !important;
  }
  .pt_lp--30 {
    padding-top: 30px !important;
  }
  .pb_lp--30 {
    padding-bottom: 30px !important;
  }
  .pl_lp--30 {
    padding-left: 30px !important;
  }
  .pr_lp--30 {
    padding-right: 30px !important;
  }
  .mt_lp--30 {
    margin-top: 30px !important;
  }
  .mb_lp--30 {
    margin-bottom: 30px !important;
  }
  .ptb_lp--35 {
    padding: 35px 0 !important;
  }
  .plr_lp--35 {
    padding: 0 35px !important;
  }
  .pt_lp--35 {
    padding-top: 35px !important;
  }
  .pb_lp--35 {
    padding-bottom: 35px !important;
  }
  .pl_lp--35 {
    padding-left: 35px !important;
  }
  .pr_lp--35 {
    padding-right: 35px !important;
  }
  .mt_lp--35 {
    margin-top: 35px !important;
  }
  .mb_lp--35 {
    margin-bottom: 35px !important;
  }
  .ptb_lp--40 {
    padding: 40px 0 !important;
  }
  .plr_lp--40 {
    padding: 0 40px !important;
  }
  .pt_lp--40 {
    padding-top: 40px !important;
  }
  .pb_lp--40 {
    padding-bottom: 40px !important;
  }
  .pl_lp--40 {
    padding-left: 40px !important;
  }
  .pr_lp--40 {
    padding-right: 40px !important;
  }
  .mt_lp--40 {
    margin-top: 40px !important;
  }
  .mb_lp--40 {
    margin-bottom: 40px !important;
  }
  .ptb_lp--45 {
    padding: 45px 0 !important;
  }
  .plr_lp--45 {
    padding: 0 45px !important;
  }
  .pt_lp--45 {
    padding-top: 45px !important;
  }
  .pb_lp--45 {
    padding-bottom: 45px !important;
  }
  .pl_lp--45 {
    padding-left: 45px !important;
  }
  .pr_lp--45 {
    padding-right: 45px !important;
  }
  .mt_lp--45 {
    margin-top: 45px !important;
  }
  .mb_lp--45 {
    margin-bottom: 45px !important;
  }
  .ptb_lp--50 {
    padding: 50px 0 !important;
  }
  .plr_lp--50 {
    padding: 0 50px !important;
  }
  .pt_lp--50 {
    padding-top: 50px !important;
  }
  .pb_lp--50 {
    padding-bottom: 50px !important;
  }
  .pl_lp--50 {
    padding-left: 50px !important;
  }
  .pr_lp--50 {
    padding-right: 50px !important;
  }
  .mt_lp--50 {
    margin-top: 50px !important;
  }
  .mb_lp--50 {
    margin-bottom: 50px !important;
  }
  .ptb_lp--55 {
    padding: 55px 0 !important;
  }
  .plr_lp--55 {
    padding: 0 55px !important;
  }
  .pt_lp--55 {
    padding-top: 55px !important;
  }
  .pb_lp--55 {
    padding-bottom: 55px !important;
  }
  .pl_lp--55 {
    padding-left: 55px !important;
  }
  .pr_lp--55 {
    padding-right: 55px !important;
  }
  .mt_lp--55 {
    margin-top: 55px !important;
  }
  .mb_lp--55 {
    margin-bottom: 55px !important;
  }
  .ptb_lp--60 {
    padding: 60px 0 !important;
  }
  .plr_lp--60 {
    padding: 0 60px !important;
  }
  .pt_lp--60 {
    padding-top: 60px !important;
  }
  .pb_lp--60 {
    padding-bottom: 60px !important;
  }
  .pl_lp--60 {
    padding-left: 60px !important;
  }
  .pr_lp--60 {
    padding-right: 60px !important;
  }
  .mt_lp--60 {
    margin-top: 60px !important;
  }
  .mb_lp--60 {
    margin-bottom: 60px !important;
  }
  .ptb_lp--65 {
    padding: 65px 0 !important;
  }
  .plr_lp--65 {
    padding: 0 65px !important;
  }
  .pt_lp--65 {
    padding-top: 65px !important;
  }
  .pb_lp--65 {
    padding-bottom: 65px !important;
  }
  .pl_lp--65 {
    padding-left: 65px !important;
  }
  .pr_lp--65 {
    padding-right: 65px !important;
  }
  .mt_lp--65 {
    margin-top: 65px !important;
  }
  .mb_lp--65 {
    margin-bottom: 65px !important;
  }
  .ptb_lp--70 {
    padding: 70px 0 !important;
  }
  .plr_lp--70 {
    padding: 0 70px !important;
  }
  .pt_lp--70 {
    padding-top: 70px !important;
  }
  .pb_lp--70 {
    padding-bottom: 70px !important;
  }
  .pl_lp--70 {
    padding-left: 70px !important;
  }
  .pr_lp--70 {
    padding-right: 70px !important;
  }
  .mt_lp--70 {
    margin-top: 70px !important;
  }
  .mb_lp--70 {
    margin-bottom: 70px !important;
  }
  .ptb_lp--75 {
    padding: 75px 0 !important;
  }
  .plr_lp--75 {
    padding: 0 75px !important;
  }
  .pt_lp--75 {
    padding-top: 75px !important;
  }
  .pb_lp--75 {
    padding-bottom: 75px !important;
  }
  .pl_lp--75 {
    padding-left: 75px !important;
  }
  .pr_lp--75 {
    padding-right: 75px !important;
  }
  .mt_lp--75 {
    margin-top: 75px !important;
  }
  .mb_lp--75 {
    margin-bottom: 75px !important;
  }
  .ptb_lp--80 {
    padding: 80px 0 !important;
  }
  .plr_lp--80 {
    padding: 0 80px !important;
  }
  .pt_lp--80 {
    padding-top: 80px !important;
  }
  .pb_lp--80 {
    padding-bottom: 80px !important;
  }
  .pl_lp--80 {
    padding-left: 80px !important;
  }
  .pr_lp--80 {
    padding-right: 80px !important;
  }
  .mt_lp--80 {
    margin-top: 80px !important;
  }
  .mb_lp--80 {
    margin-bottom: 80px !important;
  }
  .ptb_lp--85 {
    padding: 85px 0 !important;
  }
  .plr_lp--85 {
    padding: 0 85px !important;
  }
  .pt_lp--85 {
    padding-top: 85px !important;
  }
  .pb_lp--85 {
    padding-bottom: 85px !important;
  }
  .pl_lp--85 {
    padding-left: 85px !important;
  }
  .pr_lp--85 {
    padding-right: 85px !important;
  }
  .mt_lp--85 {
    margin-top: 85px !important;
  }
  .mb_lp--85 {
    margin-bottom: 85px !important;
  }
  .ptb_lp--90 {
    padding: 90px 0 !important;
  }
  .plr_lp--90 {
    padding: 0 90px !important;
  }
  .pt_lp--90 {
    padding-top: 90px !important;
  }
  .pb_lp--90 {
    padding-bottom: 90px !important;
  }
  .pl_lp--90 {
    padding-left: 90px !important;
  }
  .pr_lp--90 {
    padding-right: 90px !important;
  }
  .mt_lp--90 {
    margin-top: 90px !important;
  }
  .mb_lp--90 {
    margin-bottom: 90px !important;
  }
  .ptb_lp--95 {
    padding: 95px 0 !important;
  }
  .plr_lp--95 {
    padding: 0 95px !important;
  }
  .pt_lp--95 {
    padding-top: 95px !important;
  }
  .pb_lp--95 {
    padding-bottom: 95px !important;
  }
  .pl_lp--95 {
    padding-left: 95px !important;
  }
  .pr_lp--95 {
    padding-right: 95px !important;
  }
  .mt_lp--95 {
    margin-top: 95px !important;
  }
  .mb_lp--95 {
    margin-bottom: 95px !important;
  }
  .ptb_lp--100 {
    padding: 100px 0 !important;
  }
  .plr_lp--100 {
    padding: 0 100px !important;
  }
  .pt_lp--100 {
    padding-top: 100px !important;
  }
  .pb_lp--100 {
    padding-bottom: 100px !important;
  }
  .pl_lp--100 {
    padding-left: 100px !important;
  }
  .pr_lp--100 {
    padding-right: 100px !important;
  }
  .mt_lp--100 {
    margin-top: 100px !important;
  }
  .mb_lp--100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0 !important;
  }
  .plr_lg--5 {
    padding: 0 5px !important;
  }
  .pt_lg--5 {
    padding-top: 5px !important;
  }
  .pb_lg--5 {
    padding-bottom: 5px !important;
  }
  .pl_lg--5 {
    padding-left: 5px !important;
  }
  .pr_lg--5 {
    padding-right: 5px !important;
  }
  .mt_lg--5 {
    margin-top: 5px !important;
  }
  .mb_lg--5 {
    margin-bottom: 5px !important;
  }
  .ml_lg--5 {
    margin-left: 5px !important;
  }
  .ptb_lg--10 {
    padding: 10px 0 !important;
  }
  .plr_lg--10 {
    padding: 0 10px !important;
  }
  .pt_lg--10 {
    padding-top: 10px !important;
  }
  .pb_lg--10 {
    padding-bottom: 10px !important;
  }
  .pl_lg--10 {
    padding-left: 10px !important;
  }
  .pr_lg--10 {
    padding-right: 10px !important;
  }
  .mt_lg--10 {
    margin-top: 10px !important;
  }
  .mb_lg--10 {
    margin-bottom: 10px !important;
  }
  .ml_lg--10 {
    margin-left: 10px !important;
  }
  .ptb_lg--15 {
    padding: 15px 0 !important;
  }
  .plr_lg--15 {
    padding: 0 15px !important;
  }
  .pt_lg--15 {
    padding-top: 15px !important;
  }
  .pb_lg--15 {
    padding-bottom: 15px !important;
  }
  .pl_lg--15 {
    padding-left: 15px !important;
  }
  .pr_lg--15 {
    padding-right: 15px !important;
  }
  .mt_lg--15 {
    margin-top: 15px !important;
  }
  .mb_lg--15 {
    margin-bottom: 15px !important;
  }
  .ml_lg--15 {
    margin-left: 15px !important;
  }
  .ptb_lg--20 {
    padding: 20px 0 !important;
  }
  .plr_lg--20 {
    padding: 0 20px !important;
  }
  .pt_lg--20 {
    padding-top: 20px !important;
  }
  .pb_lg--20 {
    padding-bottom: 20px !important;
  }
  .pl_lg--20 {
    padding-left: 20px !important;
  }
  .pr_lg--20 {
    padding-right: 20px !important;
  }
  .mt_lg--20 {
    margin-top: 20px !important;
  }
  .mb_lg--20 {
    margin-bottom: 20px !important;
  }
  .ml_lg--20 {
    margin-left: 20px !important;
  }
  .ptb_lg--25 {
    padding: 25px 0 !important;
  }
  .plr_lg--25 {
    padding: 0 25px !important;
  }
  .pt_lg--25 {
    padding-top: 25px !important;
  }
  .pb_lg--25 {
    padding-bottom: 25px !important;
  }
  .pl_lg--25 {
    padding-left: 25px !important;
  }
  .pr_lg--25 {
    padding-right: 25px !important;
  }
  .mt_lg--25 {
    margin-top: 25px !important;
  }
  .mb_lg--25 {
    margin-bottom: 25px !important;
  }
  .ml_lg--25 {
    margin-left: 25px !important;
  }
  .ptb_lg--30 {
    padding: 30px 0 !important;
  }
  .plr_lg--30 {
    padding: 0 30px !important;
  }
  .pt_lg--30 {
    padding-top: 30px !important;
  }
  .pb_lg--30 {
    padding-bottom: 30px !important;
  }
  .pl_lg--30 {
    padding-left: 30px !important;
  }
  .pr_lg--30 {
    padding-right: 30px !important;
  }
  .mt_lg--30 {
    margin-top: 30px !important;
  }
  .mb_lg--30 {
    margin-bottom: 30px !important;
  }
  .ml_lg--30 {
    margin-left: 30px !important;
  }
  .ptb_lg--35 {
    padding: 35px 0 !important;
  }
  .plr_lg--35 {
    padding: 0 35px !important;
  }
  .pt_lg--35 {
    padding-top: 35px !important;
  }
  .pb_lg--35 {
    padding-bottom: 35px !important;
  }
  .pl_lg--35 {
    padding-left: 35px !important;
  }
  .pr_lg--35 {
    padding-right: 35px !important;
  }
  .mt_lg--35 {
    margin-top: 35px !important;
  }
  .mb_lg--35 {
    margin-bottom: 35px !important;
  }
  .ml_lg--35 {
    margin-left: 35px !important;
  }
  .ptb_lg--40 {
    padding: 40px 0 !important;
  }
  .plr_lg--40 {
    padding: 0 40px !important;
  }
  .pt_lg--40 {
    padding-top: 40px !important;
  }
  .pb_lg--40 {
    padding-bottom: 40px !important;
  }
  .pl_lg--40 {
    padding-left: 40px !important;
  }
  .pr_lg--40 {
    padding-right: 40px !important;
  }
  .mt_lg--40 {
    margin-top: 40px !important;
  }
  .mb_lg--40 {
    margin-bottom: 40px !important;
  }
  .ml_lg--40 {
    margin-left: 40px !important;
  }
  .ptb_lg--45 {
    padding: 45px 0 !important;
  }
  .plr_lg--45 {
    padding: 0 45px !important;
  }
  .pt_lg--45 {
    padding-top: 45px !important;
  }
  .pb_lg--45 {
    padding-bottom: 45px !important;
  }
  .pl_lg--45 {
    padding-left: 45px !important;
  }
  .pr_lg--45 {
    padding-right: 45px !important;
  }
  .mt_lg--45 {
    margin-top: 45px !important;
  }
  .mb_lg--45 {
    margin-bottom: 45px !important;
  }
  .ml_lg--45 {
    margin-left: 45px !important;
  }
  .ptb_lg--50 {
    padding: 50px 0 !important;
  }
  .plr_lg--50 {
    padding: 0 50px !important;
  }
  .pt_lg--50 {
    padding-top: 50px !important;
  }
  .pb_lg--50 {
    padding-bottom: 50px !important;
  }
  .pl_lg--50 {
    padding-left: 50px !important;
  }
  .pr_lg--50 {
    padding-right: 50px !important;
  }
  .mt_lg--50 {
    margin-top: 50px !important;
  }
  .mb_lg--50 {
    margin-bottom: 50px !important;
  }
  .ml_lg--50 {
    margin-left: 50px !important;
  }
  .ptb_lg--55 {
    padding: 55px 0 !important;
  }
  .plr_lg--55 {
    padding: 0 55px !important;
  }
  .pt_lg--55 {
    padding-top: 55px !important;
  }
  .pb_lg--55 {
    padding-bottom: 55px !important;
  }
  .pl_lg--55 {
    padding-left: 55px !important;
  }
  .pr_lg--55 {
    padding-right: 55px !important;
  }
  .mt_lg--55 {
    margin-top: 55px !important;
  }
  .mb_lg--55 {
    margin-bottom: 55px !important;
  }
  .ml_lg--55 {
    margin-left: 55px !important;
  }
  .ptb_lg--60 {
    padding: 60px 0 !important;
  }
  .plr_lg--60 {
    padding: 0 60px !important;
  }
  .pt_lg--60 {
    padding-top: 60px !important;
  }
  .pb_lg--60 {
    padding-bottom: 60px !important;
  }
  .pl_lg--60 {
    padding-left: 60px !important;
  }
  .pr_lg--60 {
    padding-right: 60px !important;
  }
  .mt_lg--60 {
    margin-top: 60px !important;
  }
  .mb_lg--60 {
    margin-bottom: 60px !important;
  }
  .ml_lg--60 {
    margin-left: 60px !important;
  }
  .ptb_lg--65 {
    padding: 65px 0 !important;
  }
  .plr_lg--65 {
    padding: 0 65px !important;
  }
  .pt_lg--65 {
    padding-top: 65px !important;
  }
  .pb_lg--65 {
    padding-bottom: 65px !important;
  }
  .pl_lg--65 {
    padding-left: 65px !important;
  }
  .pr_lg--65 {
    padding-right: 65px !important;
  }
  .mt_lg--65 {
    margin-top: 65px !important;
  }
  .mb_lg--65 {
    margin-bottom: 65px !important;
  }
  .ml_lg--65 {
    margin-left: 65px !important;
  }
  .ptb_lg--70 {
    padding: 70px 0 !important;
  }
  .plr_lg--70 {
    padding: 0 70px !important;
  }
  .pt_lg--70 {
    padding-top: 70px !important;
  }
  .pb_lg--70 {
    padding-bottom: 70px !important;
  }
  .pl_lg--70 {
    padding-left: 70px !important;
  }
  .pr_lg--70 {
    padding-right: 70px !important;
  }
  .mt_lg--70 {
    margin-top: 70px !important;
  }
  .mb_lg--70 {
    margin-bottom: 70px !important;
  }
  .ml_lg--70 {
    margin-left: 70px !important;
  }
  .ptb_lg--75 {
    padding: 75px 0 !important;
  }
  .plr_lg--75 {
    padding: 0 75px !important;
  }
  .pt_lg--75 {
    padding-top: 75px !important;
  }
  .pb_lg--75 {
    padding-bottom: 75px !important;
  }
  .pl_lg--75 {
    padding-left: 75px !important;
  }
  .pr_lg--75 {
    padding-right: 75px !important;
  }
  .mt_lg--75 {
    margin-top: 75px !important;
  }
  .mb_lg--75 {
    margin-bottom: 75px !important;
  }
  .ml_lg--75 {
    margin-left: 75px !important;
  }
  .ptb_lg--80 {
    padding: 80px 0 !important;
  }
  .plr_lg--80 {
    padding: 0 80px !important;
  }
  .pt_lg--80 {
    padding-top: 80px !important;
  }
  .pb_lg--80 {
    padding-bottom: 80px !important;
  }
  .pl_lg--80 {
    padding-left: 80px !important;
  }
  .pr_lg--80 {
    padding-right: 80px !important;
  }
  .mt_lg--80 {
    margin-top: 80px !important;
  }
  .mb_lg--80 {
    margin-bottom: 80px !important;
  }
  .ml_lg--80 {
    margin-left: 80px !important;
  }
  .ptb_lg--85 {
    padding: 85px 0 !important;
  }
  .plr_lg--85 {
    padding: 0 85px !important;
  }
  .pt_lg--85 {
    padding-top: 85px !important;
  }
  .pb_lg--85 {
    padding-bottom: 85px !important;
  }
  .pl_lg--85 {
    padding-left: 85px !important;
  }
  .pr_lg--85 {
    padding-right: 85px !important;
  }
  .mt_lg--85 {
    margin-top: 85px !important;
  }
  .mb_lg--85 {
    margin-bottom: 85px !important;
  }
  .ml_lg--85 {
    margin-left: 85px !important;
  }
  .ptb_lg--90 {
    padding: 90px 0 !important;
  }
  .plr_lg--90 {
    padding: 0 90px !important;
  }
  .pt_lg--90 {
    padding-top: 90px !important;
  }
  .pb_lg--90 {
    padding-bottom: 90px !important;
  }
  .pl_lg--90 {
    padding-left: 90px !important;
  }
  .pr_lg--90 {
    padding-right: 90px !important;
  }
  .mt_lg--90 {
    margin-top: 90px !important;
  }
  .mb_lg--90 {
    margin-bottom: 90px !important;
  }
  .ml_lg--90 {
    margin-left: 90px !important;
  }
  .ptb_lg--95 {
    padding: 95px 0 !important;
  }
  .plr_lg--95 {
    padding: 0 95px !important;
  }
  .pt_lg--95 {
    padding-top: 95px !important;
  }
  .pb_lg--95 {
    padding-bottom: 95px !important;
  }
  .pl_lg--95 {
    padding-left: 95px !important;
  }
  .pr_lg--95 {
    padding-right: 95px !important;
  }
  .mt_lg--95 {
    margin-top: 95px !important;
  }
  .mb_lg--95 {
    margin-bottom: 95px !important;
  }
  .ml_lg--95 {
    margin-left: 95px !important;
  }
  .ptb_lg--100 {
    padding: 100px 0 !important;
  }
  .plr_lg--100 {
    padding: 0 100px !important;
  }
  .pt_lg--100 {
    padding-top: 100px !important;
  }
  .pb_lg--100 {
    padding-bottom: 100px !important;
  }
  .pl_lg--100 {
    padding-left: 100px !important;
  }
  .pr_lg--100 {
    padding-right: 100px !important;
  }
  .mt_lg--100 {
    margin-top: 100px !important;
  }
  .mb_lg--100 {
    margin-bottom: 100px !important;
  }
  .ml_lg--100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0 !important;
  }
  .pl_md--0 {
    padding-left: 0 !important;
  }
  .pr_md--0 {
    padding-right: 0 !important;
  }
  .pt_md--0 {
    padding-top: 0 !important;
  }
  .pb_md--0 {
    padding-bottom: 0 !important;
  }
  .mr_md--0 {
    margin-right: 0 !important;
  }
  .ml_md--0 {
    margin-left: 0 !important;
  }
  .mt_md--0 {
    margin-top: 0 !important;
  }
  .mb_md--0 {
    margin-bottom: 0 !important;
  }
  .ptb_md--250 {
    padding: 250px 0 !important;
  }
  .ptb_md--5 {
    padding: 5px 0 !important;
  }
  .plr_md--5 {
    padding: 0 5px !important;
  }
  .pt_md--5 {
    padding-top: 5px !important;
  }
  .pb_md--5 {
    padding-bottom: 5px !important;
  }
  .pl_md--5 {
    padding-left: 5px !important;
  }
  .pr_md--5 {
    padding-right: 5px !important;
  }
  .mt_md--5 {
    margin-top: 5px !important;
  }
  .mb_md--5 {
    margin-bottom: 5px !important;
  }
  .ptb_md--10 {
    padding: 10px 0 !important;
  }
  .plr_md--10 {
    padding: 0 10px !important;
  }
  .pt_md--10 {
    padding-top: 10px !important;
  }
  .pb_md--10 {
    padding-bottom: 10px !important;
  }
  .pl_md--10 {
    padding-left: 10px !important;
  }
  .pr_md--10 {
    padding-right: 10px !important;
  }
  .mt_md--10 {
    margin-top: 10px !important;
  }
  .mb_md--10 {
    margin-bottom: 10px !important;
  }
  .ptb_md--15 {
    padding: 15px 0 !important;
  }
  .plr_md--15 {
    padding: 0 15px !important;
  }
  .pt_md--15 {
    padding-top: 15px !important;
  }
  .pb_md--15 {
    padding-bottom: 15px !important;
  }
  .pl_md--15 {
    padding-left: 15px !important;
  }
  .pr_md--15 {
    padding-right: 15px !important;
  }
  .mt_md--15 {
    margin-top: 15px !important;
  }
  .mb_md--15 {
    margin-bottom: 15px !important;
  }
  .ptb_md--20 {
    padding: 20px 0 !important;
  }
  .plr_md--20 {
    padding: 0 20px !important;
  }
  .pt_md--20 {
    padding-top: 20px !important;
  }
  .pb_md--20 {
    padding-bottom: 20px !important;
  }
  .pl_md--20 {
    padding-left: 20px !important;
  }
  .pr_md--20 {
    padding-right: 20px !important;
  }
  .mt_md--20 {
    margin-top: 20px !important;
  }
  .mb_md--20 {
    margin-bottom: 20px !important;
  }
  .ptb_md--25 {
    padding: 25px 0 !important;
  }
  .plr_md--25 {
    padding: 0 25px !important;
  }
  .pt_md--25 {
    padding-top: 25px !important;
  }
  .pb_md--25 {
    padding-bottom: 25px !important;
  }
  .pl_md--25 {
    padding-left: 25px !important;
  }
  .pr_md--25 {
    padding-right: 25px !important;
  }
  .mt_md--25 {
    margin-top: 25px !important;
  }
  .mb_md--25 {
    margin-bottom: 25px !important;
  }
  .ptb_md--30 {
    padding: 30px 0 !important;
  }
  .plr_md--30 {
    padding: 0 30px !important;
  }
  .pt_md--30 {
    padding-top: 30px !important;
  }
  .pb_md--30 {
    padding-bottom: 30px !important;
  }
  .pl_md--30 {
    padding-left: 30px !important;
  }
  .pr_md--30 {
    padding-right: 30px !important;
  }
  .mt_md--30 {
    margin-top: 30px !important;
  }
  .mb_md--30 {
    margin-bottom: 30px !important;
  }
  .ptb_md--35 {
    padding: 35px 0 !important;
  }
  .plr_md--35 {
    padding: 0 35px !important;
  }
  .pt_md--35 {
    padding-top: 35px !important;
  }
  .pb_md--35 {
    padding-bottom: 35px !important;
  }
  .pl_md--35 {
    padding-left: 35px !important;
  }
  .pr_md--35 {
    padding-right: 35px !important;
  }
  .mt_md--35 {
    margin-top: 35px !important;
  }
  .mb_md--35 {
    margin-bottom: 35px !important;
  }
  .ptb_md--40 {
    padding: 40px 0 !important;
  }
  .plr_md--40 {
    padding: 0 40px !important;
  }
  .pt_md--40 {
    padding-top: 40px !important;
  }
  .pb_md--40 {
    padding-bottom: 40px !important;
  }
  .pl_md--40 {
    padding-left: 40px !important;
  }
  .pr_md--40 {
    padding-right: 40px !important;
  }
  .mt_md--40 {
    margin-top: 40px !important;
  }
  .mb_md--40 {
    margin-bottom: 40px !important;
  }
  .ptb_md--45 {
    padding: 45px 0 !important;
  }
  .plr_md--45 {
    padding: 0 45px !important;
  }
  .pt_md--45 {
    padding-top: 45px !important;
  }
  .pb_md--45 {
    padding-bottom: 45px !important;
  }
  .pl_md--45 {
    padding-left: 45px !important;
  }
  .pr_md--45 {
    padding-right: 45px !important;
  }
  .mt_md--45 {
    margin-top: 45px !important;
  }
  .mb_md--45 {
    margin-bottom: 45px !important;
  }
  .ptb_md--50 {
    padding: 50px 0 !important;
  }
  .plr_md--50 {
    padding: 0 50px !important;
  }
  .pt_md--50 {
    padding-top: 50px !important;
  }
  .pb_md--50 {
    padding-bottom: 50px !important;
  }
  .pl_md--50 {
    padding-left: 50px !important;
  }
  .pr_md--50 {
    padding-right: 50px !important;
  }
  .mt_md--50 {
    margin-top: 50px !important;
  }
  .mb_md--50 {
    margin-bottom: 50px !important;
  }
  .ptb_md--55 {
    padding: 55px 0 !important;
  }
  .plr_md--55 {
    padding: 0 55px !important;
  }
  .pt_md--55 {
    padding-top: 55px !important;
  }
  .pb_md--55 {
    padding-bottom: 55px !important;
  }
  .pl_md--55 {
    padding-left: 55px !important;
  }
  .pr_md--55 {
    padding-right: 55px !important;
  }
  .mt_md--55 {
    margin-top: 55px !important;
  }
  .mb_md--55 {
    margin-bottom: 55px !important;
  }
  .ptb_md--60 {
    padding: 60px 0 !important;
  }
  .plr_md--60 {
    padding: 0 60px !important;
  }
  .pt_md--60 {
    padding-top: 60px !important;
  }
  .pb_md--60 {
    padding-bottom: 60px !important;
  }
  .pl_md--60 {
    padding-left: 60px !important;
  }
  .pr_md--60 {
    padding-right: 60px !important;
  }
  .mt_md--60 {
    margin-top: 60px !important;
  }
  .mb_md--60 {
    margin-bottom: 60px !important;
  }
  .ptb_md--65 {
    padding: 65px 0 !important;
  }
  .plr_md--65 {
    padding: 0 65px !important;
  }
  .pt_md--65 {
    padding-top: 65px !important;
  }
  .pb_md--65 {
    padding-bottom: 65px !important;
  }
  .pl_md--65 {
    padding-left: 65px !important;
  }
  .pr_md--65 {
    padding-right: 65px !important;
  }
  .mt_md--65 {
    margin-top: 65px !important;
  }
  .mb_md--65 {
    margin-bottom: 65px !important;
  }
  .ptb_md--70 {
    padding: 70px 0 !important;
  }
  .plr_md--70 {
    padding: 0 70px !important;
  }
  .pt_md--70 {
    padding-top: 70px !important;
  }
  .pb_md--70 {
    padding-bottom: 70px !important;
  }
  .pl_md--70 {
    padding-left: 70px !important;
  }
  .pr_md--70 {
    padding-right: 70px !important;
  }
  .mt_md--70 {
    margin-top: 70px !important;
  }
  .mb_md--70 {
    margin-bottom: 70px !important;
  }
  .ptb_md--75 {
    padding: 75px 0 !important;
  }
  .plr_md--75 {
    padding: 0 75px !important;
  }
  .pt_md--75 {
    padding-top: 75px !important;
  }
  .pb_md--75 {
    padding-bottom: 75px !important;
  }
  .pl_md--75 {
    padding-left: 75px !important;
  }
  .pr_md--75 {
    padding-right: 75px !important;
  }
  .mt_md--75 {
    margin-top: 75px !important;
  }
  .mb_md--75 {
    margin-bottom: 75px !important;
  }
  .ptb_md--80 {
    padding: 80px 0 !important;
  }
  .plr_md--80 {
    padding: 0 80px !important;
  }
  .pt_md--80 {
    padding-top: 80px !important;
  }
  .pb_md--80 {
    padding-bottom: 80px !important;
  }
  .pl_md--80 {
    padding-left: 80px !important;
  }
  .pr_md--80 {
    padding-right: 80px !important;
  }
  .mt_md--80 {
    margin-top: 80px !important;
  }
  .mb_md--80 {
    margin-bottom: 80px !important;
  }
  .ptb_md--85 {
    padding: 85px 0 !important;
  }
  .plr_md--85 {
    padding: 0 85px !important;
  }
  .pt_md--85 {
    padding-top: 85px !important;
  }
  .pb_md--85 {
    padding-bottom: 85px !important;
  }
  .pl_md--85 {
    padding-left: 85px !important;
  }
  .pr_md--85 {
    padding-right: 85px !important;
  }
  .mt_md--85 {
    margin-top: 85px !important;
  }
  .mb_md--85 {
    margin-bottom: 85px !important;
  }
  .ptb_md--90 {
    padding: 90px 0 !important;
  }
  .plr_md--90 {
    padding: 0 90px !important;
  }
  .pt_md--90 {
    padding-top: 90px !important;
  }
  .pb_md--90 {
    padding-bottom: 90px !important;
  }
  .pl_md--90 {
    padding-left: 90px !important;
  }
  .pr_md--90 {
    padding-right: 90px !important;
  }
  .mt_md--90 {
    margin-top: 90px !important;
  }
  .mb_md--90 {
    margin-bottom: 90px !important;
  }
  .ptb_md--95 {
    padding: 95px 0 !important;
  }
  .plr_md--95 {
    padding: 0 95px !important;
  }
  .pt_md--95 {
    padding-top: 95px !important;
  }
  .pb_md--95 {
    padding-bottom: 95px !important;
  }
  .pl_md--95 {
    padding-left: 95px !important;
  }
  .pr_md--95 {
    padding-right: 95px !important;
  }
  .mt_md--95 {
    margin-top: 95px !important;
  }
  .mb_md--95 {
    margin-bottom: 95px !important;
  }
  .ptb_md--100 {
    padding: 100px 0 !important;
  }
  .plr_md--100 {
    padding: 0 100px !important;
  }
  .pt_md--100 {
    padding-top: 100px !important;
  }
  .pb_md--100 {
    padding-bottom: 100px !important;
  }
  .pl_md--100 {
    padding-left: 100px !important;
  }
  .pr_md--100 {
    padding-right: 100px !important;
  }
  .mt_md--100 {
    margin-top: 100px !important;
  }
  .mb_md--100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0 !important;
  }
  .ptb_sm--0 {
    padding: 0 !important;
  }
  .pl_sm--0 {
    padding-left: 0 !important;
  }
  .pr_sm--0 {
    padding-right: 0 !important;
  }
  .pt_sm--0 {
    padding-top: 0 !important;
  }
  .pb_sm--0 {
    padding-bottom: 0 !important;
  }
  .mr_sm--0 {
    margin-right: 0 !important;
  }
  .ml_sm--0 {
    margin-left: 0 !important;
  }
  .mt_sm--0 {
    margin-top: 0 !important;
  }
  .mb_sm--0 {
    margin-bottom: 0 !important;
  }
  .pt_sm--150 {
    padding-top: 150px !important;
  }
  .pb_sm--110 {
    padding-bottom: 110px !important;
  }
  .ptb_sm--5 {
    padding: 5px 0 !important;
  }
  .plr_sm--5 {
    padding: 0 5px !important;
  }
  .pt_sm--5 {
    padding-top: 5px !important;
  }
  .pb_sm--5 {
    padding-bottom: 5px !important;
  }
  .pl_sm--5 {
    padding-left: 5px !important;
  }
  .pr_sm--5 {
    padding-right: 5px !important;
  }
  .mt_sm--5 {
    margin-top: 5px !important;
  }
  .ml_sm--5 {
    margin-left: 5px !important;
  }
  .mr_sm--5 {
    margin-right: 5px !important;
  }
  .mb_sm--5 {
    margin-bottom: 5px !important;
  }
  .ptb_sm--10 {
    padding: 10px 0 !important;
  }
  .plr_sm--10 {
    padding: 0 10px !important;
  }
  .pt_sm--10 {
    padding-top: 10px !important;
  }
  .pb_sm--10 {
    padding-bottom: 10px !important;
  }
  .pl_sm--10 {
    padding-left: 10px !important;
  }
  .pr_sm--10 {
    padding-right: 10px !important;
  }
  .mt_sm--10 {
    margin-top: 10px !important;
  }
  .ml_sm--10 {
    margin-left: 10px !important;
  }
  .mr_sm--10 {
    margin-right: 10px !important;
  }
  .mb_sm--10 {
    margin-bottom: 10px !important;
  }
  .ptb_sm--15 {
    padding: 15px 0 !important;
  }
  .plr_sm--15 {
    padding: 0 15px !important;
  }
  .pt_sm--15 {
    padding-top: 15px !important;
  }
  .pb_sm--15 {
    padding-bottom: 15px !important;
  }
  .pl_sm--15 {
    padding-left: 15px !important;
  }
  .pr_sm--15 {
    padding-right: 15px !important;
  }
  .mt_sm--15 {
    margin-top: 15px !important;
  }
  .ml_sm--15 {
    margin-left: 15px !important;
  }
  .mr_sm--15 {
    margin-right: 15px !important;
  }
  .mb_sm--15 {
    margin-bottom: 15px !important;
  }
  .ptb_sm--20 {
    padding: 20px 0 !important;
  }
  .plr_sm--20 {
    padding: 0 20px !important;
  }
  .pt_sm--20 {
    padding-top: 20px !important;
  }
  .pb_sm--20 {
    padding-bottom: 20px !important;
  }
  .pl_sm--20 {
    padding-left: 20px !important;
  }
  .pr_sm--20 {
    padding-right: 20px !important;
  }
  .mt_sm--20 {
    margin-top: 20px !important;
  }
  .ml_sm--20 {
    margin-left: 20px !important;
  }
  .mr_sm--20 {
    margin-right: 20px !important;
  }
  .mb_sm--20 {
    margin-bottom: 20px !important;
  }
  .ptb_sm--25 {
    padding: 25px 0 !important;
  }
  .plr_sm--25 {
    padding: 0 25px !important;
  }
  .pt_sm--25 {
    padding-top: 25px !important;
  }
  .pb_sm--25 {
    padding-bottom: 25px !important;
  }
  .pl_sm--25 {
    padding-left: 25px !important;
  }
  .pr_sm--25 {
    padding-right: 25px !important;
  }
  .mt_sm--25 {
    margin-top: 25px !important;
  }
  .ml_sm--25 {
    margin-left: 25px !important;
  }
  .mr_sm--25 {
    margin-right: 25px !important;
  }
  .mb_sm--25 {
    margin-bottom: 25px !important;
  }
  .ptb_sm--30 {
    padding: 30px 0 !important;
  }
  .plr_sm--30 {
    padding: 0 30px !important;
  }
  .pt_sm--30 {
    padding-top: 30px !important;
  }
  .pb_sm--30 {
    padding-bottom: 30px !important;
  }
  .pl_sm--30 {
    padding-left: 30px !important;
  }
  .pr_sm--30 {
    padding-right: 30px !important;
  }
  .mt_sm--30 {
    margin-top: 30px !important;
  }
  .ml_sm--30 {
    margin-left: 30px !important;
  }
  .mr_sm--30 {
    margin-right: 30px !important;
  }
  .mb_sm--30 {
    margin-bottom: 30px !important;
  }
  .ptb_sm--35 {
    padding: 35px 0 !important;
  }
  .plr_sm--35 {
    padding: 0 35px !important;
  }
  .pt_sm--35 {
    padding-top: 35px !important;
  }
  .pb_sm--35 {
    padding-bottom: 35px !important;
  }
  .pl_sm--35 {
    padding-left: 35px !important;
  }
  .pr_sm--35 {
    padding-right: 35px !important;
  }
  .mt_sm--35 {
    margin-top: 35px !important;
  }
  .ml_sm--35 {
    margin-left: 35px !important;
  }
  .mr_sm--35 {
    margin-right: 35px !important;
  }
  .mb_sm--35 {
    margin-bottom: 35px !important;
  }
  .ptb_sm--40 {
    padding: 40px 0 !important;
  }
  .plr_sm--40 {
    padding: 0 40px !important;
  }
  .pt_sm--40 {
    padding-top: 40px !important;
  }
  .pb_sm--40 {
    padding-bottom: 40px !important;
  }
  .pl_sm--40 {
    padding-left: 40px !important;
  }
  .pr_sm--40 {
    padding-right: 40px !important;
  }
  .mt_sm--40 {
    margin-top: 40px !important;
  }
  .ml_sm--40 {
    margin-left: 40px !important;
  }
  .mr_sm--40 {
    margin-right: 40px !important;
  }
  .mb_sm--40 {
    margin-bottom: 40px !important;
  }
  .ptb_sm--45 {
    padding: 45px 0 !important;
  }
  .plr_sm--45 {
    padding: 0 45px !important;
  }
  .pt_sm--45 {
    padding-top: 45px !important;
  }
  .pb_sm--45 {
    padding-bottom: 45px !important;
  }
  .pl_sm--45 {
    padding-left: 45px !important;
  }
  .pr_sm--45 {
    padding-right: 45px !important;
  }
  .mt_sm--45 {
    margin-top: 45px !important;
  }
  .ml_sm--45 {
    margin-left: 45px !important;
  }
  .mr_sm--45 {
    margin-right: 45px !important;
  }
  .mb_sm--45 {
    margin-bottom: 45px !important;
  }
  .ptb_sm--50 {
    padding: 50px 0 !important;
  }
  .plr_sm--50 {
    padding: 0 50px !important;
  }
  .pt_sm--50 {
    padding-top: 50px !important;
  }
  .pb_sm--50 {
    padding-bottom: 50px !important;
  }
  .pl_sm--50 {
    padding-left: 50px !important;
  }
  .pr_sm--50 {
    padding-right: 50px !important;
  }
  .mt_sm--50 {
    margin-top: 50px !important;
  }
  .ml_sm--50 {
    margin-left: 50px !important;
  }
  .mr_sm--50 {
    margin-right: 50px !important;
  }
  .mb_sm--50 {
    margin-bottom: 50px !important;
  }
  .ptb_sm--55 {
    padding: 55px 0 !important;
  }
  .plr_sm--55 {
    padding: 0 55px !important;
  }
  .pt_sm--55 {
    padding-top: 55px !important;
  }
  .pb_sm--55 {
    padding-bottom: 55px !important;
  }
  .pl_sm--55 {
    padding-left: 55px !important;
  }
  .pr_sm--55 {
    padding-right: 55px !important;
  }
  .mt_sm--55 {
    margin-top: 55px !important;
  }
  .ml_sm--55 {
    margin-left: 55px !important;
  }
  .mr_sm--55 {
    margin-right: 55px !important;
  }
  .mb_sm--55 {
    margin-bottom: 55px !important;
  }
  .ptb_sm--60 {
    padding: 60px 0 !important;
  }
  .plr_sm--60 {
    padding: 0 60px !important;
  }
  .pt_sm--60 {
    padding-top: 60px !important;
  }
  .pb_sm--60 {
    padding-bottom: 60px !important;
  }
  .pl_sm--60 {
    padding-left: 60px !important;
  }
  .pr_sm--60 {
    padding-right: 60px !important;
  }
  .mt_sm--60 {
    margin-top: 60px !important;
  }
  .ml_sm--60 {
    margin-left: 60px !important;
  }
  .mr_sm--60 {
    margin-right: 60px !important;
  }
  .mb_sm--60 {
    margin-bottom: 60px !important;
  }
  .ptb_sm--65 {
    padding: 65px 0 !important;
  }
  .plr_sm--65 {
    padding: 0 65px !important;
  }
  .pt_sm--65 {
    padding-top: 65px !important;
  }
  .pb_sm--65 {
    padding-bottom: 65px !important;
  }
  .pl_sm--65 {
    padding-left: 65px !important;
  }
  .pr_sm--65 {
    padding-right: 65px !important;
  }
  .mt_sm--65 {
    margin-top: 65px !important;
  }
  .ml_sm--65 {
    margin-left: 65px !important;
  }
  .mr_sm--65 {
    margin-right: 65px !important;
  }
  .mb_sm--65 {
    margin-bottom: 65px !important;
  }
  .ptb_sm--70 {
    padding: 70px 0 !important;
  }
  .plr_sm--70 {
    padding: 0 70px !important;
  }
  .pt_sm--70 {
    padding-top: 70px !important;
  }
  .pb_sm--70 {
    padding-bottom: 70px !important;
  }
  .pl_sm--70 {
    padding-left: 70px !important;
  }
  .pr_sm--70 {
    padding-right: 70px !important;
  }
  .mt_sm--70 {
    margin-top: 70px !important;
  }
  .ml_sm--70 {
    margin-left: 70px !important;
  }
  .mr_sm--70 {
    margin-right: 70px !important;
  }
  .mb_sm--70 {
    margin-bottom: 70px !important;
  }
  .ptb_sm--75 {
    padding: 75px 0 !important;
  }
  .plr_sm--75 {
    padding: 0 75px !important;
  }
  .pt_sm--75 {
    padding-top: 75px !important;
  }
  .pb_sm--75 {
    padding-bottom: 75px !important;
  }
  .pl_sm--75 {
    padding-left: 75px !important;
  }
  .pr_sm--75 {
    padding-right: 75px !important;
  }
  .mt_sm--75 {
    margin-top: 75px !important;
  }
  .ml_sm--75 {
    margin-left: 75px !important;
  }
  .mr_sm--75 {
    margin-right: 75px !important;
  }
  .mb_sm--75 {
    margin-bottom: 75px !important;
  }
  .ptb_sm--80 {
    padding: 80px 0 !important;
  }
  .plr_sm--80 {
    padding: 0 80px !important;
  }
  .pt_sm--80 {
    padding-top: 80px !important;
  }
  .pb_sm--80 {
    padding-bottom: 80px !important;
  }
  .pl_sm--80 {
    padding-left: 80px !important;
  }
  .pr_sm--80 {
    padding-right: 80px !important;
  }
  .mt_sm--80 {
    margin-top: 80px !important;
  }
  .ml_sm--80 {
    margin-left: 80px !important;
  }
  .mr_sm--80 {
    margin-right: 80px !important;
  }
  .mb_sm--80 {
    margin-bottom: 80px !important;
  }
  .ptb_sm--85 {
    padding: 85px 0 !important;
  }
  .plr_sm--85 {
    padding: 0 85px !important;
  }
  .pt_sm--85 {
    padding-top: 85px !important;
  }
  .pb_sm--85 {
    padding-bottom: 85px !important;
  }
  .pl_sm--85 {
    padding-left: 85px !important;
  }
  .pr_sm--85 {
    padding-right: 85px !important;
  }
  .mt_sm--85 {
    margin-top: 85px !important;
  }
  .ml_sm--85 {
    margin-left: 85px !important;
  }
  .mr_sm--85 {
    margin-right: 85px !important;
  }
  .mb_sm--85 {
    margin-bottom: 85px !important;
  }
  .ptb_sm--90 {
    padding: 90px 0 !important;
  }
  .plr_sm--90 {
    padding: 0 90px !important;
  }
  .pt_sm--90 {
    padding-top: 90px !important;
  }
  .pb_sm--90 {
    padding-bottom: 90px !important;
  }
  .pl_sm--90 {
    padding-left: 90px !important;
  }
  .pr_sm--90 {
    padding-right: 90px !important;
  }
  .mt_sm--90 {
    margin-top: 90px !important;
  }
  .ml_sm--90 {
    margin-left: 90px !important;
  }
  .mr_sm--90 {
    margin-right: 90px !important;
  }
  .mb_sm--90 {
    margin-bottom: 90px !important;
  }
  .ptb_sm--95 {
    padding: 95px 0 !important;
  }
  .plr_sm--95 {
    padding: 0 95px !important;
  }
  .pt_sm--95 {
    padding-top: 95px !important;
  }
  .pb_sm--95 {
    padding-bottom: 95px !important;
  }
  .pl_sm--95 {
    padding-left: 95px !important;
  }
  .pr_sm--95 {
    padding-right: 95px !important;
  }
  .mt_sm--95 {
    margin-top: 95px !important;
  }
  .ml_sm--95 {
    margin-left: 95px !important;
  }
  .mr_sm--95 {
    margin-right: 95px !important;
  }
  .mb_sm--95 {
    margin-bottom: 95px !important;
  }
  .ptb_sm--100 {
    padding: 100px 0 !important;
  }
  .plr_sm--100 {
    padding: 0 100px !important;
  }
  .pt_sm--100 {
    padding-top: 100px !important;
  }
  .pb_sm--100 {
    padding-bottom: 100px !important;
  }
  .pl_sm--100 {
    padding-left: 100px !important;
  }
  .pr_sm--100 {
    padding-right: 100px !important;
  }
  .mt_sm--100 {
    margin-top: 100px !important;
  }
  .ml_sm--100 {
    margin-left: 100px !important;
  }
  .mr_sm--100 {
    margin-right: 100px !important;
  }
  .mb_sm--100 {
    margin-bottom: 100px !important;
  }
  .pl_sm--0 {
    padding-left: 0 !important;
  }
  .pr_sm--0 {
    padding-right: 0 !important;
  }
  .pt_sm--0 {
    padding-top: 0 !important;
  }
  .pb_sm--0 {
    padding-bottom: 0 !important;
  }
  .mr_sm--0 {
    margin-right: 0 !important;
  }
  .ml_sm--0 {
    margin-left: 0 !important;
  }
  .mt_sm--0 {
    margin-top: 0 !important;
  }
  .mb_sm--0 {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important;
  }
  .plr_mobile--5 {
    padding: 0 5px !important;
  }
  .pt_mobile--5 {
    padding-top: 5px !important;
  }
  .pb_mobile--5 {
    padding-bottom: 5px !important;
  }
  .pl_mobile--5 {
    padding-left: 5px !important;
  }
  .pr_mobile--5 {
    padding-right: 5px !important;
  }
  .mt_mobile--5 {
    margin-top: 5px !important;
  }
  .mb_mobile--5 {
    margin-bottom: 5px !important;
  }
  .ptb_mobile--10 {
    padding: 10px 0 !important;
  }
  .plr_mobile--10 {
    padding: 0 10px !important;
  }
  .pt_mobile--10 {
    padding-top: 10px !important;
  }
  .pb_mobile--10 {
    padding-bottom: 10px !important;
  }
  .pl_mobile--10 {
    padding-left: 10px !important;
  }
  .pr_mobile--10 {
    padding-right: 10px !important;
  }
  .mt_mobile--10 {
    margin-top: 10px !important;
  }
  .mb_mobile--10 {
    margin-bottom: 10px !important;
  }
  .ptb_mobile--15 {
    padding: 15px 0 !important;
  }
  .plr_mobile--15 {
    padding: 0 15px !important;
  }
  .pt_mobile--15 {
    padding-top: 15px !important;
  }
  .pb_mobile--15 {
    padding-bottom: 15px !important;
  }
  .pl_mobile--15 {
    padding-left: 15px !important;
  }
  .pr_mobile--15 {
    padding-right: 15px !important;
  }
  .mt_mobile--15 {
    margin-top: 15px !important;
  }
  .mb_mobile--15 {
    margin-bottom: 15px !important;
  }
  .ptb_mobile--20 {
    padding: 20px 0 !important;
  }
  .plr_mobile--20 {
    padding: 0 20px !important;
  }
  .pt_mobile--20 {
    padding-top: 20px !important;
  }
  .pb_mobile--20 {
    padding-bottom: 20px !important;
  }
  .pl_mobile--20 {
    padding-left: 20px !important;
  }
  .pr_mobile--20 {
    padding-right: 20px !important;
  }
  .mt_mobile--20 {
    margin-top: 20px !important;
  }
  .mb_mobile--20 {
    margin-bottom: 20px !important;
  }
  .ptb_mobile--25 {
    padding: 25px 0 !important;
  }
  .plr_mobile--25 {
    padding: 0 25px !important;
  }
  .pt_mobile--25 {
    padding-top: 25px !important;
  }
  .pb_mobile--25 {
    padding-bottom: 25px !important;
  }
  .pl_mobile--25 {
    padding-left: 25px !important;
  }
  .pr_mobile--25 {
    padding-right: 25px !important;
  }
  .mt_mobile--25 {
    margin-top: 25px !important;
  }
  .mb_mobile--25 {
    margin-bottom: 25px !important;
  }
  .ptb_mobile--30 {
    padding: 30px 0 !important;
  }
  .plr_mobile--30 {
    padding: 0 30px !important;
  }
  .pt_mobile--30 {
    padding-top: 30px !important;
  }
  .pb_mobile--30 {
    padding-bottom: 30px !important;
  }
  .pl_mobile--30 {
    padding-left: 30px !important;
  }
  .pr_mobile--30 {
    padding-right: 30px !important;
  }
  .mt_mobile--30 {
    margin-top: 30px !important;
  }
  .mb_mobile--30 {
    margin-bottom: 30px !important;
  }
  .ptb_mobile--35 {
    padding: 35px 0 !important;
  }
  .plr_mobile--35 {
    padding: 0 35px !important;
  }
  .pt_mobile--35 {
    padding-top: 35px !important;
  }
  .pb_mobile--35 {
    padding-bottom: 35px !important;
  }
  .pl_mobile--35 {
    padding-left: 35px !important;
  }
  .pr_mobile--35 {
    padding-right: 35px !important;
  }
  .mt_mobile--35 {
    margin-top: 35px !important;
  }
  .mb_mobile--35 {
    margin-bottom: 35px !important;
  }
  .ptb_mobile--40 {
    padding: 40px 0 !important;
  }
  .plr_mobile--40 {
    padding: 0 40px !important;
  }
  .pt_mobile--40 {
    padding-top: 40px !important;
  }
  .pb_mobile--40 {
    padding-bottom: 40px !important;
  }
  .pl_mobile--40 {
    padding-left: 40px !important;
  }
  .pr_mobile--40 {
    padding-right: 40px !important;
  }
  .mt_mobile--40 {
    margin-top: 40px !important;
  }
  .mb_mobile--40 {
    margin-bottom: 40px !important;
  }
  .ptb_mobile--45 {
    padding: 45px 0 !important;
  }
  .plr_mobile--45 {
    padding: 0 45px !important;
  }
  .pt_mobile--45 {
    padding-top: 45px !important;
  }
  .pb_mobile--45 {
    padding-bottom: 45px !important;
  }
  .pl_mobile--45 {
    padding-left: 45px !important;
  }
  .pr_mobile--45 {
    padding-right: 45px !important;
  }
  .mt_mobile--45 {
    margin-top: 45px !important;
  }
  .mb_mobile--45 {
    margin-bottom: 45px !important;
  }
  .ptb_mobile--50 {
    padding: 50px 0 !important;
  }
  .plr_mobile--50 {
    padding: 0 50px !important;
  }
  .pt_mobile--50 {
    padding-top: 50px !important;
  }
  .pb_mobile--50 {
    padding-bottom: 50px !important;
  }
  .pl_mobile--50 {
    padding-left: 50px !important;
  }
  .pr_mobile--50 {
    padding-right: 50px !important;
  }
  .mt_mobile--50 {
    margin-top: 50px !important;
  }
  .mb_mobile--50 {
    margin-bottom: 50px !important;
  }
  .ptb_mobile--55 {
    padding: 55px 0 !important;
  }
  .plr_mobile--55 {
    padding: 0 55px !important;
  }
  .pt_mobile--55 {
    padding-top: 55px !important;
  }
  .pb_mobile--55 {
    padding-bottom: 55px !important;
  }
  .pl_mobile--55 {
    padding-left: 55px !important;
  }
  .pr_mobile--55 {
    padding-right: 55px !important;
  }
  .mt_mobile--55 {
    margin-top: 55px !important;
  }
  .mb_mobile--55 {
    margin-bottom: 55px !important;
  }
  .ptb_mobile--60 {
    padding: 60px 0 !important;
  }
  .plr_mobile--60 {
    padding: 0 60px !important;
  }
  .pt_mobile--60 {
    padding-top: 60px !important;
  }
  .pb_mobile--60 {
    padding-bottom: 60px !important;
  }
  .pl_mobile--60 {
    padding-left: 60px !important;
  }
  .pr_mobile--60 {
    padding-right: 60px !important;
  }
  .mt_mobile--60 {
    margin-top: 60px !important;
  }
  .mb_mobile--60 {
    margin-bottom: 60px !important;
  }
  .ptb_mobile--65 {
    padding: 65px 0 !important;
  }
  .plr_mobile--65 {
    padding: 0 65px !important;
  }
  .pt_mobile--65 {
    padding-top: 65px !important;
  }
  .pb_mobile--65 {
    padding-bottom: 65px !important;
  }
  .pl_mobile--65 {
    padding-left: 65px !important;
  }
  .pr_mobile--65 {
    padding-right: 65px !important;
  }
  .mt_mobile--65 {
    margin-top: 65px !important;
  }
  .mb_mobile--65 {
    margin-bottom: 65px !important;
  }
  .ptb_mobile--70 {
    padding: 70px 0 !important;
  }
  .plr_mobile--70 {
    padding: 0 70px !important;
  }
  .pt_mobile--70 {
    padding-top: 70px !important;
  }
  .pb_mobile--70 {
    padding-bottom: 70px !important;
  }
  .pl_mobile--70 {
    padding-left: 70px !important;
  }
  .pr_mobile--70 {
    padding-right: 70px !important;
  }
  .mt_mobile--70 {
    margin-top: 70px !important;
  }
  .mb_mobile--70 {
    margin-bottom: 70px !important;
  }
  .ptb_mobile--75 {
    padding: 75px 0 !important;
  }
  .plr_mobile--75 {
    padding: 0 75px !important;
  }
  .pt_mobile--75 {
    padding-top: 75px !important;
  }
  .pb_mobile--75 {
    padding-bottom: 75px !important;
  }
  .pl_mobile--75 {
    padding-left: 75px !important;
  }
  .pr_mobile--75 {
    padding-right: 75px !important;
  }
  .mt_mobile--75 {
    margin-top: 75px !important;
  }
  .mb_mobile--75 {
    margin-bottom: 75px !important;
  }
  .ptb_mobile--80 {
    padding: 80px 0 !important;
  }
  .plr_mobile--80 {
    padding: 0 80px !important;
  }
  .pt_mobile--80 {
    padding-top: 80px !important;
  }
  .pb_mobile--80 {
    padding-bottom: 80px !important;
  }
  .pl_mobile--80 {
    padding-left: 80px !important;
  }
  .pr_mobile--80 {
    padding-right: 80px !important;
  }
  .mt_mobile--80 {
    margin-top: 80px !important;
  }
  .mb_mobile--80 {
    margin-bottom: 80px !important;
  }
  .ptb_mobile--85 {
    padding: 85px 0 !important;
  }
  .plr_mobile--85 {
    padding: 0 85px !important;
  }
  .pt_mobile--85 {
    padding-top: 85px !important;
  }
  .pb_mobile--85 {
    padding-bottom: 85px !important;
  }
  .pl_mobile--85 {
    padding-left: 85px !important;
  }
  .pr_mobile--85 {
    padding-right: 85px !important;
  }
  .mt_mobile--85 {
    margin-top: 85px !important;
  }
  .mb_mobile--85 {
    margin-bottom: 85px !important;
  }
  .ptb_mobile--90 {
    padding: 90px 0 !important;
  }
  .plr_mobile--90 {
    padding: 0 90px !important;
  }
  .pt_mobile--90 {
    padding-top: 90px !important;
  }
  .pb_mobile--90 {
    padding-bottom: 90px !important;
  }
  .pl_mobile--90 {
    padding-left: 90px !important;
  }
  .pr_mobile--90 {
    padding-right: 90px !important;
  }
  .mt_mobile--90 {
    margin-top: 90px !important;
  }
  .mb_mobile--90 {
    margin-bottom: 90px !important;
  }
  .ptb_mobile--95 {
    padding: 95px 0 !important;
  }
  .plr_mobile--95 {
    padding: 0 95px !important;
  }
  .pt_mobile--95 {
    padding-top: 95px !important;
  }
  .pb_mobile--95 {
    padding-bottom: 95px !important;
  }
  .pl_mobile--95 {
    padding-left: 95px !important;
  }
  .pr_mobile--95 {
    padding-right: 95px !important;
  }
  .mt_mobile--95 {
    margin-top: 95px !important;
  }
  .mb_mobile--95 {
    margin-bottom: 95px !important;
  }
  .ptb_mobile--100 {
    padding: 100px 0 !important;
  }
  .plr_mobile--100 {
    padding: 0 100px !important;
  }
  .pt_mobile--100 {
    padding-top: 100px !important;
  }
  .pb_mobile--100 {
    padding-bottom: 100px !important;
  }
  .pl_mobile--100 {
    padding-left: 100px !important;
  }
  .pr_mobile--100 {
    padding-right: 100px !important;
  }
  .mt_mobile--100 {
    margin-top: 100px !important;
  }
  .mb_mobile--100 {
    margin-bottom: 100px !important;
  }
}
.slick-gutter-5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
.slick-gutter-5 .slick-slide {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.slick-gutter-10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.slick-gutter-10 .slick-slide {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.slick-gutter-15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.slick-gutter-15 .slick-slide {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.slick-gutter-20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.slick-gutter-20 .slick-slide {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.slick-gutter-25 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}
.slick-gutter-25 .slick-slide {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.slick-gutter-30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.slick-gutter-30 .slick-slide {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.slick-gutter-35 {
  margin-left: -35px !important;
  margin-right: -35px !important;
}
.slick-gutter-35 .slick-slide {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.slick-gutter-40 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}
.slick-gutter-40 .slick-slide {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.slick-gutter-45 {
  margin-left: -45px !important;
  margin-right: -45px !important;
}
.slick-gutter-45 .slick-slide {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.slick-gutter-50 {
  margin-left: -50px !important;
  margin-right: -50px !important;
}
.slick-gutter-50 .slick-slide {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.slick-gutter-55 {
  margin-left: -55px !important;
  margin-right: -55px !important;
}
.slick-gutter-55 .slick-slide {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.slick-gutter-60 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}
.slick-gutter-60 .slick-slide {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.slick-gutter-65 {
  margin-left: -65px !important;
  margin-right: -65px !important;
}
.slick-gutter-65 .slick-slide {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.slick-gutter-70 {
  margin-left: -70px !important;
  margin-right: -70px !important;
}
.slick-gutter-70 .slick-slide {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.slick-gutter-75 {
  margin-left: -75px !important;
  margin-right: -75px !important;
}
.slick-gutter-75 .slick-slide {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.slick-gutter-80 {
  margin-left: -80px !important;
  margin-right: -80px !important;
}
.slick-gutter-80 .slick-slide {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.slick-gutter-85 {
  margin-left: -85px !important;
  margin-right: -85px !important;
}
.slick-gutter-85 .slick-slide {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.slick-gutter-90 {
  margin-left: -90px !important;
  margin-right: -90px !important;
}
.slick-gutter-90 .slick-slide {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.slick-gutter-95 {
  margin-left: -95px !important;
  margin-right: -95px !important;
}
.slick-gutter-95 .slick-slide {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.slick-gutter-100 {
  margin-left: -100px !important;
  margin-right: -100px !important;
}
.slick-gutter-100 .slick-slide {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

/*======================== 
    Common Style Css
==========================*/
/* Theme Gradient */
.theme-gradient, .counter-style-1 .count-number {
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
  text-transform: capitalize;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-theme-gradient {
  background-color: transparent;
  background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary-alt) 90%);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-gradient, .counter-style-1 .count-number {
    color: var(--color-secondary);
    background: none !important;
  }
}
/* Heading Font  */
.h1 {
  font-size: var(--h1) !important;
}

.h2 {
  font-size: var(--h2) !important;
}

.h3 {
  font-size: var(--h3) !important;
}

.h4 {
  font-size: var(--h4) !important;
}

.h5 {
  font-size: var(--h5) !important;
}

.h6 {
  font-size: var(--h6) !important;
}

/* Text Color  */
.bg-primary-color {
  background: var(--color-primary);
}

.bg-secondary-color {
  background: var(--color-secondary);
}

.bg-tertiary-color {
  background-color: var(--color-tertiary);
}

.bg-color-primary {
  background-color: var(--color-primary) !important;
}

.bg-color-primary-light {
  background-color: var(--color-primary-light) !important;
}

.bg-color-primary-alt {
  background-color: var(--color-primary-alt) !important;
}

.bg-color-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-color-tertiary {
  background-color: var(--color-tertiary) !important;
}

.bg-color-tertiary-alt {
  background-color: var(--color-tertiary-alt) !important;
}

.bg-color-light-green {
  background-color: var(--color-light-green) !important;
}

.bg-color-pink {
  background-color: var(--color-pink) !important;
}

.bg-color-primary-darker {
  background-color: var(--color-primary-darker) !important;
}

.bg-color-secondary-darker {
  background-color: var(--color-secondary-darker) !important;
}

.bg-color-light-green-darker {
  background-color: var(--color-light-green-darker) !important;
}

.bg-color-pink-darker {
  background-color: var(--color-pink-darker) !important;
}

.bg-color-heading {
  background-color: var(--color-heading) !important;
}

.bg-color-body {
  background-color: var(--color-body) !important;
}

.bg-color-dark {
  background-color: var(--color-dark) !important;
}

.bg-color-darker {
  background-color: var(--color-darker) !important;
}

.bg-color-darkest {
  background-color: var(--color-darkest) !important;
}

.bg-color-black {
  background-color: var(--color-black) !important;
}

.bg-color-blacker {
  background-color: var(--color-blacker) !important;
}

.bg-color-blackest {
  background-color: var(--color-blackest) !important;
}

.bg-color-border {
  background-color: var(--color-border) !important;
}

.bg-color-gray {
  background-color: var(--color-gray) !important;
}

.bg-color-midgray {
  background-color: var(--color-midgray) !important;
}

.bg-color-light {
  background-color: var(--color-light) !important;
}

.bg-color-lighter {
  background-color: var(--color-lighter) !important;
}

.bg-color-lightest {
  background-color: var(--color-lightest) !important;
}

.bg-color-white {
  background-color: var(--color-white) !important;
}

/* Radius */
/* Font Weight */
/* Shadows */
/* Others  */
.liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

.transition-transform {
  transition: var(--transition-transform);
}

.list-icon {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-icon li {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
@media only screen and (max-width: 767px) {
  .list-icon li {
    font-size: 16px;
  }
}
.list-icon li .icon {
  width: 30px;
  background-color: var(--color-blackest);
  height: 30px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  margin-right: 9px;
  min-width: 30px;
  min-height: 30px;
}
.list-icon li .icon.background-transparent {
  background-color: transparent;
}
.list-icon li .icon i {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rn-sub-badge {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}

/* ========= Forms Styles ========= */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

input,
select,
textarea {
  width: 100%;
  transition: 0.3s;
}

input {
  height: 40px;
  padding: 0 15px;
}

select {
  transition: 0.3s;
  height: 60px;
  line-height: 28px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=tel],
textarea {
  font-size: 16px;
  font-weight: 400;
  height: auto;
  line-height: 28px;
  background: transparent;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  color: var(--color-body);
  /* -- Placeholder -- */
}
input[type=text]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=number]::-moz-placeholder, input[type=tel]::-moz-placeholder, textarea::-moz-placeholder {
  color: var(--body-color);
  /* Firefox */
  opacity: 1;
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=email]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
textarea::placeholder {
  color: var(--body-color);
  /* Firefox */
  opacity: 1;
}
input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--body-color);
  opacity: 1;
}
input[type=text]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder,
input[type=number]::-ms-input-placeholder,
input[type=tel]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--body-color);
  opacity: 1;
}
input[type=text].p-holder__active, .input-active input[type=text], input[type=text].input-active,
input[type=password].p-holder__active,
.input-active input[type=password],
input[type=password].input-active,
input[type=email].p-holder__active,
.input-active input[type=email],
input[type=email].input-active,
input[type=number].p-holder__active,
.input-active input[type=number],
input[type=number].input-active,
input[type=tel].p-holder__active,
.input-active input[type=tel],
input[type=tel].input-active,
textarea.p-holder__active,
textarea.input-active {
  border-color: var(--color-primary);
  /* -- Placeholder -- */
}
input[type=text].p-holder__active::-moz-placeholder, .input-active input[type=text]::-moz-placeholder, input[type=text].input-active::-moz-placeholder, input[type=password].p-holder__active::-moz-placeholder, .input-active input[type=password]::-moz-placeholder, input[type=password].input-active::-moz-placeholder, input[type=email].p-holder__active::-moz-placeholder, .input-active input[type=email]::-moz-placeholder, input[type=email].input-active::-moz-placeholder, input[type=number].p-holder__active::-moz-placeholder, .input-active input[type=number]::-moz-placeholder, input[type=number].input-active::-moz-placeholder, input[type=tel].p-holder__active::-moz-placeholder, .input-active input[type=tel]::-moz-placeholder, input[type=tel].input-active::-moz-placeholder, textarea.p-holder__active::-moz-placeholder, textarea.input-active::-moz-placeholder {
  color: var(--color-primary);
  /* Firefox */
  opacity: 1;
}
input[type=text].p-holder__active::placeholder, .input-active input[type=text]::placeholder, input[type=text].input-active::placeholder,
input[type=password].p-holder__active::placeholder,
.input-active input[type=password]::placeholder,
input[type=password].input-active::placeholder,
input[type=email].p-holder__active::placeholder,
.input-active input[type=email]::placeholder,
input[type=email].input-active::placeholder,
input[type=number].p-holder__active::placeholder,
.input-active input[type=number]::placeholder,
input[type=number].input-active::placeholder,
input[type=tel].p-holder__active::placeholder,
.input-active input[type=tel]::placeholder,
input[type=tel].input-active::placeholder,
textarea.p-holder__active::placeholder,
textarea.input-active::placeholder {
  color: var(--color-primary);
  /* Firefox */
  opacity: 1;
}
input[type=text].p-holder__active:-ms-input-placeholder, .input-active input[type=text]:-ms-input-placeholder, input[type=text].input-active:-ms-input-placeholder,
input[type=password].p-holder__active:-ms-input-placeholder,
.input-active input[type=password]:-ms-input-placeholder,
input[type=password].input-active:-ms-input-placeholder,
input[type=email].p-holder__active:-ms-input-placeholder,
.input-active input[type=email]:-ms-input-placeholder,
input[type=email].input-active:-ms-input-placeholder,
input[type=number].p-holder__active:-ms-input-placeholder,
.input-active input[type=number]:-ms-input-placeholder,
input[type=number].input-active:-ms-input-placeholder,
input[type=tel].p-holder__active:-ms-input-placeholder,
.input-active input[type=tel]:-ms-input-placeholder,
input[type=tel].input-active:-ms-input-placeholder,
textarea.p-holder__active:-ms-input-placeholder,
textarea.input-active:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-primary);
}
input[type=text].p-holder__active::-ms-input-placeholder, .input-active input[type=text]::-ms-input-placeholder, input[type=text].input-active::-ms-input-placeholder,
input[type=password].p-holder__active::-ms-input-placeholder,
.input-active input[type=password]::-ms-input-placeholder,
input[type=password].input-active::-ms-input-placeholder,
input[type=email].p-holder__active::-ms-input-placeholder,
.input-active input[type=email]::-ms-input-placeholder,
input[type=email].input-active::-ms-input-placeholder,
input[type=number].p-holder__active::-ms-input-placeholder,
.input-active input[type=number]::-ms-input-placeholder,
input[type=number].input-active::-ms-input-placeholder,
input[type=tel].p-holder__active::-ms-input-placeholder,
.input-active input[type=tel]::-ms-input-placeholder,
input[type=tel].input-active::-ms-input-placeholder,
textarea.p-holder__active::-ms-input-placeholder,
textarea.input-active::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-primary);
}
input[type=text].p-holder__error, .input-error input[type=text], input[type=text].input-error,
input[type=password].p-holder__error,
.input-error input[type=password],
input[type=password].input-error,
input[type=email].p-holder__error,
.input-error input[type=email],
input[type=email].input-error,
input[type=number].p-holder__error,
.input-error input[type=number],
input[type=number].input-error,
input[type=tel].p-holder__error,
.input-error input[type=tel],
input[type=tel].input-error,
textarea.p-holder__error,
textarea.input-error {
  border-color: #f4282d;
  /* -- Placeholder -- */
}
input[type=text].p-holder__error::-moz-placeholder, .input-error input[type=text]::-moz-placeholder, input[type=text].input-error::-moz-placeholder, input[type=password].p-holder__error::-moz-placeholder, .input-error input[type=password]::-moz-placeholder, input[type=password].input-error::-moz-placeholder, input[type=email].p-holder__error::-moz-placeholder, .input-error input[type=email]::-moz-placeholder, input[type=email].input-error::-moz-placeholder, input[type=number].p-holder__error::-moz-placeholder, .input-error input[type=number]::-moz-placeholder, input[type=number].input-error::-moz-placeholder, input[type=tel].p-holder__error::-moz-placeholder, .input-error input[type=tel]::-moz-placeholder, input[type=tel].input-error::-moz-placeholder, textarea.p-holder__error::-moz-placeholder, textarea.input-error::-moz-placeholder {
  color: #f4282d;
  /* Firefox */
  opacity: 1;
}
input[type=text].p-holder__error::placeholder, .input-error input[type=text]::placeholder, input[type=text].input-error::placeholder,
input[type=password].p-holder__error::placeholder,
.input-error input[type=password]::placeholder,
input[type=password].input-error::placeholder,
input[type=email].p-holder__error::placeholder,
.input-error input[type=email]::placeholder,
input[type=email].input-error::placeholder,
input[type=number].p-holder__error::placeholder,
.input-error input[type=number]::placeholder,
input[type=number].input-error::placeholder,
input[type=tel].p-holder__error::placeholder,
.input-error input[type=tel]::placeholder,
input[type=tel].input-error::placeholder,
textarea.p-holder__error::placeholder,
textarea.input-error::placeholder {
  color: #f4282d;
  /* Firefox */
  opacity: 1;
}
input[type=text].p-holder__error:-ms-input-placeholder, .input-error input[type=text]:-ms-input-placeholder, input[type=text].input-error:-ms-input-placeholder,
input[type=password].p-holder__error:-ms-input-placeholder,
.input-error input[type=password]:-ms-input-placeholder,
input[type=password].input-error:-ms-input-placeholder,
input[type=email].p-holder__error:-ms-input-placeholder,
.input-error input[type=email]:-ms-input-placeholder,
input[type=email].input-error:-ms-input-placeholder,
input[type=number].p-holder__error:-ms-input-placeholder,
.input-error input[type=number]:-ms-input-placeholder,
input[type=number].input-error:-ms-input-placeholder,
input[type=tel].p-holder__error:-ms-input-placeholder,
.input-error input[type=tel]:-ms-input-placeholder,
input[type=tel].input-error:-ms-input-placeholder,
textarea.p-holder__error:-ms-input-placeholder,
textarea.input-error:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f4282d;
}
input[type=text].p-holder__error::-ms-input-placeholder, .input-error input[type=text]::-ms-input-placeholder, input[type=text].input-error::-ms-input-placeholder,
input[type=password].p-holder__error::-ms-input-placeholder,
.input-error input[type=password]::-ms-input-placeholder,
input[type=password].input-error::-ms-input-placeholder,
input[type=email].p-holder__error::-ms-input-placeholder,
.input-error input[type=email]::-ms-input-placeholder,
input[type=email].input-error::-ms-input-placeholder,
input[type=number].p-holder__error::-ms-input-placeholder,
.input-error input[type=number]::-ms-input-placeholder,
input[type=number].input-error::-ms-input-placeholder,
input[type=tel].p-holder__error::-ms-input-placeholder,
.input-error input[type=tel]::-ms-input-placeholder,
input[type=tel].input-error::-ms-input-placeholder,
textarea.p-holder__error::-ms-input-placeholder,
textarea.input-error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f4282d;
}
input[type=text].p-holder__error:focus, .input-error input[type=text]:focus, input[type=text].input-error:focus,
input[type=password].p-holder__error:focus,
.input-error input[type=password]:focus,
input[type=password].input-error:focus,
input[type=email].p-holder__error:focus,
.input-error input[type=email]:focus,
input[type=email].input-error:focus,
input[type=number].p-holder__error:focus,
.input-error input[type=number]:focus,
input[type=number].input-error:focus,
input[type=tel].p-holder__error:focus,
.input-error input[type=tel]:focus,
input[type=tel].input-error:focus,
textarea.p-holder__error:focus,
textarea.input-error:focus {
  border-color: #f4282d;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=tel]:focus,
textarea:focus {
  border-color: var(--color-primary);
}

input[type=checkbox],
input[type=radio] {
  opacity: 0;
  position: absolute;
}
input[type=checkbox] ~ label,
input[type=radio] ~ label {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: var(--body-color);
  font-weight: 400;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 0;
}
input[type=checkbox] ~ label::before,
input[type=radio] ~ label::before {
  content: " ";
  position: absolute;
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: var(--border-width) solid rgba(24, 152, 165, 0.2);
  border-radius: 2px;
  transition: all 0.3s;
}
input[type=checkbox] ~ label::after,
input[type=radio] ~ label::after {
  content: " ";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 10px;
  height: 5px;
  background-color: transparent;
  border-bottom: var(--border-width) solid #fff;
  border-left: var(--border-width) solid #fff;
  border-radius: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.3s;
}
input[type=checkbox]:checked ~ label::before,
input[type=radio]:checked ~ label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
input[type=checkbox]:checked ~ label::after,
input[type=radio]:checked ~ label::after {
  opacity: 1;
}

input[type=radio] ~ label::before {
  border-radius: 50%;
}
input[type=radio] ~ label::after {
  width: 8px;
  height: 8px;
  left: 3px;
  background: #fff;
  border-radius: 50%;
}

.form-group {
  margin-bottom: 20px;
  display: inline-block;
}
.form-group input {
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  height: 50px;
  font-size: 16px;
  padding: 0 15px;
}
.form-group input:focus {
  border-color: var(--color-primary);
}
.form-group textarea {
  min-height: 193px;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  resize: none;
  padding: 15px;
  font-size: 16px;
}
.form-group textarea:focus {
  border-color: var(--color-primary);
}

input[type=submit] {
  width: auto;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 20px;
  border-radius: 6px;
  display: block;
  font-weight: 500;
  transition: 0.3s;
  border: var(--border-width) solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  height: 60px;
  margin-top: 30px;
}
input[type=submit]:hover {
  color: #ffffff;
  box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  transform: translateY(-5px);
}

.contact-form--1 form input::-webkit-input-placeholder,
.contact-form--1 form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-body) !important;
}

.contact-form--1 form input::-moz-placeholder,
.contact-form--1 form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-ms-input-placeholder,
.contact-form--1 form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-moz-placeholder,
.contact-form--1 form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-body) !important;
}

/**
 * Settings
 */
/**
  * Easings
  */
/**
  * Core
  */
[data-sal] {
  transition-duration: 0.2s;
  transition-delay: 0s;
  transition-duration: var(--sal-duration, 0.2s);
  transition-delay: var(--sal-delay, 0s);
  transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
  transition-duration: 0.2s;
}

[data-sal][data-sal-duration="250"] {
  transition-duration: 0.25s;
}

[data-sal][data-sal-duration="300"] {
  transition-duration: 0.3s;
}

[data-sal][data-sal-duration="350"] {
  transition-duration: 0.35s;
}

[data-sal][data-sal-duration="400"] {
  transition-duration: 0.4s;
}

[data-sal][data-sal-duration="450"] {
  transition-duration: 0.45s;
}

[data-sal][data-sal-duration="500"] {
  transition-duration: 0.5s;
}

[data-sal][data-sal-duration="550"] {
  transition-duration: 0.55s;
}

[data-sal][data-sal-duration="600"] {
  transition-duration: 0.6s;
}

[data-sal][data-sal-duration="650"] {
  transition-duration: 0.65s;
}

[data-sal][data-sal-duration="700"] {
  transition-duration: 0.7s;
}

[data-sal][data-sal-duration="750"] {
  transition-duration: 0.75s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: 0.8s;
}

[data-sal][data-sal-duration="850"] {
  transition-duration: 0.85s;
}

[data-sal][data-sal-duration="900"] {
  transition-duration: 0.9s;
}

[data-sal][data-sal-duration="950"] {
  transition-duration: 0.95s;
}

[data-sal][data-sal-duration="1000"] {
  transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
  transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
  transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
  transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
  transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
  transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
  transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
  transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
  transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
  transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
  transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
  transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
  transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
  transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
  transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
  transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
  transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
  transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
  transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
  transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
  transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
  transition-delay: 0.05s;
}

[data-sal][data-sal-delay="100"] {
  transition-delay: 0.1s;
}

[data-sal][data-sal-delay="150"] {
  transition-delay: 0.15s;
}

[data-sal][data-sal-delay="200"] {
  transition-delay: 0.2s;
}

[data-sal][data-sal-delay="250"] {
  transition-delay: 0.25s;
}

[data-sal][data-sal-delay="300"] {
  transition-delay: 0.3s;
}

[data-sal][data-sal-delay="350"] {
  transition-delay: 0.35s;
}

[data-sal][data-sal-delay="400"] {
  transition-delay: 0.4s;
}

[data-sal][data-sal-delay="450"] {
  transition-delay: 0.45s;
}

[data-sal][data-sal-delay="500"] {
  transition-delay: 0.5s;
}

[data-sal][data-sal-delay="550"] {
  transition-delay: 0.55s;
}

[data-sal][data-sal-delay="600"] {
  transition-delay: 0.6s;
}

[data-sal][data-sal-delay="650"] {
  transition-delay: 0.65s;
}

[data-sal][data-sal-delay="700"] {
  transition-delay: 0.7s;
}

[data-sal][data-sal-delay="750"] {
  transition-delay: 0.75s;
}

[data-sal][data-sal-delay="800"] {
  transition-delay: 0.8s;
}

[data-sal][data-sal-delay="850"] {
  transition-delay: 0.85s;
}

[data-sal][data-sal-delay="900"] {
  transition-delay: 0.9s;
}

[data-sal][data-sal-delay="950"] {
  transition-delay: 0.95s;
}

[data-sal][data-sal-delay="1000"] {
  transition-delay: 1s;
}

[data-sal][data-sal-easing=linear] {
  transition-timing-function: linear;
}

[data-sal][data-sal-easing=ease] {
  transition-timing-function: ease;
}

[data-sal][data-sal-easing=ease-in] {
  transition-timing-function: ease-in;
}

[data-sal][data-sal-easing=ease-out] {
  transition-timing-function: ease-out;
}

[data-sal][data-sal-easing=ease-in-out] {
  transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[data-sal][data-sal-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-circ] {
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

[data-sal][data-sal-easing=ease-out-circ] {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[data-sal][data-sal-easing=ease-in-out-circ] {
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

[data-sal][data-sal-easing=ease-in-expo] {
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

[data-sal][data-sal-easing=ease-out-expo] {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

[data-sal][data-sal-easing=ease-in-out-expo] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-sal][data-sal-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-sal][data-sal-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-sal][data-sal-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

[data-sal][data-sal-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-sal][data-sal-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-sal][data-sal-easing=ease-in-quint] {
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

[data-sal][data-sal-easing=ease-out-quint] {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

[data-sal][data-sal-easing=ease-in-out-quint] {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

[data-sal][data-sal-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-sal][data-sal-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-sal][data-sal-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-sal][data-sal-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-sal][data-sal-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-sal][data-sal-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/**
  * Animations
  */
[data-sal|=fade] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|=fade].sal-animate,
body.sal-disabled [data-sal|=fade] {
  opacity: 1;
}

[data-sal|=slide] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal=slide-up] {
  transform: translateY(20%);
}

[data-sal=slide-down] {
  transform: translateY(-20%);
}

[data-sal=slide-left] {
  transform: translateX(20%);
}

[data-sal=slide-right] {
  transform: translateX(-20%);
}

[data-sal|=slide].sal-animate,
body.sal-disabled [data-sal|=slide] {
  opacity: 1;
  transform: none;
}

[data-sal|=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal=zoom-in] {
  transform: scale(0.5);
}

[data-sal=zoom-out] {
  transform: scale(1.1);
}

[data-sal|=zoom].sal-animate,
body.sal-disabled [data-sal|=zoom] {
  opacity: 1;
  transform: none;
}

[data-sal|=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal=flip-left] {
  transform: perspective(2000px) rotateY(-91deg);
}

[data-sal=flip-right] {
  transform: perspective(2000px) rotateY(91deg);
}

[data-sal=flip-up] {
  transform: perspective(2000px) rotateX(-91deg);
}

[data-sal=flip-down] {
  transform: perspective(2000px) rotateX(91deg);
}

[data-sal|=flip].sal-animate,
body.sal-disabled [data-sal|=flip] {
  transform: none;
}

.cd-intro {
  margin: 4em auto;
}

@media only screen and (min-width: 768px) {
  .cd-intro {
    margin: 5em auto;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-intro {
    margin: 6em auto;
  }
}
.cd-headline {
  font-size: 3rem;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .cd-headline {
    font-size: 4.4rem;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-headline {
    font-size: 48px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-headline {
    font-size: 40px;
  }
}
@media only screen and (max-width: 479px) {
  .cd-headline {
    font-size: 26px;
  }
}
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* -------------------------------- 

xclip 

-------------------------------- */
.cd-headline.clip span {
  display: inline-block;
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: middle;
}

.cd-headline.clip .cd-words-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 80%;
  background-color: var(--color-secondary);
  transform: translateY(-50%);
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline.clip b.is-visible {
  opacity: 1;
}

/**************************************
    Theme Styles
***************************************/
/* Theme Bg Color  */
.rn-gradient-circle {
  position: fixed;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
  opacity: 0.25;
  filter: blur(100px);
}
.rn-gradient-circle.theme-pink {
  left: auto;
  top: -250px;
  right: -250px;
  bottom: auto;
  background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.theme-shape {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.theme-shape::before {
  position: absolute;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
  opacity: 0.2;
  filter: blur(100px);
  content: "";
}
.theme-shape::after {
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  opacity: 0.2;
  filter: blur(100px);
  content: "";
  left: auto;
  top: -250px;
  right: -250px;
  bottom: auto;
  background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.link-hover a {
  display: inline-block;
  position: relative;
}
.link-hover a::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: currentColor;
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}
.link-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/**************************************
    Header Styles
***************************************/
/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
  display: none;
}

.header-default {
  max-width: 100%;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-darker);
  color: #fff;
  font-size: 12px;
  z-index: 99;
  position: relative;
}
.header-default .logo a {
  display: flex;
  height: 80px;
  line-height: 80px;
  align-items: center;
}
.header-default .logo a img {
  max-height: 45px;
}
.header-default .header-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header-default.header-relative {
  position: relative;
}
.header-default .header-right {
  display: flex;
  justify-content: flex-end;
}
.header-default .header-right .header-btn {
  margin-left: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-default .header-right .header-btn {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-default .header-right .header-btn {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-default a.btn-default,
  .header-default button.btn-default {
    height: 36px;
    line-height: 35px;
    padding: 0 10px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .header-default a.btn-default.btn-small,
  .header-default button.btn-default.btn-small {
    height: 36px;
    line-height: 35px;
    padding: 0 10px;
    font-size: 12px;
  }
}
.header-default.header-transparent {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  background: transparent !important;
  -webkit-backdrop-filter: inherit;
          backdrop-filter: inherit;
}

.header-transparent-with-topbar {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar {
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar {
    position: relative;
  }
}
.header-transparent-with-topbar .header-top-bar {
  background-color: transparent;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar .header-top-bar {
    background-color: var(--color-darker);
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar .header-top-bar {
    background-color: var(--color-darker);
  }
}
.header-transparent-with-topbar .header-default {
  background-color: transparent;
  background-image: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar .header-default {
    background-color: var(--color-darker);
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar .header-default {
    background-color: var(--color-darker);
  }
}

.hamberger-button {
  background: var(--color-blackest);
  border: 0 none;
  color: var(--color-white);
  width: 40px;
  height: 40px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.header-left-align .mainmenu-nav {
  margin-left: 30px;
}

.rn-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-dark) !important;
  width: 100%;
  height: 80px;
  animation: headerSticky 0.95s ease forwards;
  z-index: 9;
}

@keyframes headerSticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
/*--------------------------
    Navigation Styles 
----------------------------*/
.mainmenu-nav .mainmenu {
  display: flex;
  margin: 0;
  padding: 0;
}
.mainmenu-nav .mainmenu > li > a {
  color: var(--color-body);
  font-size: 16px;
  font-weight: 500;
  padding: 0 17px;
  display: block;
  height: 80px;
  line-height: 80px;
  transition: 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainmenu-nav .mainmenu > li > a {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainmenu-nav .mainmenu > li > a {
    padding: 0 12px;
  }
}
.mainmenu-nav .mainmenu > li > a:hover, .mainmenu-nav .mainmenu > li > a.active {
  color: var(--color-primary) !important;
}
.mainmenu-nav .mainmenu > li.current > a {
  color: var(--color-primary) !important;
}
.mainmenu-nav .mainmenu li {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}
.mainmenu-nav .mainmenu li.has-droupdown .submenu {
  min-width: 260px;
  height: auto;
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  padding: 12px 0;
  transition: 0.3s;
  border-radius: 0 0 10px 10px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
.mainmenu-nav .mainmenu li.has-droupdown .submenu li a {
  font-weight: 500;
  padding: 5px 20px;
  font-size: 14px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 3px;
}
.mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
  color: var(--color-primary) !important;
  background: var(--color-darker);
}
.mainmenu-nav .mainmenu li.has-droupdown .submenu li a.active {
  color: var(--color-primary) !important;
  background: var(--color-darker);
}
.mainmenu-nav .mainmenu li.has-droupdown:hover .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.mainmenu-nav .mainmenu li.with-megamenu {
  position: static;
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu {
  position: absolute;
  transition: 0.3s;
  top: 90%;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 7px;
  visibility: hidden;
  opacity: 0;
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper {
  border-radius: 0 0 10px 10px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item {
  padding: 22px 10px;
  border-right: 1px solid var(--color-extra04);
  height: 100%;
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a {
  font-weight: 500;
  padding: 5px 14px;
  font-size: 15px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 4px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a .rn-badge-card {
  margin-left: 10px;
}
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover, .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a.active {
  color: var(--color-primary) !important;
  background: var(--color-darker);
}
.mainmenu-nav .mainmenu li.with-megamenu .single-mega-item:last-child .mega-menu-item {
  border-right: 0 none;
}
.mainmenu-nav .mainmenu li.with-megamenu:hover .rn-megamenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

/*--------------------------
    Header Top Styles  
-----------------------------*/
.header-top-news {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  z-index: 99;
  transition: 0.4s;
  margin-bottom: -1px;
}
.header-top-news.bg-image1 {
  background-image: url(/build/images/top-banner.27086e0f.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.header-top-news .inner {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .header-top-news .inner {
    display: block;
    text-align: center;
    padding: 15px 0;
  }
}
.header-top-news .inner .content .news-text {
  display: inline-block;
  margin-left: 15px;
  color: var(--color-body);
  font-size: 14px;
}
@media only screen and (max-width: 575px) {
  .header-top-news .inner .content .news-text {
    margin-left: 0;
    margin-top: 10px;
    display: block;
  }
}
.header-top-news .inner .right-button {
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  .header-top-news .inner .right-button {
    margin-left: 0;
  }
}
.header-top-news .icon-close {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .header-top-news .icon-close {
    right: 10px;
    top: 9px;
    transform: none;
  }
}
.header-top-news .close-button {
  background: transparent;
  color: #fff;
  border: 2px solid var(--color-border);
  width: 35px;
  display: inline-block;
  height: 35px;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  line-height: 30px;
  transition: 0.3s;
}
.header-top-news .close-button:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.header-top-news.deactive {
  display: none;
}

/*----------------------------
    Header Top Bar  
----------------------------*/
.header-top-bar {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-darker);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-bar .header-left {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .header-top-bar .header-left {
    text-align: center;
  }
}
.header-top-bar .header-left p {
  margin-bottom: 0;
}
.header-top-bar .header-left p a {
  transition: 0.3s;
}
.header-top-bar .header-left p a i {
  position: relative;
  top: 2px;
}
.header-top-bar .header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-bar .header-right {
    justify-content: center;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .header-top-bar .header-right {
    justify-content: center;
    margin-top: 6px;
  }
}
.header-top-bar .header-right .address-content {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
.header-top-bar .header-right .address-content p {
  margin-bottom: 0;
  padding: 0 10px;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .header-top-bar .header-right .address-content p {
    padding: 0 8px;
  }
}
.header-top-bar .header-right .address-content p + p::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background: var(--color-border);
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
}
.header-top-bar .header-right .address-content p a {
  transition: 0.3s;
}
.header-top-bar .header-right .address-content p i {
  margin-right: 8px;
  font-size: 16px;
}
.header-top-bar .social-default.icon-naked li {
  margin: 1px;
}
.header-top-bar .social-default.icon-naked li a {
  color: var(--color-body);
  font-size: 15px;
  line-height: 26px;
}
.header-top-bar .social-icon-wrapper {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
}
@media only screen and (max-width: 575px) {
  .header-top-bar .social-icon-wrapper {
    margin-left: 0;
    padding-left: 0;
    flex-basis: 100%;
  }
}
.header-top-bar .social-icon-wrapper::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background: var(--color-border);
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
}
@media only screen and (max-width: 575px) {
  .header-top-bar .social-icon-wrapper::before {
    display: none;
  }
}

.header-transparent-with-topbar {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar {
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar {
    position: relative;
  }
}
.header-transparent-with-topbar .header-top-bar {
  background-color: transparent;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar .header-top-bar {
    background-color: var(--color-darker);
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar .header-top-bar {
    background-color: var(--color-darker);
  }
}
.header-transparent-with-topbar .header-default {
  background-color: transparent;
  background-image: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-transparent-with-topbar .header-default {
    background-color: var(--color-darker);
  }
}
@media only screen and (max-width: 767px) {
  .header-transparent-with-topbar .header-default {
    background-color: var(--color-darker);
  }
}

/*------------------------------
    Popup Menu Styles  
--------------------------------*/
.popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.popup-mobile-menu .inner {
  width: 320px;
  z-index: 999;
  position: absolute;
  background-color: var(--color-blackest);
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  left: -150px;
  transition: all 0.5s ease-out;
}
.popup-mobile-menu .inner .header-top {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.popup-mobile-menu .inner .header-top .logo a img {
  max-height: 45px;
}
.popup-mobile-menu .inner .header-top .close-menu .close-button {
  background: var(--color-darker);
  border: 0 none;
  color: var(--color-white);
  width: 40px;
  height: 40px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.popup-mobile-menu.active {
  visibility: visible;
  opacity: 1;
}
.popup-mobile-menu.active .inner {
  opacity: 1;
  left: 0;
  overflow-y: auto;
}
.popup-mobile-menu .mainmenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 15px 20px;
}
.popup-mobile-menu .mainmenu li {
  margin: 0;
}
.popup-mobile-menu .mainmenu li a {
  padding: 8px 0;
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.popup-mobile-menu .mainmenu li a.active {
  color: var(--color-primary) !important;
}
.popup-mobile-menu .mainmenu li + li {
  border-top: 1px solid var(--color-border);
}
.popup-mobile-menu .mainmenu .has-droupdown .submenu {
  padding: 0;
  max-width: 100%;
  list-style: none;
  padding-left: 14px;
  display: none;
}
.popup-mobile-menu .mainmenu .has-droupdown .submenu li a {
  font-size: 15px;
}
.popup-mobile-menu .mainmenu .has-droupdown .submenu li a.active {
  color: var(--color-primary) !important;
}
.popup-mobile-menu .mainmenu .has-menu-child-item > a {
  position: relative;
}
.popup-mobile-menu .mainmenu .has-menu-child-item > a::after {
  position: absolute;
  content: "\e9b1";
  font-family: "feather" !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}
.popup-mobile-menu .mainmenu .has-menu-child-item > a.open::after {
  content: "\e996";
}
.popup-mobile-menu .mainmenu .rn-megamenu {
  padding: 0;
  max-width: 100%;
  list-style: none;
  padding-left: 14px;
  display: none;
}
.popup-mobile-menu .mainmenu .mega-menu-item {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
}
.popup-mobile-menu .mainmenu .mega-menu-item li a {
  font-size: 15px;
}
.popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
  border-bottom: 1px solid var(--color-border);
}
.popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
  border-bottom-color: transparent;
}

/*----------------------
    One Page Nav 
------------------------*/
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}
.mainmenu-nav.onepagenav .mainmenu > li > a {
  position: relative;
  padding: 0;
}

.mainmenu-nav.onepagenav .mainmenu li {
  margin: 0 17px;
}

.mainmenu-nav.onepagenav .mainmenu > li > a::after {
  position: absolute;
  content: "";
  left: 0;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  bottom: 0;
  opacity: 0;
  transition: 0.4s;
}

.mainmenu-nav.onepagenav .mainmenu > li.current > a::after {
  width: 100%;
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .header-default.header-center-align .header-right .header-btn {
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainmenu-nav.onepagenav .mainmenu li {
    margin: 0 7px;
  }
}
/**************************************
            Elements 
***************************************/
/*====================
    Breadcrump Area 
======================*/
.breadcarumb-style-1 {
  border-bottom: 1px solid var(--color-border);
}

.breadcrumb-inner {
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-inner .title br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-inner .title br {
    display: none;
  }
}
.breadcrumb-inner ul.page-list {
  margin-top: 16px;
}
.breadcrumb-inner ul.page-list li {
  display: inline-block;
  color: var(--color-body);
  padding: 0 12px;
  position: relative;
  font-size: 16px;
  margin: 0;
}
.breadcrumb-inner ul.page-list li::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-body);
  width: 5px;
  height: 5px;
  border-radius: 100%;
  opacity: 0.5;
  right: -5px;
}
.breadcrumb-inner ul.page-list li a {
  color: var(--color-body);
  transition: 0.5s;
}
.breadcrumb-inner ul.page-list li a:hover {
  color: var(--color-primary);
}
.breadcrumb-inner ul.page-list li.rn-breadcrumb-item.active {
  color: var(--color-body);
  opacity: 0.6;
}
.breadcrumb-inner ul.page-list li.rn-breadcrumb-item.active::after {
  display: none;
}

.section-title .subtitle {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
.section-title .subtitle.radius-rounded {
  border-radius: 100px;
}
@media only screen and (max-width: 767px) {
  .section-title .description br {
    display: none;
  }
}
.section-title p:empty {
  display: none;
}

.contact-form-1 .form-group {
  display: block;
}

.section-title-2 .title {
  color: var(--color-body);
  display: block;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 19px;
  opacity: 0.6;
}

/* Button Styles  */
.button-group {
  margin: -10px;
}
.button-group a,
.button-group button {
  margin: 10px;
}

a.btn-default,
button.btn-default,
div.btn-default {
  padding: 0 28px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 8px;
  background-color: transparent;
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
  transition-duration: 300ms;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: var(--shadow-light);
  display: inline-block;
  height: 50px;
  line-height: 47px;
  letter-spacing: 0.5px;
  width: auto;
  transition: 0.4s;
}
a.btn-default.btn-icon .icon,
button.btn-default.btn-icon .icon,
div.btn-default.btn-icon .icon {
  position: relative;
  top: 2px;
  padding-left: 4px;
}
a.btn-default.btn-icon .icon i,
button.btn-default.btn-icon .icon i,
div.btn-default.btn-icon .icon i {
  width: 20px;
  height: 20px;
}
a.btn-default:hover,
button.btn-default:hover,
div.btn-default:hover {
  box-shadow: var(--shadow-primary);
  filter: brightness(125%);
  color: var(--color-white);
  text-decoration: none;
}
a.btn-default.btn-small,
button.btn-default.btn-small,
div.btn-default.btn-small {
  height: 40px;
  line-height: 36px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
}
a.btn-default.btn-medium,
button.btn-default.btn-medium,
div.btn-default.btn-medium {
  height: 65px;
  line-height: 61px;
  padding: 0 40px;
}
a.btn-default.btn-large,
button.btn-default.btn-large,
div.btn-default.btn-large {
  height: 60px;
  line-height: 56px;
  padding: 0 37px;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  a.btn-default.btn-large,
  button.btn-default.btn-large,
  div.btn-default.btn-large {
    padding: 0 25px;
  }
}
a.btn-default.btn-extra-large,
button.btn-default.btn-extra-large,
div.btn-default.btn-extra-large {
  height: 90px;
  line-height: 90px;
  padding: 0 70px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  a.btn-default.btn-extra-large,
  button.btn-default.btn-extra-large,
  div.btn-default.btn-extra-large {
    padding: 0 44px;
  }
}
a.btn-default.btn-border,
button.btn-default.btn-border,
div.btn-default.btn-border {
  background-image: inherit;
  border-color: var(--color-border);
  border-width: 2px;
  border-style: solid;
}
a.btn-default.btn-border:hover,
button.btn-default.btn-border:hover,
div.btn-default.btn-border:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
a.btn-default.text-underline,
button.btn-default.text-underline,
div.btn-default.text-underline {
  background-image: inherit;
  border-color: var(--color-white);
}
a.btn-default.text-underline span,
button.btn-default.text-underline span,
div.btn-default.text-underline span {
  position: relative;
  display: inline-block;
  line-height: 30px;
}
a.btn-default.text-underline:hover,
button.btn-default.text-underline:hover,
div.btn-default.text-underline:hover {
  box-shadow: none;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
a.btn-default.round,
button.btn-default.round,
div.btn-default.round {
  border-radius: 100px;
}
a.btn-default.square,
button.btn-default.square,
div.btn-default.square {
  border-radius: 0;
}
a.btn-default.rounded-player,
button.btn-default.rounded-player,
div.btn-default.rounded-player {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  padding: 0;
  line-height: 100px;
  font-size: 30px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  a.btn-default.rounded-player,
  button.btn-default.rounded-player,
  div.btn-default.rounded-player {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}
a.btn-default.rounded-player span i,
button.btn-default.rounded-player span i,
div.btn-default.rounded-player span i {
  position: relative;
  top: 1px;
  left: 2px;
}
a.btn-default.rounded-player.border,
button.btn-default.rounded-player.border,
div.btn-default.rounded-player.border {
  background-image: inherit;
  border-color: var(--color-white) !important;
  border-width: 2px !important;
}
a.btn-default.rounded-player.border:hover,
button.btn-default.rounded-player.border:hover,
div.btn-default.rounded-player.border:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary) !important;
}
a.btn-default.rounded-player.sm-size,
button.btn-default.rounded-player.sm-size,
div.btn-default.rounded-player.sm-size {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.btn-read-more span {
  position: relative;
  color: var(--color-heading);
  display: inline-block;
  line-height: 35px;
  transition: 0.3s;
}
.btn-read-more span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: var(--color-border);
  transition: 0.3s;
  width: 100%;
  bottom: 3px;
}
.btn-read-more span::before {
  position: absolute;
  content: "";
  left: auto;
  bottom: 2px;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}
.btn-read-more:hover span {
  color: var(--color-primary);
}
.btn-read-more:hover span::before {
  width: 100%;
  left: 0;
  right: auto;
}

.rn-badge {
  display: inline-block;
  background-color: var(--color-primary);
  padding: 0 16px;
  height: 30px;
  line-height: 30px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.rn-badge-card {
  background-color: transparent;
  background: transparent;
  padding: 1px 10px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 2;
  letter-spacing: 0.5px;
}
.rn-badge-card::before {
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  border-radius: 100px;
  opacity: 0.5;
}

/*--------------------
    Service Styles  
----------------------*/
.service.service__style--1 {
  padding: 50px 35px;
  padding-bottom: 55px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--1 {
    padding: 30px 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1 {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--1 {
    padding: 30px 14px;
  }
}
.service.service__style--1 .icon {
  font-size: 45px;
  font-weight: 400;
  margin-bottom: 23px;
  color: var(--color-primary);
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1 .icon {
    margin-bottom: 11px;
  }
}
.service.service__style--1 .icon i {
  stroke-width: 1 !important;
  font-size: 45px;
}
.service.service__style--1 .content .title {
  margin-bottom: 19px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1 .content .title {
    margin-bottom: 8px;
  }
}
.service.service__style--1 .content .title a {
  color: inherit;
}
.service.service__style--1 .content .title a:hover {
  color: var(--color-primary);
}
.service.service__style--1:hover {
  transform: translateY(-5px);
}
.service.service__style--1.rbt-border-none {
  border: none;
}
.service.service__style--1.rbt-border {
  border: 1px solid var(--color-border);
}
.service.service__style--1.text-left, .service.service__style--1.text-start {
  padding: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--1.text-left, .service.service__style--1.text-start {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service.service__style--1.text-left, .service.service__style--1.text-start {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1.text-left, .service.service__style--1.text-start {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--1.text-left, .service.service__style--1.text-start {
    padding: 30px 20px;
  }
}
.service.service__style--1.text-left .icon, .service.service__style--1.text-start .icon {
  justify-content: left;
}
.service.service__style--1.icon-circle-style {
  background-color: transparent;
  box-shadow: none;
  padding: 0px 35px;
  margin-top: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--1.icon-circle-style {
    padding: 0px 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service.service__style--1.icon-circle-style {
    padding: 0px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1.icon-circle-style {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--1.icon-circle-style {
    padding: 0px 10px;
  }
}
.service.service__style--1.icon-circle-style .icon {
  font-size: 39px;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  margin-bottom: 23px;
  background-color: var(--color-primary-darker);
  line-height: 90px;
  align-items: center;
}
.service.service__style--1.with-working-process {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service.service__style--1.with-working-process {
    padding: 20px 0;
  }
}
.service.service__style--1.with-working-process .icon {
  width: 180px;
  height: 180px;
  line-height: 180px;
  font-size: 60px;
  position: relative;
}
.service.service__style--1.with-working-process .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  left: 100%;
  background-color: var(--color-border);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1.with-working-process .line {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--1.with-working-process .line {
    display: none;
  }
}
.service.service__style--1.with-working-process:hover {
  transform: none;
}
.service.gallery-style {
  padding: 40px 30px;
  background: var(--color-blackest);
  border-radius: 24px;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
  margin-top: 30px;
}
.service.gallery-style .content .image img {
  border-radius: 5px;
}
.service.gallery-style .content .title {
  margin-bottom: 19px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.service.gallery-style .content .title a {
  color: inherit;
}
.service.gallery-style .content .title a:hover {
  color: var(--color-primary);
}
.service.gallery-style .content p {
  color: var(--color-body);
  margin-bottom: 20px;
}
.service.service__style--2 {
  padding: 50px 35px;
  padding-bottom: 55px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  position: relative;
  z-index: 2;
  border-radius: 10px;
  margin-top: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--2 {
    padding: 10px 28px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service.service__style--2 {
    padding: 10px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 {
    padding: 10px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--2 {
    padding: 0px 18px;
  }
}
.service.service__style--2 .image {
  margin-bottom: 23px;
}
.service.service__style--2 .image img {
  max-height: 220px;
}
.service.service__style--2 .content .title a {
  color: inherit;
}
.service.service__style--2 .content .title a:hover {
  color: var(--color-primary);
}
.service.service__style--2 .content p {
  padding: 0 5%;
}
.service.service__style--2:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 39px rgba(255, 101, 80, 0.1);
}

.service-wrapper > [class*=col]:nth-child(2) .service.service__style--1 .icon {
  color: var(--color-secondary);
}
.service-wrapper > [class*=col]:nth-child(3) .service.service__style--1 .icon {
  color: var(--color-tertiary);
}
.service-wrapper > [class*=col]:nth-child(4) .service.service__style--1 .icon {
  color: var(--color-pink);
}
.service-wrapper > [class*=col]:nth-child(5) .service.service__style--1 .icon {
  color: var(--color-primary);
}
.service-wrapper > [class*=col]:nth-child(6) .service.service__style--1 .icon {
  color: var(--color-tertiary);
}
.service-wrapper > [class*=col]:nth-child(2) .service.service__style--1.icon-circle-style .icon {
  background-color: var(--color-secondary-darker);
}
.service-wrapper > [class*=col]:nth-child(3) .service.service__style--1.icon-circle-style .icon {
  background-color: var(--color-tertiary-darker);
}
.service-wrapper > [class*=col]:nth-child(4) .service.service__style--1.icon-circle-style .icon {
  background-color: var(--color-pink-darker);
}
.service-wrapper > [class*=col]:nth-child(5) .service.service__style--1.icon-circle-style .icon {
  background-color: var(--color-primary-darker);
}
.service-wrapper > [class*=col]:nth-child(6) .service.service__style--1.icon-circle-style .icon {
  background-color: var(--color-tertiary-darker);
}
.service-wrapper > [class*=col]:last-child .with-working-process .line {
  display: none;
}

/*
    Card Styles  
*/
.card-box.card-style-1 {
  box-shadow: var(--shadow-lighter);
  background-color: var(--color-blackest);
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
}
.card-box .inner .image a img {
  border-radius: 5px;
}
.card-box .inner .content {
  padding-top: 20px;
}
.card-box .inner .content .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 14px;
}
.card-box .inner .content .title a {
  color: inherit;
}
.card-box .inner .content .title a:hover {
  color: var(--color-primary);
}
.card-box .inner .content .description {
  margin-bottom: 0;
}
.card-box .inner .content .btn-default {
  margin-top: 20px;
}

/* Counter Style One  */
.counter-style-1 .count-number {
  font-size: 90px;
}
@media only screen and (max-width: 767px) {
  .counter-style-1 .count-number {
    font-size: 62px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-style-1 .count-number {
    font-size: 45px;
  }
}
.counter-style-1 .title {
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

.counter-style-2 {
  margin-top: 30px;
}
.counter-style-2 .count-number {
  font-size: 44px;
  background-color: var(--color-blackest);
  display: inline-block;
  min-width: 150px;
  height: 150px;
  border-radius: 100%;
  text-align: center;
  line-height: 150px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-weight: 500;
  color: var(--color-white);
}
.counter-style-2 .title {
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 10px;
}
.counter-style-2 .description {
  line-height: 1.8;
  padding-right: 16%;
  margin: 0;
}
.counter-style-2.color-variation .count-number {
  background-color: var(--color-primary-darker);
  color: var(--color-primary);
}
.counter-style-2.border-style .count-number {
  background-color: transparent;
  border: 2px solid var(--color-border);
}
.counter-style-2.text-center .description {
  padding: 0 10%;
}

[class*=col]:nth-child(2) .counter-style-2.color-variation .count-number {
  background-color: var(--color-secondary-darker);
  color: var(--color-secondary);
}

[class*=col]:nth-child(3) .counter-style-2.color-variation .count-number {
  background-color: var(--color-light-green-darker);
  color: var(--color-light-green);
}

/*------------------------------
    Counterup Style Four  
-------------------------------*/
.counter-style-4 {
  text-align: left;
  margin-top: 30px;
}
.counter-style-4 .counter-title {
  margin-top: 8px;
  font-weight: 400;
  font-size: 24px;
}
.counter-style-4 .count-number {
  font-size: 35px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--color-primary);
  display: inline-block;
  position: relative;
}
.counter-style-4 .count-number::after {
  content: "+";
}
.counter-style-4 .count-number span {
  display: inline-block;
}

/*-----------------------
    Progress Bar 
-----------------------*/
.single-progress + .single-progress {
  margin-top: 40px;
}

.single-progress {
  position: relative;
  overflow: hidden;
}
.single-progress .title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: #acacac;
  color: var(--color-body);
  letter-spacing: 0.5px;
  margin-bottom: 18px;
}

.progress {
  height: 6px;
  overflow: visible;
  background-color: var(--color-blackest);
  border-radius: 10px;
}
.progress .progress-bar {
  border-radius: 10px;
  width: 0;
  height: 100%;
  background-color: var(--color-primary);
  transition: width 0.5s ease;
  overflow: visible;
}
.progress .progress-bar.bar-color-2 {
  background: rgb(251, 83, 67);
}
.progress .progress-bar.bar-color-3 {
  background: #E33FA1;
}
.progress .progress-bar.bar-color-4 {
  background: #6549D5;
}
.progress .progress-bar.bar-gradiand {
  background: radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%);
}
.progress .progress-number {
  position: absolute;
  font-size: 14px;
  top: 0;
  line-height: 24px;
  right: 0;
  z-index: 1;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.progress-info .title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--color-body);
  letter-spacing: 0.5px;
}
.progress-info span.progress-number {
  font-size: 14px;
  color: var(--color-body);
}

.no-radius .progress {
  border-radius: 0;
}
.no-radius .progress .progress-bar {
  border-radius: 0;
}

.large-size .progress {
  height: 20px;
}

/*-----------------------------------
    Radial Progress 
-----------------------------------*/
.radial-progress-single {
  text-align: center;
}
.radial-progress-single .circle-info {
  padding-top: 20px;
  text-align: center;
}
.radial-progress-single .circle-info .title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4px;
}
.radial-progress-single .circle-info .subtitle {
  font-style: italic;
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--color-body);
}

.radial-progress {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .radial-progress {
    display: inline-block;
  }
}
.radial-progress .circle-text {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}
.radial-progress .circle-text span.count {
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  color: var(--color-body);
  position: relative;
}
.radial-progress .circle-text span.count::after {
  position: absolute;
  content: "%";
  left: 100%;
  color: var(--color-body);
}

/*--------------------------
    Accordion Styles  
---------------------------*/
.rn-accordion-style .card {
  background: var(--color-lessdark);
  border: 0;
  border-radius: var(--radius) !important;
}
.rn-accordion-style .card .card-header {
  background-color: transparent;
  border-bottom: 0 none;
  box-shadow: none;
  padding: 20px 30px;
  margin-bottom: 0;
}
.rn-accordion-style .card .card-header button {
  color: var(--color-heading);
  font-size: 18px;
  line-height: 27px;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  width: 100%;
  text-align: left;
  padding: 0;
  background: transparent;
  position: relative;
}
.rn-accordion-style .card .card-header button::before {
  position: absolute;
  content: "\e996";
  font-family: "feather" !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}
.rn-accordion-style .card .card-header button.collapsed::before {
  content: "\e9b1";
}
.rn-accordion-style .card .card-body {
  padding: 16px;
  padding-top: 0;
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  padding: 30px 20px;
  border-top: 1px solid var(--color-border);
}
.rn-accordion-style .card + .card {
  margin-top: 20px;
}
.rn-accordion-style.rn-accordion-02 .card {
  background: transparent;
  border-radius: 0 !important;
}
.rn-accordion-style.rn-accordion-02 .card .card-header {
  border-bottom: 2px solid var(--color-border);
  padding: 0px 20px 10px 0;
}
.rn-accordion-style.rn-accordion-02 .card .card-header .accordion-button::after {
  display: none;
}
.rn-accordion-style.rn-accordion-02 .card .card-body {
  padding: 15px 20px 10px 0;
  border-top: 0;
}
.rn-accordion-style.rn-accordion-03 .card {
  background: transparent;
  border-radius: 0 !important;
}
.rn-accordion-style.rn-accordion-03 .card .card-header {
  border: 2px solid var(--color-border);
  padding: 15px 20px;
  border-radius: var(--radius) !important;
}
.rn-accordion-style.rn-accordion-03 .card .card-body {
  padding: 15px 20px 10px 0;
  border: 0;
}
.rn-accordion-style.rn-accordion-04 .card {
  border: 2px solid var(--color-border);
  background: transparent;
  border-radius: var(--radius) !important;
}
.rn-accordion-style.rn-accordion-04 .card .card-header {
  padding: 15px 20px;
  border-radius: var(--radius) !important;
}
.rn-accordion-style.rn-accordion-04 .card .card-body {
  border-top: 2px solid var(--color-border);
}

.accordion-button::after {
  visibility: hidden;
}

/*-----------------------
    Social Icons 
-------------------------*/
.social-default {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  margin: -5px;
}
.social-default li {
  margin: 5px;
}
.social-default li a {
  width: 48px;
  background-color: var(--color-lessdark);
  height: 48px;
  line-height: 45px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  transition: 0.3s;
  color: var(--color-white);
  z-index: 2;
  position: relative;
}
.social-default li a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--color-primary);
  transform: scale(0.5);
  opacity: 0;
  transition: 0.3s;
  border-radius: 100%;
}
.social-default li a:hover {
  color: var(--color-white);
}
.social-default li a:hover::after {
  transform: scale(1);
  opacity: 1;
}
.social-default li a i {
  vertical-align: middle;
}
.social-default.transparent-with-border li a {
  line-height: 45px;
  background: transparent;
  border: 1px solid var(--color-border);
}
.social-default.transparent-with-border li a:hover {
  border-color: transparent;
}
.social-default.transparent-with-border li a::after {
  border: 1px solid var(--color-primary);
}
.social-default.with-gradient li a::after {
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}
.social-default.with-bg-primary li a {
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: 0.5s;
  line-height: 44px;
}
.social-default.with-bg-primary li a::after {
  display: none;
}
.social-default.with-bg-primary li a:hover {
  background-color: transparent;
}
.social-default.icon-naked li a {
  background: transparent !important;
  width: 30px;
  height: 30px;
  line-height: 36px;
}
.social-default.icon-naked li a::after {
  display: none;
}
.social-default.icon-naked li a:hover {
  color: var(--color-primary) !important;
}

/*----------------------
    Team Style  
-------------------------*/
.rn-box-card,
.team-style-default {
  margin-top: 75px;
}
.rn-box-card .inner,
.team-style-default .inner {
  background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
  -webkit-backdrop-filter: blur(4px) opacity(0.95);
  backdrop-filter: blur(4px) opacity(0.95);
  border-radius: var(--radius-big);
}
.rn-box-card .inner .thumbnail,
.team-style-default .inner .thumbnail {
  max-width: 150px;
  max-height: 150px;
  border-radius: 100%;
  border: 6px solid var(--color-lessdark);
  top: -75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 150px;
  min-height: 150px;
  overflow: hidden;
}
.rn-box-card .inner .thumbnail img,
.team-style-default .inner .thumbnail img {
  border-radius: 100%;
}
.rn-box-card .inner .content,
.team-style-default .inner .content {
  text-align: center;
  padding: 20px;
  padding-top: 85px;
  padding-bottom: 35px;
}
.rn-box-card .inner .content .title,
.team-style-default .inner .content .title {
  font-size: 34px;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 6px;
}
.rn-box-card .inner .content .subtitle,
.team-style-default .inner .content .subtitle {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 15px;
  display: inline-block;
}
.rn-box-card .inner .content .team-form,
.team-style-default .inner .content .team-form {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  letter-spacing: 0.6px;
  font-style: italic;
}
.rn-box-card .inner .content .team-form img,
.team-style-default .inner .content .team-form img {
  padding-right: 10px;
  max-height: 18px;
}
.rn-box-card .inner .content .description,
.team-style-default .inner .content .description {
  margin-bottom: 0;
  padding: 0 5%;
}
.rn-box-card .inner .social-icon li a,
.team-style-default .inner .social-icon li a {
  color: var(--color-body);
}
.rn-box-card.border-gradient .thumbnail,
.team-style-default.border-gradient .thumbnail {
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
  padding: 5px;
  border: 0 none;
}
.rn-box-card.style-two,
.team-style-default.style-two {
  margin-top: 30px;
}
.rn-box-card.style-two .inner,
.team-style-default.style-two .inner {
  padding-top: 35px;
}
.rn-box-card.style-two .inner .thumbnail,
.team-style-default.style-two .inner .thumbnail {
  top: 0;
  position: static;
  left: inherit;
  transform: none;
  min-width: 150px;
  min-height: 150px;
  margin: 0 auto;
}
.rn-box-card.style-two .inner .content,
.team-style-default.style-two .inner .content {
  padding-top: 10px;
}

/*---------------------
    Team Style Two  
-----------------------*/
.team-style-two .inner .thumbnail img {
  width: 100%;
  border-radius: var(--radius);
}
.team-style-two .inner .content {
  padding-top: 20px;
}
.team-style-two .inner .content .title {
  font-size: 28px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 0;
}
.team-style-two .inner .content .subtitle {
  font-size: 18px;
  line-height: 1.4;
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 0;
  opacity: 0;
  transform: translateX(30px);
  transition: transform 0.45s cubic-bezier(0.16, 0.32, 0.26, 0.92), opacity 0.45s ease-out;
  transition-delay: 0.15s;
}
.team-style-two .inner .content .team-form {
  display: block;
  font-size: 14px;
  letter-spacing: 0.6px;
  font-style: italic;
  margin-top: 11px;
}
.team-style-two .inner .content .team-form img {
  padding-right: 10px;
  max-height: 18px;
}
.team-style-two:hover .inner .content .subtitle {
  opacity: 1;
  transform: none;
}

/*---------------------
    Team Style Three  
-----------------------*/
.team-style-three .inner {
  position: relative;
}
.team-style-three .inner .thumbnail img {
  width: 100%;
  border-radius: var(--radius);
}
.team-style-three .inner .content {
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
  transition: all 0.3s;
  opacity: 0;
}
.team-style-three .inner .content .team-info {
  padding: 20px;
  background-color: rgba(58, 58, 58, 0.85);
  border-radius: var(--radius-small);
}
.team-style-three .inner .content .title {
  font-size: 17px;
  line-height: 25px;
  color: var(--color-heading);
  margin-bottom: 4px;
}
.team-style-three .inner .content .subtitle {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 4px;
}
.team-style-three .inner .content .team-form {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: #ADADAD;
  letter-spacing: 0.5px;
}
.team-style-three:hover .inner .content {
  opacity: 1;
}

/*---------------------
    Portfolio Styles  
-----------------------*/
.rwt-portfolio-area {
  position: relative;
  overflow: hidden;
}

.rwt-card {
  overflow: hidden;
}
.rwt-card .inner .thumbnail {
  position: relative;
}
.rwt-card .inner .thumbnail .card-image {
  overflow: hidden;
}
.rwt-card .inner .thumbnail .card-image a {
  display: block;
  overflow: hidden;
}
.rwt-card .inner .thumbnail .card-image img {
  overflow: hidden;
}
.rwt-card .inner .content {
  padding-top: 20px;
}
.rwt-card .inner .content .subtitle {
  text-transform: capitalize;
}
.rwt-card:hover {
  transform: translateY(-4px);
}
.rwt-card:hover .inner .thumbnail .card-image img {
  transform: scale(1.05);
}

/*------------------
    No Overlay  
--------------------*/
.no-overlay .rwt-card .inner .thumbnail .rwt-overlay {
  display: none;
}

/*----------------------
    Box Grid Layout  
------------------------*/
.box-grid-layout .rwt-card {
  border-radius: var(--radius-small);
  background-color: var(--color-blackest);
}
.box-grid-layout .rwt-card .inner .thumbnail .card-image a {
  border-radius: var(--radius-small) var(--radius-small) 0 0;
}
.box-grid-layout .rwt-card .inner .thumbnail .card-image a img {
  border-radius: var(--radius-small) var(--radius-small) 0 0;
}
.box-grid-layout .rwt-card .inner .content {
  padding: 25px;
}

/*-----------------------------
    Filter Button Style  
-------------------------------*/
.filter-button-default {
  display: flex;
  justify-content: center;
  margin: 0 -5px;
  flex-wrap: wrap;
}
.filter-button-default button {
  border: 0 none;
  padding: 10px 20px;
  text-transform: capitalize;
  background: transparent;
  color: var(--color-body);
  margin: 0 5px;
}
@media only screen and (max-width: 767px) {
  .filter-button-default button {
    padding: 10px 10px;
  }
}
.filter-button-default button.is-checked, .filter-button-default button:hover {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
}

/*--------------------------------
    Portfolio Read More Icon  
---------------------------------*/
.rwt-load-more a.btn-default.btn-icon .icon,
.rwt-load-more button.btn-default.btn-icon .icon {
  top: 0px;
  margin-left: -2px;
}
.rwt-load-more a.btn-default.btn-icon .icon svg,
.rwt-load-more button.btn-default.btn-icon .icon svg {
  width: 14px;
  height: 20px;
}

/*----------------------------------
    Portfolio Grid Layout  
----------------------------------*/
.grid-metro2 {
  margin: 0 -15px;
}
.grid-metro2 .portfolio-2 {
  width: 50%;
  padding: 0 15px;
  margin-top: 30px;
}
@media only screen and (max-width: 575px) {
  .grid-metro2 .portfolio-2 {
    width: 100%;
  }
}
.grid-metro2 .resizer {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .grid-metro2 .resizer {
    width: 100%;
  }
}

.grid-metro3 {
  margin: 0 -15px;
}
.grid-metro3 .portfolio-3 {
  width: 33.33%;
  padding: 0 15px;
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-metro3 .portfolio-3 {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .grid-metro3 .portfolio-3 {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .grid-metro3 .portfolio-3 {
    width: 100%;
  }
}
.grid-metro3 .resizer {
  width: 33.33%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-metro3 .resizer {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .grid-metro3 .resizer {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .grid-metro3 .resizer {
    width: 100%;
  }
}

/*-------------------------
    Testimonial Styles  
--------------------------*/
.card-style-default.testimonial-style-one .inner .thumbnail {
  overflow: visible;
}
.card-style-default.testimonial-style-one .inner .thumbnail::after {
  position: absolute;
  content: "”";
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--color-blackest);
  right: 0;
  top: 0;
  color: #fff;
  z-index: 2;
  text-align: center;
  line-height: 58px;
  font-size: 39px;
}
.card-style-default.testimonial-style-one .inner .content .description {
  margin-bottom: 14px;
}
.card-style-default.testimonial-style-one .inner .content .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: 0.5px;
}
.card-style-default.testimonial-style-one .inner .content .subtitle {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}
.card-style-default.testimonial-style-one.style-two .inner .thumbnail {
  position: relative;
}
.card-style-default.testimonial-style-one.style-two .inner .description {
  margin-bottom: 19px;
  margin-top: 20px;
}

/*-----------------------------
    Testimonial Style Two  
-------------------------------*/
.testimonial-style-two .content {
  position: relative;
  z-index: 2;
}
.testimonial-style-two .content::after {
  position: absolute;
  content: "“";
  left: -20px;
  color: var(--color-lessdark);
  text-align: center;
  font-size: 320px;
  opacity: 0.7;
  line-height: 50px;
  top: 52px;
  z-index: -1;
}
.testimonial-style-two .content .form {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.testimonial-style-two .content .description {
  line-height: 1.5;
  margin-bottom: 30px;
  font-size: 32px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-style-two .content .description {
    margin-bottom: 25px;
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-style-two .content .description {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-style-two .content .description {
    font-size: 24px;
  }
}
.testimonial-style-two .content .client-info .title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
}
.testimonial-style-two .content .client-info .subtitle {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--color-body);
  font-style: italic;
}
.testimonial-style-two .thumbnail {
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
  border-radius: 100%;
  padding: 10px;
}
.testimonial-style-two .thumbnail img {
  border-radius: 100%;
}

.slick-initialized .slick-slide {
  margin-bottom: 0;
}

.slick-grid-15 {
  margin: 0 -15px;
}
.slick-grid-15 .slide-single-layout {
  padding: 0 15px;
}

/*----------------------
    Slick Dots  
------------------------*/
.rn-slick-dot .slick-dots {
  bottom: -60px;
}
@media only screen and (max-width: 575px) {
  .rn-slick-dot .slick-dots {
    bottom: -50px;
  }
}
.rn-slick-dot .slick-dots li {
  width: 30px;
  margin: 0;
  opacity: 1;
  position: relative;
}
.rn-slick-dot .slick-dots li button::before {
  display: none;
}
.rn-slick-dot .slick-dots li button::after {
  background: var(--color-lessdark);
  width: 100%;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 11px;
  background-color: transparent;
  box-shadow: inset 0 0 0 5px var(--color-lessdark);
}
.rn-slick-dot .slick-dots li.slick-active {
  opacity: 1;
}
.rn-slick-dot .slick-dots li.slick-active button::after {
  transform: scale(2);
  box-shadow: inset 0 0 0 1px var(--color-primary);
  background-color: transparent;
  background-color: transparent !important;
}
.rn-slick-dot.dot-light .slick-dots li button::after {
  background: #ffffff;
  box-shadow: inset 0 0 0 5px #ffffff;
}
.rn-slick-dot.dot-light .slick-dots li.slick-active {
  opacity: 1;
}
.rn-slick-dot.dot-light .slick-dots li.slick-active button::after {
  transform: scale(2);
  box-shadow: inset 0 0 0 1px var(--color-primary);
  background-color: transparent;
  background-color: transparent !important;
}

/*----------------------
    Slick Arrow  
------------------------*/
.rn-slick-arrow {
  position: relative;
}
.rn-slick-arrow button.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
  width: 68px;
  height: 68px;
  border: 2px solid var(--color-border);
  border-radius: 100%;
  color: var(--color-border);
  background-color: transparent;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-slick-arrow button.slick-arrow {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-slick-arrow button.slick-arrow {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .rn-slick-arrow button.slick-arrow {
    display: none !important;
  }
}
.rn-slick-arrow button.slick-arrow:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.rn-slick-arrow button.slick-arrow::before {
  line-height: 1;
  opacity: 0.4;
  position: relative;
  top: -2px;
  transition: 0.5s;
  z-index: 2;
}
.rn-slick-arrow button.slick-arrow.prev-arrow {
  left: 15px;
}
@media only screen and (max-width: 767px) {
  .rn-slick-arrow button.slick-arrow.prev-arrow {
    left: 10px;
  }
}
.rn-slick-arrow button.slick-arrow.prev-arrow::before {
  content: url(/build/images/slick-arrow-left-small.26c58485.png);
  vertical-align: middle;
}
.rn-slick-arrow button.slick-arrow.prev-arrow:hover {
  left: 10px;
}
.rn-slick-arrow button.slick-arrow.prev-arrow:hover::before {
  opacity: 1;
}
.rn-slick-arrow button.slick-arrow.next-arrow {
  left: auto;
  right: 15px;
}
@media only screen and (max-width: 767px) {
  .rn-slick-arrow button.slick-arrow.next-arrow {
    right: 10px;
  }
}
.rn-slick-arrow button.slick-arrow.next-arrow::before {
  content: url(/build/images/slick-arrow-right-small.df121e6a.png);
  vertical-align: middle;
}
.rn-slick-arrow button.slick-arrow.next-arrow:hover {
  right: 10px;
}
.rn-slick-arrow button.slick-arrow.next-arrow:hover::before {
  opacity: 1;
}
.rn-slick-arrow button.slick-arrow i {
  display: none;
}
.rn-slick-arrow:hover button.slick-arrow {
  opacity: 1;
}

/* -----------------------
Timeline Area  
--------------------------*/
.timeline-line {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: hsla(0, 0%, 76.9%, 0.22);
}
.timeline-line::before, .timeline-line::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
}
.timeline-line::before {
  height: 100%;
  bottom: 0;
  background: linear-gradient(180deg, var(var(--color-blackest)), rgba(19, 19, 19, 0));
}
.timeline-line::after {
  height: 200px;
  top: 0;
  background: linear-gradient(180deg, var(var(--color-blackest)), rgba(19, 19, 19, 0));
}

.timeline-dot {
  position: absolute;
  top: 50%;
  left: 0;
}
.timeline-dot::before, .timeline-dot::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.timeline-dot::before {
  z-index: 1;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  background: linear-gradient(180deg, var(--color-primary-light), var(--color-primary));
  box-shadow: 0 0 15px rgba(82, 56, 255, 0.5);
}
.timeline-dot::after {
  border: 5px solid var(var(--color-blackest));
  background: radial-gradient(50% 50% at 50% 50%, var(--color-lessdark) 48.96%, var(--color-lessdark) 100%);
  z-index: 0;
  width: 31px;
  height: 31px;
}
.timeline-dot .time-line-circle {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(242, 201, 76, 0) 40.62%, rgba(82, 56, 255, 0.5) 100%);
  transform: translate(-50%, -50%);
  will-change: transform;
  animation: radialanimation 2.2s cubic-bezier(0.26, 1.04, 0.54, 1) infinite;
}

/*------------------------------
    Single Timeline Styles  
-------------------------------*/
.single-timeline {
  position: relative;
  padding-left: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-timeline {
    padding-left: 54px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-timeline {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .single-timeline {
    padding-left: 30px;
  }
}
.single-timeline .single-content {
  background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
  -webkit-backdrop-filter: blur(4px) opacity(0.95);
  backdrop-filter: blur(4px) opacity(0.95);
  border-radius: var(--radius-big);
}
.single-timeline .single-content .inner {
  padding: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-timeline .single-content .inner {
    padding: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .single-timeline .single-content .inner {
    padding: 20px;
  }
}
.single-timeline .single-content .inner .content .date-of-timeline {
  color: var(--color-body);
  display: block;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  font-style: italic;
  font-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-timeline .single-content .inner .content .date-of-timeline {
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .single-timeline .single-content .inner .content .date-of-timeline {
    margin-bottom: 14px;
  }
}
.single-timeline .single-content .inner .content .title {
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-timeline .single-content .inner .content .title {
    font-size: 32px;
  }
}
.single-timeline .single-content .inner .content .description {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-timeline .single-content .inner .content .description {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .single-timeline .single-content .inner .content .description {
    margin-top: 15px;
  }
}
.single-timeline .single-content .thumbnail img {
  border-radius: var(--radius);
}
@keyframes radialanimation {
  0% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(0.1);
  }
  to {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
/*----------------------------
    Timeline Style Two  
----------------------------*/
.timeline-style-two {
  position: relative;
  padding: 20px 50px 50px 50px;
  border-radius: var(--radius-big);
}

.rn-timeline-single {
  margin-top: 30px;
}
.rn-timeline-single .rn-timeline {
  text-align: center;
}
.rn-timeline-single .rn-timeline .progress-line {
  position: relative;
  border-radius: 0px;
  height: 6px;
  box-shadow: none;
  margin: 20px 0;
  width: 100%;
}
.rn-timeline-single .rn-timeline .progress-line .line-inner {
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  background-image: radial-gradient(circle farthest-corner at 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary-light) 65%, var(--color-light-green) 85%);
  background-attachment: fixed;
  background-repeat: repeat-y;
  width: 100%;
  display: flex;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .rn-timeline-single .rn-timeline .progress-line .line-inner {
    display: none;
  }
}
.rn-timeline-single .rn-timeline .progress-dot {
  position: relative;
  height: 30px;
  display: block;
  width: 100%;
  margin-top: -39px;
  margin-left: -15px;
}
.rn-timeline-single .rn-timeline .progress-dot .dot-level {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-primary-light));
  background-attachment: fixed;
  background-repeat: repeat-y;
  position: absolute;
  width: 28px;
  height: 28px;
  display: block;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.rn-timeline-single .rn-timeline .progress-dot .dot-level .dot-inner {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: var(--color-darkest);
}
.rn-timeline-single:first-child .rn-timeline .progress-line {
  width: 50%;
  left: 50%;
}
.rn-timeline-single:last-child .rn-timeline .progress-line {
  width: 50%;
}
.rn-timeline-single .title {
  margin-bottom: 33px;
  font-size: 20px;
}
.rn-timeline-single .description {
  padding: 0 10%;
  margin-top: 15px;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-timeline-single .description {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-timeline-single .description {
    padding: 0 10px;
  }
}
.rn-timeline-single.no-gradient .rn-timeline .progress-line .line-inner {
  background-image: inherit;
  background: var(--color-primary);
}
.rn-timeline-single.no-gradient .rn-timeline .progress-dot .dot-level {
  background-image: inherit;
  background: var(--color-primary);
}
.rn-timeline-single.dark-line .rn-timeline .progress-line .line-inner {
  background-image: inherit;
  background: var(--color-lessdark);
}
.rn-timeline-single.dark-line .rn-timeline .progress-dot .dot-level {
  background-image: inherit;
  background: var(--color-lessdark);
}
.rn-timeline-single.dark-line .rn-timeline .progress-dot .dot-level .dot-inner {
  background-color: var(--color-primary);
}

/*--------------------------------
    Call To Action Style  
--------------------------------*/
.clltoaction-style-default {
  padding: 250px 0;
}
.clltoaction-style-default .title {
  font-size: 52px;
  font-weight: 600;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 0;
  line-height: 1.4;
  color: var(--color-white);
}
.clltoaction-style-default .subtitle {
  color: #fff;
  font-size: 24px;
  line-height: 1.4;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.clltoaction-style-default .content p {
  color: var(--color-body);
}
.clltoaction-style-default .call-to-btn {
  margin-top: 50px;
}
.clltoaction-style-default .content.text-left .title::before {
  left: 0;
  transform: none;
}
.clltoaction-style-default .content.text-left .subtitle {
  padding: 0 16% 0 0;
}
.clltoaction-style-default.style-2 {
  padding: 130px 0;
}
.clltoaction-style-default.style-3 {
  padding: 150px 0;
}
.clltoaction-style-default.style-3 .content-wrapper {
  background-color: var(--color-lessdark);
  border-radius: var(--radius);
  padding: 50px 40px;
}
.clltoaction-style-default.style-3 .content .title {
  font-size: 32px;
  margin-bottom: 18px;
  padding-bottom: 10px;
  color: var(--color-white);
}
.clltoaction-style-default.style-3 .content .subtitle {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.5px;
}
.clltoaction-style-default.style-3 .call-to-btn {
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-3 .call-to-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-3 .call-to-btn {
    margin-top: 20px;
  }
}
.clltoaction-style-default.style-4 {
  padding: 0;
}
.clltoaction-style-default.style-4 .content-wrapper {
  background-color: var(--color-lessdark);
  border-radius: var(--radius);
  padding: 70px 50px;
}
.clltoaction-style-default.style-4 .content .title {
  font-size: 47px;
  margin-bottom: 33px;
  padding-bottom: 16px;
  line-height: 1.4;
}
.clltoaction-style-default.style-4 .content .subtitle {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.5px;
}
.clltoaction-style-default.style-4 .call-to-btn {
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-4 .call-to-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-4 .call-to-btn {
    margin-top: 20px;
  }
}
.clltoaction-style-default.style-5 {
  padding: 0;
}
.clltoaction-style-default.style-5 .content-wrapper {
  background-color: var(--color-blackest);
  border-radius: var(--radius);
  padding: 70px 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-5 .content-wrapper {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-5 .content-wrapper {
    padding: 40px 20px;
  }
}
.clltoaction-style-default.style-5 .content .title {
  font-size: 47px;
  margin-bottom: 20px;
  padding-bottom: 0;
  line-height: 1.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clltoaction-style-default.style-5 .content .title {
    font-size: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-5 .content .title {
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-5 .content .title {
    font-size: 34px;
  }
}
@media only screen and (max-width: 575px) {
  .clltoaction-style-default.style-5 .content .title {
    font-size: 26px;
  }
}
.clltoaction-style-default.style-5 .content .title::before {
  display: none;
}
.clltoaction-style-default.style-5 .content .subtitle {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.5px;
}
.clltoaction-style-default.style-5 .call-to-btn {
  margin-top: 30px;
}
.clltoaction-style-default.style-6 {
  padding: 80px 0;
}
.clltoaction-style-default.style-6 .content .title {
  font-size: 36px;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 1.4;
}
.clltoaction-style-default.style-6 .content .title::before {
  display: none;
}
.clltoaction-style-default.style-6 .call-to-btn {
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-6 .call-to-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-6 .call-to-btn {
    margin-top: 20px;
  }
}
.clltoaction-style-default.style-7 {
  padding: 0;
}
.clltoaction-style-default.style-7 .content-wrapper {
  padding: 40px 0;
  border-bottom: 1px solid var(--color-border);
}
.clltoaction-style-default.style-7 .content .subtitle {
  font-size: 16px;
  color: var(--color-body);
  max-width: 450px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 13px;
  padding: 0;
}
.clltoaction-style-default.style-7 .call-to-btn {
  margin-top: 0;
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clltoaction-style-default.style-7 .call-to-btn {
    margin-top: 20px;
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .clltoaction-style-default.style-7 .call-to-btn {
    margin-top: 20px;
    text-align: left;
  }
}

.rn-call-to-action.style-8 {
  padding: 100px 0;
  background: var(--color-blackest);
}
.rn-call-to-action.style-8 .content-wrapper {
  padding: 40px 0;
  border-bottom: 1px solid var(--color-border);
}
.rn-call-to-action.style-8 .content .subtitle {
  font-size: 20px;
  color: var(--color-body);
  line-height: 1.5;
  margin-bottom: 0;
}
.rn-call-to-action.style-8 .call-to-btn {
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-call-to-action.style-8 .call-to-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-call-to-action.style-8 .call-to-btn {
    margin-top: 20px;
  }
}

.theme-shape {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.theme-shape::before {
  position: absolute;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
  opacity: 0.2;
  filter: blur(100px);
  content: "";
}
.theme-shape::after {
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  opacity: 0.2;
  filter: blur(100px);
  content: "";
  left: auto;
  top: -250px;
  right: -250px;
  bottom: auto;
  background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
}
.bg-image.bg-image2 {
  background-image: url(/build/images/bg-image-1.e12a7844.jpg);
}
.bg-image.bg-image3 {
  background-image: url(/build/images/bg-image-2.7cb92a1c.jpg);
}

.data-black-overlay {
  position: relative;
}
.data-black-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: #000000;
  opacity: 0.7;
}

/*---------------------
    Tab Styles  
-----------------------*/
.rn-default-tab .tab-button {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  border-bottom: none;
}
.rn-default-tab .tab-button .tabs__tab {
  margin: 0;
}
.rn-default-tab .tab-button .tabs__tab .nav-link {
  color: var(--color-white);
  padding: 24px 44px;
  margin-bottom: -2px;
  outline: none;
  border: 0 none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 24px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-default-tab .tab-button .tabs__tab .nav-link {
    padding: 24px 20px;
  }
}
.rn-default-tab .tab-button .tabs__tab .nav-link.active {
  background: var(--color-lessdark);
  border-color: transparent;
}
.rn-default-tab .tab-button .rn-tab-button button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  color: var(--color-white);
}
.rn-default-tab .rn-tab-content {
  background: var(--color-lessdark);
  padding: 40px;
}
@media only screen and (max-width: 767px) {
  .rn-default-tab .rn-tab-content {
    padding: 30px;
  }
}
.rn-default-tab .rn-tab-content p:last-child {
  margin-bottom: 0;
}
.rn-default-tab.style-two .tab-button {
  margin-bottom: 30px;
}
.rn-default-tab.style-two .tab-button .tabs__tab button {
  border-radius: var(--radius-small);
}
.rn-default-tab.style-two .rn-tab-content {
  border-radius: var(--radius-small);
}

.rn-pricing {
  margin-top: 40px;
}
.rn-pricing .pricing-table-inner {
  padding: 40px;
  position: relative;
  z-index: 2;
  border: 2px solid var(--color-border);
  border-radius: 5px;
  transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
  transform-style: preserve-3d;
}
.rn-pricing .pricing-table-inner::before {
  z-index: -1;
  display: inline-block;
  content: "";
  transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
  opacity: 0;
  border-radius: 5px;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background-color: var(--color-lessdark);
}
.rn-pricing .pricing-table-inner .pricing-header {
  margin-bottom: 30px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: 2px solid var(--color-border);
}
.rn-pricing .pricing-table-inner .pricing-header .title {
  margin-bottom: 20px;
}
.rn-pricing .pricing-table-inner .pricing-header .price-wrapper {
  display: flex;
  justify-content: center;
}
.rn-pricing .pricing-table-inner .pricing-header .pricing span {
  display: block;
}
.rn-pricing .pricing-table-inner .pricing-header .pricing span.currency {
  font-size: 28px;
  position: relative;
  top: -3px;
  left: -2px;
  opacity: 0.3;
}
.rn-pricing .pricing-table-inner .pricing-header .pricing span.price {
  font-size: 77px;
  color: var(--color-white);
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .rn-pricing .pricing-table-inner .pricing-header .pricing span.price {
    font-size: 54px;
  }
}
.rn-pricing .pricing-table-inner .pricing-header .pricing span.subtitle {
  font-size: 14px;
  color: var(--color-body);
}
.rn-pricing .pricing-table-inner .pricing-body {
  text-align: center;
  margin-bottom: 30px;
}
.rn-pricing .pricing-table-inner .pricing-body .list-style--1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.rn-pricing .pricing-table-inner .pricing-footer {
  text-align: center;
}
.rn-pricing.style-2 .pricing-table-inner {
  background-color: var(--color-blackest);
  border-color: transparent !important;
}
.rn-pricing.style-4 {
  margin-left: -1px;
  margin-right: -1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing.style-4 {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-pricing.style-4 {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.rn-pricing.active .pricing-table-inner {
  border-color: transparent;
}
.rn-pricing.active .pricing-table-inner::before {
  transform: scaleY(1.12);
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing.active .pricing-table-inner::before {
    transform: scaleY(1);
  }
}
@media only screen and (max-width: 767px) {
  .rn-pricing.active .pricing-table-inner::before {
    transform: scaleY(1);
  }
}
.rn-pricing.active .pricing-table-inner .pricing-header {
  border-bottom: 2px solid var(--color-border);
}
.rn-pricing.active .pricing-table-inner .pricing-header .title {
  color: var(--color-white);
}
.rn-pricing.active .pricing-table-inner .pricing-header .pricing span {
  display: block;
}
.rn-pricing.active .pricing-table-inner .pricing-header .pricing span.currency {
  color: var(--color-white);
  opacity: 1;
}
.rn-pricing.active .pricing-table-inner .pricing-header .pricing span.price {
  color: var(--color-white);
}
.rn-pricing.active .pricing-table-inner .pricing-header .pricing span.subtitle {
  color: var(--color-white);
}
.rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li {
  color: var(--color-white);
}
.rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li svg {
  color: var(--color-white);
}
.rn-pricing.active .pricing-table-inner .pricing-footer {
  text-align: center;
}
.rn-pricing.style-5 .pricing-table-inner {
  background-color: var(--color-blackest);
  border-color: transparent;
  padding: 0;
  padding-bottom: 40px;
  border: 0 none;
}
.rn-pricing.style-5 .pricing-table-inner::before {
  display: none;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header {
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom: 0 none !important;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header .title {
  padding: 30px 40px;
  margin-bottom: 0;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header .price-wrapper {
  margin-bottom: 5px;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.currency {
  font-size: 40px;
  position: relative;
  top: 0px;
  left: 0;
  opacity: 1;
  color: var(--color-white);
  font-weight: 700;
  line-height: 7px;
  display: flex;
  align-items: center;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.price {
  font-size: 50px;
  color: var(--color-white);
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
}
.rn-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.subtitle {
  color: var(--color-white);
}
.rn-pricing.style-5 .pricing-table-inner .pricing {
  background-color: var(--color-lessdark);
  padding: 30px;
}

/*--------------------------
    split Area Styles  
----------------------------*/
.rn-splite-style .thumbnail img {
  border-radius: 0;
  width: 100%;
}
.rn-splite-style .thumbnail.image-left-content img {
  border-radius: 0 10px 10px 0;
}
.rn-splite-style .split-inner {
  padding: 30px 115px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-splite-style .split-inner {
    padding: 40px 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-splite-style .split-inner {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-splite-style .split-inner {
    padding: 40px 30px;
  }
}
.rn-splite-style .split-inner .title {
  font-size: 36px;
  line-height: 50px;
  color: var(--color-heading);
  font-weight: var(--p-bold);
}
@media only screen and (max-width: 767px) {
  .rn-splite-style .split-inner .title {
    font-size: 24px;
    line-height: 30px;
  }
}
.rn-splite-style .split-inner .split-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.rn-splite-style .split-inner .split-list li {
  color: var(--color-dark-alt);
}

/*----------------------------
    Video Popup Styles  
------------------------------*/
.video-popup,
.rn-gallery {
  position: relative;
  display: block;
}
.video-popup .thumbnail img,
.rn-gallery .thumbnail img {
  width: 100%;
}
.video-popup .video-icon,
.rn-gallery .video-icon {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 10px;
  transition: 0.4s;
}

.rn-gallery .video-icon {
  opacity: 0;
  transition: 0.4s;
  transform: translate(-50%, -50%) scale(0.5);
}
.rn-gallery:hover .video-icon {
  opacity: 1;
  margin-top: 0;
  transform: translate(-50%, -50%) scale(1);
}

.video-gallery-content {
  border-radius: 10px;
  padding-top: 300px;
  padding-bottom: 60px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-gallery-content {
    padding-bottom: 40px;
    padding-top: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .video-gallery-content {
    padding-bottom: 40px;
    padding-top: 150px;
  }
}
.video-gallery-content .overlay-content {
  padding: 0 60px;
}
@media only screen and (max-width: 767px) {
  .video-gallery-content .overlay-content {
    padding: 0 20px;
  }
}
.video-gallery-content .overlay-content .title {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 30px;
  letter-spacing: 0.5px;
  line-height: 1.4;
  color: var(--color-white);
}
@media only screen and (max-width: 767px) {
  .video-gallery-content .overlay-content .title {
    font-size: 20px;
  }
}

/*==========================
    Contact Address 
============================*/
.contact-form-1 .form-group input {
  height: 70px;
  padding: 0 20px;
}
.contact-form-1 .form-group textarea {
  padding-top: 20px;
  padding-left: 20px;
}

.contact-address-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*-------------------
    Address Area  
--------------------*/
.rn-address {
  margin-top: 30px;
  padding: 40px;
  border-radius: 10px;
  padding-top: 40px;
  background: var(--color-blackest);
  padding-bottom: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-address {
    padding: 30px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-address {
    padding: 30px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-address {
    padding: 30px 20px;
  }
}
.rn-address .icon {
  color: var(--color-primary);
  border-radius: 100%;
  font-size: 40px;
  display: inline-block;
  margin-bottom: 17px;
  line-height: 6px;
}
.rn-address .inner h4.title, .rn-address .inner .title.h4 {
  font-size: 18px;
  font-weight: 700;
}
.rn-address .inner p {
  font-size: 18px;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-address .inner p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-address .inner p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-address .inner p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-address .inner p br {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-address .inner p br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .rn-address .inner p br {
    display: none;
  }
}
.rn-address .inner p a {
  color: var(--color-body);
}
.rn-address .inner p a:hover {
  color: var(--color-primary);
}
.rn-address:hover {
  transform: translateY(-5px);
}

.google-map-style-1 {
  width: 100%;
  height: 500px;
}

.brand-carousel-activation {
  padding: 0 50px;
}
@media only screen and (max-width: 767px) {
  .brand-carousel-activation {
    padding: 0 10px;
  }
}
.brand-carousel-activation li {
  margin-top: 0;
  margin-bottom: 0;
}
.brand-carousel-activation li a {
  display: block;
}
.brand-carousel-activation li a img {
  opacity: 0.8;
  transition: 0.4s;
}
.brand-carousel-activation li:hover a img {
  opacity: 1;
  filter: contrast(0.5);
}
.brand-carousel-activation.rn-slick-arrow button.slick-arrow {
  width: 50px;
  height: 50px;
}
.brand-carousel-activation.rn-slick-arrow button.slick-arrow i {
  display: block;
  font-size: 24px;
  color: #fff;
}
.brand-carousel-activation.rn-slick-arrow button.slick-arrow::before {
  display: none;
}

.brand-style-1 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.brand-style-1 li {
  flex-basis: 25%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  margin: 0px 0px -2px -2px;
  border-color: var(--color-border);
  padding: 40px 0 40px 0;
  text-align: center;
}
@media only screen and (max-width: 575px) {
  .brand-style-1 li {
    flex-basis: 33.33%;
  }
}
@media only screen and (max-width: 479px) {
  .brand-style-1 li {
    flex-basis: 50%;
  }
}
.brand-style-1 li a {
  display: block;
}
.brand-style-1 li a img {
  opacity: 0.8;
  transition: 0.4s;
}
.brand-style-1 li:hover a img {
  opacity: 1;
  filter: contrast(0.5);
}

.brand-style-2 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.brand-style-2 li {
  flex-basis: 16.666%;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-style-2 li {
    flex-basis: 25%;
  }
}
@media only screen and (max-width: 767px) {
  .brand-style-2 li {
    flex-basis: 25%;
  }
}
@media only screen and (max-width: 575px) {
  .brand-style-2 li {
    flex-basis: 33.33%;
  }
}
@media only screen and (max-width: 479px) {
  .brand-style-2 li {
    flex-basis: 50%;
  }
}
.brand-style-2 li a {
  display: block;
}
.brand-style-2 li a img {
  opacity: 0.8;
  transition: 0.3s;
}
.brand-style-2 li:hover a img {
  opacity: 1;
}
.brand-style-2.variation-2 li {
  flex-basis: 25%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .brand-style-2.variation-2 li {
    flex-basis: 33.33%;
  }
}
@media only screen and (max-width: 479px) {
  .brand-style-2.variation-2 li {
    flex-basis: 50%;
  }
}

/*---------------------------
    Error Styles Area  
-----------------------------*/
.error-inner {
  text-align: center;
}
.error-inner h1, .error-inner .h1 {
  font-size: 200px;
  line-height: 1;
  color: var(--color-primary);
  font-weight: var(--p-bold);
}
@media only screen and (max-width: 767px) {
  .error-inner h1, .error-inner .h1 {
    font-size: 100px;
    line-height: 1;
  }
}
.error-inner .title {
  line-height: 45px;
  font-weight: var(--p-bold);
}
.error-inner .description {
  font-size: var(--font-size-b2);
  line-height: 28px;
  color: var(--color-body);
  font-weight: var(--p-regular);
  margin-bottom: 30px;
}
.error-inner .blog-search {
  display: inline-block;
}
.error-inner .blog-search input {
  min-width: 500px;
}
.error-inner .view-more-button {
  margin-top: 30px;
}

.advance-tab-button-1 .tab-button-list {
  list-style: none;
  padding: 0;
  margin: -10px 0;
}
.advance-tab-button-1 .tab-button-list li {
  margin: 10px 0;
}
.advance-tab-button-1 .tab-button-list .tab-button {
  cursor: pointer;
  padding: 47px 27px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0 none;
  margin: 0;
}
.advance-tab-button-1 .tab-button-list .tab-button::before {
  position: absolute;
  content: "";
  background: var(--color-blackest);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  transform: scale(0.9);
  z-index: -1;
  border-radius: 7px;
}
.advance-tab-button-1 .tab-button-list .tab-button:hover::before {
  opacity: 1;
  transform: scale(1);
}
.advance-tab-button-1 .tab-button-list .tab-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  border-left: 20px solid var(--color-blackest);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  margin-top: -20px;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.9);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advance-tab-button-1 .tab-button-list .tab-button::after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .advance-tab-button-1 .tab-button-list .tab-button::after {
    display: none;
  }
}
.advance-tab-button-1 .tab-button-list .tab-button .title {
  color: var(--color-body);
  font-size: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
}
.advance-tab-button-1 .tab-button-list .tab-button .description {
  margin-bottom: 0;
  transition: 0.3s;
}
.advance-tab-button-1 .tab-button-list .tab-button.active .title {
  color: var(--color-white);
}
.advance-tab-button-1 .tab-button-list .tab-button.active::before {
  opacity: 1;
  transform: scale(1);
}
.advance-tab-button-1 .tab-button-list .tab-button.active::after {
  opacity: 1;
  transform: scale(1);
}

.nav-tabs {
  border-bottom: 0;
}

.advance-tab-content-1 .thumbnail img {
  border-radius: 10px;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(0.9) rotate(-180deg);
  left: auto;
  right: 100%;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(1) rotate(-180deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  left: 50%;
  transform: scale(0.9) translateX(-50%) rotate(-92deg);
  bottom: 94%;
  top: auto;
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  opacity: 0;
  transform: scale(1) translateX(-50%) rotate(-92deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button.active::after {
  opacity: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  color: #495057;
  background: transparent;
  border: 0 none;
  box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.advance-tab-button-1 .tab-button-list .tab-button:focus {
  outline: none;
  border: 0 none;
  box-shadow: none;
}

/*----------------------------
    Advance Pricing Table  
------------------------------*/
.advance-pricing {
  background: var(--color-blackest);
  border-radius: 10px;
}
.advance-pricing .pricing-left {
  height: 100%;
  background: var(--color-lessdark);
  padding: 60px 50px;
  border-radius: 10px 0 0 10px;
}
@media only screen and (max-width: 767px) {
  .advance-pricing .pricing-left {
    padding: 30px 20px;
  }
}
.advance-pricing .pricing-left .main-title {
  margin-bottom: 10px;
}
.advance-pricing .pricing-left .price-wrapper .price-amount {
  font-size: 60px;
  text-align: center;
  display: block;
  color: var(--color-white);
  font-weight: 700;
}
.advance-pricing .pricing-left .price-wrapper .price-amount sup {
  font-size: 16px;
  top: -34px;
}
.advance-pricing .pricing-left .btn-default {
  width: 100%;
  margin: 10px 0;
}
.advance-pricing .pricing-left .rating {
  text-align: center;
  margin-top: 5px;
}
.advance-pricing .pricing-left .rating a {
  font-weight: 700;
  color: #f1b304;
}
.advance-pricing .pricing-left .rating a svg {
  fill: #f1b304;
}
.advance-pricing .pricing-left .subtitle {
  display: block;
  color: var(--color-body);
  text-align: center;
}
.advance-pricing .pricing-right {
  padding: 60px 30px;
}
.advance-pricing .pricing-right .price-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.advance-pricing .pricing-right .price-title li {
  font-size: 16px;
  margin: 8px 0;
}

.rn-comment-form .section-title span.subtitle {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
}
.rn-comment-form .section-title .title {
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 20px;
  color: var(--color-white);
}

iframe {
  border-radius: 10px;
}

.rnform-group {
  margin-bottom: 20px;
}
.rnform-group input {
  border: 2px solid var(--color-border);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
}

.rn-comment-form .inner .rnform-group textarea {
  min-height: 193px;
  border: 2px solid var(--color-border);
  border-radius: 4px;
  resize: none;
  padding: 15px;
  font-size: 16px;
}

/*--------------------------
    Swicher Styles  
----------------------------*/
.active-dark-mode .my_switcher ul li .setColor.dark {
  display: none;
}

.active-light-mode .my_switcher ul li .setColor.light {
  display: none;
}

.active-light-mode .my_switcher ul li a.dark img {
  display: block;
}

.my_switcher ul li a.dark img {
  display: none;
  filter: brightness(100);
}

.my_switcher {
  border: 0 none;
  padding: 0;
  margin-left: 10px;
  width: 40px;
  background: var(--color-secondary);
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 100%;
}
@media only screen and (max-width: 767px) {
  .my_switcher {
    margin-left: 5px;
  }
}
.my_switcher ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
}
.my_switcher ul li {
  margin-top: 0;
  margin-bottom: 0;
}
.my_switcher ul li a {
  display: block;
  position: relative;
  z-index: 2;
  background: var(--color-secondary);
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.my_switcher ul li a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.my_switcher ul li a.active {
  display: none;
}

/*------------------------
    Back to Top  
--------------------------*/
.rn-back-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border-radius: 50%;
  text-align: center;
  z-index: 999 !important;
  background-color: #0f0f11;
  background-color: var(--color-blackest);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.rn-back-top i {
  color: #fff;
  transition: 0.3s;
  font-size: 20px;
}
.rn-back-top:hover i {
  color: var(--color-primary) !important;
}

/*--------------------------
    About Styles  
---------------------------*/
.about-style-4 .title {
  font-weight: 400;
  margin-bottom: 35px;
}
.about-style-4 .title strong {
  color: var(--color-primary);
}
.about-style-4 .feature-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.about-style-4 .feature-list li {
  display: flex;
}
.about-style-4 .feature-list li .icon {
  background: var(--color-primary);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: var(--color-white);
  text-align: center;
  min-width: 30px;
}
.about-style-4 .feature-list li .icon i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.about-style-4 .feature-list li .title-wrapper {
  margin-left: 13px;
}
.about-style-4 .feature-list li .title-wrapper .title {
  font-size: 18px;
  margin: 0;
}
.about-style-4 .feature-list li .title-wrapper .text {
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 80%;
  margin-top: 13px;
}

.about-style-5 {
  position: relative;
  z-index: 2;
}
.about-style-5 .about-wrapper {
  margin-top: -150px;
  background: var(--color-blackest);
  border-radius: 10px;
  padding: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-5 .about-wrapper {
    margin-top: 80px;
    padding: 15px 15px 30px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .about-style-5 .about-wrapper {
    margin-top: 80px;
    padding: 15px 15px 30px 15px;
  }
}
.about-style-5 .about-wrapper .thumbnail img {
  border-radius: 10px;
}
.about-style-5 .about-wrapper .content .inner {
  padding: 0 40px;
}
@media only screen and (max-width: 767px) {
  .about-style-5 .about-wrapper .content .inner {
    padding: 0;
  }
}
.about-style-5 .about-wrapper .content .inner .title {
  font-size: 30px;
  margin-bottom: 13px;
}
.about-style-5 .about-wrapper .content .inner p {
  margin-bottom: 20px;
  width: 80%;
}

.contact-address {
  padding: 0;
  margin: 0;
  list-style: none;
}
.contact-address li {
  font-size: 16px;
}
.contact-address li i {
  color: var(--color-primary);
  margin-right: 10px;
}

.about-style-6 .rbt-progress-style-1,
.about-style-6 .wrapper {
  position: relative;
  z-index: 2;
  max-width: 850px;
  margin: 0 auto;
  border-radius: 10px;
}
.about-style-6 .wrapper {
  padding: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-6 .wrapper {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .about-style-6 .wrapper {
    padding: 20px;
  }
}
.about-style-6 .wrapper::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: var(--color-lessdark);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
  border-radius: 10px;
}
.about-style-6 .thumbnail img {
  max-height: 200px;
}
.about-style-6 .subtitle {
  font-size: 18px;
  font-weight: 500;
}
.about-style-6 .maintitle {
  font-size: 30px;
  line-height: 1.3;
  margin-top: 17px;
  margin-bottom: 25px;
}

/*---------------------------
    Newsletter Styles  
----------------------------*/
.border-top-bottom {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.newsletter-area .border-top-bottom {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.rn-newsletter-default .newsletter-wrapper {
  padding: 40px 0;
}
.rn-newsletter-default .newsletter-section-title .title {
  color: var(--color-body);
  margin-bottom: 7px;
}
.rn-newsletter-default .newsletter-section-title .description {
  color: var(--color-body);
  margin-bottom: 0;
}
.rn-newsletter-default .rn-newsletter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -10px;
}
@media only screen and (max-width: 575px) {
  .rn-newsletter-default .rn-newsletter {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-newsletter-default .rn-newsletter {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .rn-newsletter-default .rn-newsletter {
    justify-content: flex-start;
  }
}
.rn-newsletter-default .rn-newsletter .form-group {
  margin-bottom: 0;
  margin: 0 10px;
}
.rn-newsletter-default .rn-newsletter .form-group input {
  height: 55px;
  padding: 0 24px;
  min-width: 500px;
  background: var(--color-blackest);
  border: 0 none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-newsletter-default .rn-newsletter .form-group input {
    min-width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-newsletter-default .rn-newsletter .form-group input {
    min-width: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .rn-newsletter-default .rn-newsletter .form-group input {
    min-width: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .rn-newsletter-default .rn-newsletter .form-group input {
    min-width: 100%;
    margin-bottom: 20px;
  }
}
.rn-newsletter-default .rn-newsletter .form-group .btn-default {
  height: 55px;
}

/**************************************
            Blog 
***************************************/
/*----------------------
    Blog Styles  
-----------------------*/
.rn-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--color-blackest);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--radius);
  overflow: hidden;
  transition: all 0.5s;
}
.rn-card .inner .thumbnail {
  overflow: hidden;
}
.rn-card .inner .thumbnail a {
  display: block;
  overflow: hidden;
}
.rn-card .inner .thumbnail a img {
  width: 100%;
  transition: 0.5s;
  overflow: hidden;
}
.rn-card .inner .content {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  padding-top: 22px;
}
@media only screen and (max-width: 767px) {
  .rn-card .inner .content {
    padding: 30px 20px;
  }
}
.rn-card .inner .content .descriptiion {
  margin-bottom: 0;
  margin-bottom: 13px;
}
.rn-card .inner .content .title {
  font-size: 20px;
  line-height: 1.5;
  transition: all 0.5s;
  margin-bottom: 0;
  margin-top: 15px;
  flex-basis: 100%;
}
.rn-card.card-list-view {
  height: 100%;
}
.rn-card.card-list-view .inner {
  display: flex;
  height: 100%;
}
@media only screen and (max-width: 575px) {
  .rn-card.card-list-view .inner {
    display: block;
  }
}
.rn-card.card-list-view .inner .thumbnail {
  flex-basis: 46%;
}
.rn-card.card-list-view .inner .thumbnail a {
  display: block;
  height: 100%;
}
.rn-card.card-list-view .inner .thumbnail a img {
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.rn-card.card-list-view .inner .content {
  flex-basis: 54%;
}
.rn-card.card-list-view .inner .content .title {
  margin-bottom: 10px;
}
.rn-card.card-list-view .inner .content .descriptiion {
  margin-bottom: 13px;
  margin-top: 12px;
}
.rn-card.content-transparent {
  position: relative;
  border: 0 none;
}
.rn-card.content-transparent .content {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.9) 100%);
  padding-top: 60px;
}
@media only screen and (max-width: 767px) {
  .rn-card.content-transparent .content {
    padding-top: 20px;
  }
}
.rn-card.content-transparent .content .title {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .rn-card.content-transparent .content .title {
    font-size: 17px;
  }
}
.rn-card.content-transparent .content .title a {
  color: var(--color-white);
}
.rn-card.content-transparent .content .title:hover a {
  color: var(--color-primary);
}
.rn-card.content-transparent .content .rn-meta-list li {
  color: #acaeb1;
}
.rn-card.content-transparent .content .rn-meta-list li a {
  color: #acaeb1;
}
.rn-card.content-transparent.post-large .content .title {
  font-size: 32px;
}
@media only screen and (max-width: 767px) {
  .rn-card.content-transparent.post-large .content .title {
    font-size: 19px;
  }
}
.rn-card:hover .inner .thumbnail a img {
  transform: scale(1.1);
}

.rn-meta-list {
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.rn-meta-list li {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--color-gray);
  font-size: 14px;
}
.rn-meta-list li a {
  color: var(--color-gray);
  transition: 0.5s;
}
.rn-meta-list li.separator {
  margin: 0 0.8rem;
}

.blog-search {
  position: relative;
}
.blog-search input {
  height: 60px;
  padding: 0 20px;
  color: var(--color-body);
  padding-right: 80px;
}
.blog-search .search-button {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border: 0 none;
  background: var(--color-primary-light);
  height: 47px;
  padding: 0 20px;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
}
.blog-search .search-button i {
  color: var(--color-white);
  font-weight: 400;
}

.rbt-single-widget {
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid var(--color-border);
  padding-top: 0;
}
.rbt-single-widget:first-child {
  margin-top: 0 !important;
}
.rbt-single-widget input,
.rbt-single-widget select {
  border: var(--border-width) solid var(--color-border);
}
.rbt-single-widget.widget_search {
  padding: 0;
  border: 0 none;
}
.rbt-single-widget.widget_search .title {
  margin: 0;
}
.rbt-single-widget p {
  font-size: 16px;
  line-height: 28px;
  color: #717173;
}
.rbt-single-widget .title {
  line-height: 26px;
  position: relative;
  padding-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-white);
  background-color: var(--color-blackest);
  margin: -1px -22px 0;
  padding: 14px 24px;
  border-radius: 4px;
}
.rbt-single-widget .title a {
  color: var(--color-primary);
}
.rbt-single-widget .title a.rsswidget img {
  height: 13px;
  margin-right: 3px;
}
.rbt-single-widget .title img {
  vertical-align: 0px;
}
.rbt-single-widget ul {
  padding-left: 0;
  margin-bottom: -10px;
  list-style: none;
  padding-right: 0;
}
.rbt-single-widget ul ul {
  margin-bottom: 0;
}
.rbt-single-widget ul li {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
  color: #717173;
}
.rbt-single-widget ul li a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: var(--color-heading);
  display: initial;
}
.rbt-single-widget ul li a:hover {
  color: var(--color-primary);
}
.rbt-single-widget.widget_categories ul li a {
  display: flex;
  justify-content: space-between;
}
.rbt-single-widget.widget_recent_comments ul li, .rbt-single-widget.widget_recent_entries ul li, .rbt-single-widget.widget_archive ul li, .rbt-single-widget.widget_categories ul li, .rbt-single-widget.widget_meta ul li, .rbt-single-widget.widget_pages ul li, .rbt-single-widget.widget_nav_menu ul li {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--color-body);
}
.rbt-single-widget.widget_recent_comments ul li a, .rbt-single-widget.widget_recent_entries ul li a, .rbt-single-widget.widget_archive ul li a, .rbt-single-widget.widget_categories ul li a, .rbt-single-widget.widget_meta ul li a, .rbt-single-widget.widget_pages ul li a, .rbt-single-widget.widget_nav_menu ul li a {
  color: var(--color-body);
}
.rbt-single-widget.widget_recent_comments ul li a:hover, .rbt-single-widget.widget_recent_entries ul li a:hover, .rbt-single-widget.widget_archive ul li a:hover, .rbt-single-widget.widget_categories ul li a:hover, .rbt-single-widget.widget_meta ul li a:hover, .rbt-single-widget.widget_pages ul li a:hover, .rbt-single-widget.widget_nav_menu ul li a:hover {
  color: var(--color-primary);
}
.rbt-single-widget.widget_recent_comments ul li span, .rbt-single-widget.widget_recent_entries ul li span, .rbt-single-widget.widget_archive ul li span, .rbt-single-widget.widget_categories ul li span, .rbt-single-widget.widget_meta ul li span, .rbt-single-widget.widget_pages ul li span, .rbt-single-widget.widget_nav_menu ul li span {
  color: var(--color-body);
}
.rbt-single-widget.widget_recent_comments ul li:hover a span, .rbt-single-widget.widget_recent_entries ul li:hover a span, .rbt-single-widget.widget_archive ul li:hover a span, .rbt-single-widget.widget_categories ul li:hover a span, .rbt-single-widget.widget_meta ul li:hover a span, .rbt-single-widget.widget_pages ul li:hover a span, .rbt-single-widget.widget_nav_menu ul li:hover a span {
  color: var(--color-primary);
}
.rbt-single-widget.widget_recent_comments ul li + li, .rbt-single-widget.widget_recent_entries ul li + li, .rbt-single-widget.widget_archive ul li + li, .rbt-single-widget.widget_categories ul li + li, .rbt-single-widget.widget_meta ul li + li, .rbt-single-widget.widget_pages ul li + li, .rbt-single-widget.widget_nav_menu ul li + li {
  border-top: 1px solid var(--color-border);
}
.rbt-single-widget.widget_recent_comments > ul > li, .rbt-single-widget.widget_recent_entries > ul > li, .rbt-single-widget.widget_archive > ul > li, .rbt-single-widget.widget_categories > ul > li, .rbt-single-widget.widget_meta > ul > li, .rbt-single-widget.widget_pages > ul > li {
  margin-top: 0;
  margin-bottom: 0;
}
.rbt-single-widget.widget_recent_comments > ul > li:first-child, .rbt-single-widget.widget_recent_entries > ul > li:first-child, .rbt-single-widget.widget_archive > ul > li:first-child, .rbt-single-widget.widget_categories > ul > li:first-child, .rbt-single-widget.widget_meta > ul > li:first-child, .rbt-single-widget.widget_pages > ul > li:first-child {
  margin-top: 0;
}
.rbt-single-widget.widget_recent_comments > ul > li:last-child, .rbt-single-widget.widget_recent_entries > ul > li:last-child, .rbt-single-widget.widget_archive > ul > li:last-child, .rbt-single-widget.widget_categories > ul > li:last-child, .rbt-single-widget.widget_meta > ul > li:last-child, .rbt-single-widget.widget_pages > ul > li:last-child {
  margin-bottom: 10px;
}
.rbt-single-widget.widget_recent_entries .inner a {
  color: var(--color-white);
  margin-bottom: 4px;
}
.rbt-single-widget.widget_recent_entries .inner span {
  color: var(--color-body);
  font-size: 14px;
  letter-spacing: 0.5px;
}

.alignwide {
  margin-left: -220px;
  margin-right: -220px;
  max-width: 1400px;
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.tagcloud,
.wp-block-tag-cloud {
  margin: -3px;
  padding-left: 0;
}
.tagcloud a,
.wp-block-tag-cloud a {
  background: transparent;
  display: inline-block;
  height: 40px;
  color: var(--color-body);
  font-size: 16px !important;
  line-height: 35px;
  text-decoration: none;
  margin: 3px;
  border: 2px solid var(--color-border);
  padding: 0 20px;
  border-radius: 3px;
}
.tagcloud a:hover,
.wp-block-tag-cloud a:hover {
  background: var(--color-primary);
  color: #ffffff;
  border-color: var(--color-primary);
}

/*------------------------------
    Blog Details 
----------------------------------*/
.post-page-banner .rn-meta-list {
  justify-content: center;
}
.post-page-banner .rn-meta-list li {
  color: var(--color-body);
  font-size: 20px;
  margin: 0 15px;
  margin-top: 38px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-page-banner .rn-meta-list li {
    font-size: 16px;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .post-page-banner .rn-meta-list li {
    font-size: 16px;
    margin-top: 8px;
  }
}
.post-page-banner .rn-meta-list li svg {
  margin-right: 10px;
}
.post-page-banner .rn-meta-list li a {
  color: var(--color-body);
  transition: 0.3s;
}
.post-page-banner .rn-meta-list li a:hover {
  color: var(--color-primary);
}
.post-page-banner .category-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  margin: -10px;
  padding: 0;
  margin-top: 14px;
}
.post-page-banner .category-list li {
  margin: 10px;
  position: relative;
}
.post-page-banner .category-list li::after {
  position: absolute;
  content: ",";
  right: -9px;
}
.post-page-banner .category-list li:last-child::after {
  display: none;
}

.blog-details-content .category-meta {
  display: flex;
  padding: 42px 0;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  flex-wrap: wrap;
}
.blog-details-content .category-meta span.text {
  color: var(--color-white);
  font-size: 22px;
  display: inline-block;
  margin: 10px 0;
  margin-top: 0;
}

/**************************************
    Footer Styles
***************************************/
/*-----------------------
    Footer Style Here  
------------------------*/
.logo a img {
  max-height: 55px;
}

.footer-style-1 {
  background-color: var(--color-darkest);
  border-top: 1px solid var(--color-border);
  background-image: linear-gradient(0deg, var(--color-black), var(--color-blacker));
}
.footer-style-1 .footer-top {
  padding: 120px 0;
}
.footer-style-1 .footer-top .rn-footer-widget .text-big {
  font-size: 21px;
  margin-top: 30px;
  padding-right: 13%;
  line-height: 37px;
  font-weight: 500;
}
.footer-style-1 .footer-top .rn-footer-widget .title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.footer-style-1 .footer-top .rn-footer-widget .subtitle {
  font-weight: 400;
  color: var(--color-body);
  font-size: 16px;
  padding-right: 31%;
  line-height: 28px;
  margin-bottom: 32px;
}
.footer-style-1 .footer-top .rn-footer-widget .inner {
  margin-top: 13px;
}
.footer-style-1 .footer-top .rn-footer-widget .footer-link {
  list-style: none;
  padding: 0;
}
.footer-style-1 .footer-top .rn-footer-widget .footer-link li {
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer-style-1 .footer-top .rn-footer-widget .footer-link li a {
  font-size: 14px;
  display: block;
  color: var(--color-body);
  line-height: 25px;
}

.newsletter-form input {
  flex-grow: 1;
  width: auto;
  margin-right: 10px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.footer-style-default {
  border-top: 1px solid var(--color-border);
  background-color: var(--color-darkest);
  background-image: linear-gradient(0deg, var(--color-black), var(--color-blacker));
}
.footer-style-default .footer-top {
  padding: 100px 0;
  padding-bottom: 70px;
}
.footer-style-default .footer-top .rn-footer-widget .text-big {
  font-size: 21px;
  margin-top: 30px;
  padding-right: 13%;
  line-height: 1.7;
  font-weight: 500;
  color: var(--color-body);
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-style-default .footer-top .rn-footer-widget .text-big {
    font-size: 20px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer-style-default .footer-top .rn-footer-widget .text-big {
    font-size: 20px;
    padding-right: 0;
  }
}
.footer-style-default .footer-top .rn-footer-widget .title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.footer-style-default .footer-top .rn-footer-widget .subtitle {
  font-weight: 400;
  color: var(--color-body);
  font-size: 16px;
  padding-right: 31%;
  line-height: 28px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .footer-style-default .footer-top .rn-footer-widget .subtitle {
    padding-right: 0;
  }
}
.footer-style-default .footer-top .rn-footer-widget .inner {
  margin-top: 13px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link {
  list-style: none;
  padding: 0;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li {
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 14px;
  display: inline-block;
  color: var(--color-body);
  line-height: 25px;
  transition: 0.3s;
  text-transform: capitalize;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a:hover {
  color: var(--color-primary);
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.footer-style-default.variation-two {
  border-top: 0;
}
.footer-style-default.no-border {
  border-top: 0;
}

/*--------------------------
    Copyright Area  
---------------------------*/
.copyright-style-one {
  background: var(--color-darkest);
  padding: 20px 0;
  border-top: 1px solid var(--color-border);
}
.copyright-style-one .copyright-text {
  margin-bottom: 0;
  font-size: 14px;
}
.copyright-style-one .ft-menu {
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .copyright-style-one .ft-menu {
    justify-content: center;
  }
}
.copyright-style-one .ft-menu li {
  margin: 0;
  padding: 0 10px;
  position: relative;
}
.copyright-style-one .ft-menu li + li::after {
  position: absolute;
  content: "";
  height: 14px;
  width: 1px;
  background: var(--color-border);
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
}
.copyright-style-one .ft-menu li a {
  font-size: 14px;
}
.copyright-style-one.variation-two {
  background: var(--color-blackest);
}
.copyright-style-one.no-border {
  border-top: 0;
}

/**************************************
            Template
***************************************/
/*------------------------
    Banner Styles  
-------------------------*/
.slider-dot.rn-slick-dot .slick-dots {
  bottom: 60px;
}

.height-850 {
  height: 850px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-850 {
    height: auto;
    padding: 150px 0;
  }
}
@media only screen and (max-width: 767px) {
  .height-850 {
    height: auto;
    padding: 100px 0;
  }
}

.height-650 {
  height: 650px;
  display: flex;
  align-items: center;
}

.height-750 {
  height: 750px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-750 {
    height: auto;
    padding: 150px 0;
  }
}
@media only screen and (max-width: 767px) {
  .height-750 {
    height: auto;
    padding: 100px 0;
  }
}

.height-950 {
  height: 950px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-950 {
    height: auto;
    padding: 150px 0;
  }
}
@media only screen and (max-width: 767px) {
  .height-950 {
    height: auto;
    padding: 150px 0;
  }
}

.display-one {
  font-size: 75px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .display-one {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .display-one {
    font-size: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .display-one {
    font-size: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .display-one {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .display-one br {
    display: none;
  }
}

.display-two {
  font-size: 85px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .display-two {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .display-two {
    font-size: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .display-two {
    font-size: 65px;
  }
}
@media only screen and (max-width: 575px) {
  .display-two {
    font-size: 46px;
  }
}

.bg-overlay {
  position: relative;
}
.bg-overlay::before {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  background-color: transparent;
  background-image: linear-gradient(90deg, #060606 0%, rgba(6, 6, 6, 0) 90%);
  content: "";
}

.bg-overlay-solid {
  position: relative;
  z-index: 1;
}
.bg-overlay-solid::before {
  position: absolute;
  height: 100%;
  width: 40%;
  opacity: 1;
  top: 0;
  left: 0;
  content: "";
  background: var(--color-dark);
  z-index: -1;
}
.bg-overlay-solid::after {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  opacity: 0.5;
  filter: blur(100px);
  left: auto;
  top: -250px;
  left: -250px;
  bottom: auto;
  background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
  content: "";
  z-index: -1;
}

.frame-image {
  padding: 20px;
  position: relative;
  right: 0;
  border-radius: 10px;
  transition: var(--transition);
  height: 100%;
  background-color: var(--color-blackest);
}
.frame-image img {
  width: 100%;
}
.frame-image:hover {
  transform: translateY(-5px);
}

.slider-area .inner a.btn-default.btn-border,
.slider-area .inner button.btn-default.btn-border {
  border-color: var(--color-border);
  color: var(--color-white);
}

/*-----------------------
    Slider Style One  
-------------------------*/
.slider-style-1 .inner .subtitle {
  font-family: var(--font-primary);
  font-size: 18px;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 18px;
  color: var(--color-body);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-1 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-1 .inner .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-1 .inner .subtitle {
    font-size: 14px;
  }
}
.slider-style-1 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: inline-block;
}
.slider-style-1 .inner .title span {
  display: inline-block;
}
.slider-style-1 .inner .title span label {
  margin-bottom: 0;
}
.slider-style-1 .inner .description {
  font-family: var(--font-primary);
  padding: 0 22%;
  font-size: 25px;
  line-height: 1.7;
  margin-bottom: 40px;
  color: var(--color-body);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-1 .inner .description {
    padding: 0 14%;
    font-size: 22px;
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-1 .inner .description {
    padding: 0 1%;
    font-size: 20px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-1 .inner .description {
    padding: 0 1%;
    font-size: 20px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-1 .inner .description {
    font-size: 18px;
    margin-bottom: 25px;
  }
}
.slider-style-1 .inner.text-left .description {
  padding: 0;
}
.slider-style-1 .inner .list-icon li {
  color: var(--color-body);
}
.slider-style-1 .inner .list-icon li .icon {
  background-color: var(--color-blackest) !important;
}
.slider-style-1 .inner .header-caption span {
  color: var(--color-white);
  font-size: 85px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-1 .inner .header-caption span {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-1 .inner .header-caption span {
    font-size: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-1 .inner .header-caption span {
    font-size: 65px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-1 .inner .header-caption span {
    font-size: 46px;
  }
}
.slider-style-1.variation-default .inner {
  padding-top: 80px;
}
.slider-style-1.variation-2 {
  position: relative;
}
.slider-style-1.variation-2 .thumbnail img {
  transform: scale(1.1);
}

.slider-style-2 {
  padding-top: 130px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-2 {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-2 {
    padding-top: 150px;
  }
}
.slider-style-2 .inner .subtitle {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
.slider-style-2 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  color: var(--color-white);
}
@media only screen and (max-width: 767px) {
  .slider-style-2 .inner .title.display-one {
    font-size: 40px;
  }
}
.slider-style-2 .inner .list-icon li {
  color: var(--color-body);
}
.slider-style-2.variation-2 {
  padding-top: 79px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-2.variation-2 {
    padding-top: 170px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-2.variation-2 {
    padding-top: 170px;
  }
}
.slider-style-2.variation-2 .inner .title {
  font-weight: 500;
  font-size: 75px;
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-2.variation-2 .inner .title {
    font-size: 54px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-2.variation-2 .inner .title {
    font-size: 54px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-2.variation-2 .inner .title {
    font-size: 40px;
  }
}
.slider-style-2.variation-2 .inner .tag-title {
  color: var(--color-secondary);
  margin: 0;
  letter-spacing: 1.5px;
  padding-left: 129px;
  position: relative;
  margin-top: 50px;
}
.slider-style-2.variation-2 .inner .tag-title::before {
  position: absolute;
  content: "";
  left: 0;
  width: 100px;
  height: 2px;
  background: var(--color-secondary);
  top: 50%;
  transform: translateY(-50%);
}
.slider-style-2.variation-3 {
  padding-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-2.variation-3 {
    padding-top: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-2.variation-3 {
    padding-top: 180px;
  }
}
.slider-style-2.variation-3 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  font-size: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-2.variation-3 .inner .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-2.variation-3 .inner .title {
    font-size: 50px;
  }
  .slider-style-2.variation-3 .inner .title br {
    display: none;
  }
}
.slider-style-2.variation-3 .inner .description {
  font-size: 20px;
  line-height: 37px;
  margin-bottom: 0;
  color: var(--color-body);
}
@media only screen and (max-width: 767px) {
  .slider-style-2.variation-3 .inner .description {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-3 {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-3 {
    padding: 100px 0;
  }
}
.slider-style-3 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  font-size: 130px;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-3 .inner .title {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-3 .inner .title {
    font-size: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-3 .inner .title {
    margin-bottom: 20px;
    font-size: 50px;
  }
}
.slider-style-3 .inner .description {
  font-size: 22px;
  line-height: 43px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-3 .inner .description {
    font-size: 20px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-3 .inner .description {
    font-size: 18px;
    line-height: 30px;
  }
}
.slider-style-3 .thumbnail {
  position: relative;
  z-index: 2;
}
.slider-style-3 .thumbnail::after {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: calc(100% - 130px);
  content: "";
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: var(--color-blackest);
  box-shadow: var(--shadow-1);
  border-radius: 6px;
}

.slider-style-4 .inner {
  padding-top: 80px;
}
@media only screen and (max-width: 767px) {
  .slider-style-4 .inner {
    padding-top: 50px;
  }
}
.slider-style-4 .inner > * {
  animation-name: fadeOutUp;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.slider-style-4 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  font-size: 130px;
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: var(--color-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-4 .inner .title {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-4 .inner .title {
    font-size: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-4 .inner .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-4 .inner .title {
    font-size: 55px;
  }
}
.slider-style-4 .inner .description {
  font-size: 22px;
  line-height: 43px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: var(--color-body);
}
@media only screen and (max-width: 767px) {
  .slider-style-4 .inner .description {
    font-size: 20px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-4 .inner .description br {
    display: none;
  }
}
.slider-style-4 .inner .button-group {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.slider-style-4.variation-2 .inner .title {
  font-size: 70px;
}
@media only screen and (max-width: 767px) {
  .slider-style-4.variation-2 .inner .title {
    font-size: 42px;
  }
}
.slider-style-4.variation-2 .bg-overlay::before {
  background: #000000;
  content: "";
  opacity: 0.5;
  background-image: none;
}
.slider-style-4.variation-3 .inner .title {
  font-size: 70px;
}
.slider-style-4.variation-3 .bg-overlay::before {
  background: #000000;
  content: "";
  opacity: 0.5;
  background-image: none;
}

.slider-style-5 .inner .subtitle {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  position: relative;
  padding-right: 20px;
  display: inline-block;
  color: var(--color-white);
}
.slider-style-5 .inner .subtitle::after {
  position: absolute;
  content: "";
  width: 120px;
  height: 2px;
  background: var(--color-pink);
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.slider-style-5 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  font-size: 64px;
  line-height: 1.4;
  color: var(--color-white);
}
@media only screen and (max-width: 767px) {
  .slider-style-5 .inner .title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-style-5 .inner .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-5 .inner .title br {
    display: none;
  }
}

.slider-style-6 {
  background-color: var(--color-blackest);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6 {
    overflow: hidden;
    padding: 100px 0;
    padding-top: 170px;
  }
}
.slider-style-6.shape-right {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.slider-style-6.shape-right .shape-image {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6.shape-right .shape-image {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-6.shape-right .shape-image {
    display: none;
  }
}
.slider-style-6.shape-left {
  position: relative;
}
.slider-style-6.shape-left .inner {
  position: relative;
  z-index: 2;
}
.slider-style-6.shape-left .shape-image {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  transform: rotate(180deg);
  height: 100%;
  bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6.shape-left .shape-image {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-6.shape-left .shape-image {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6.inheader-not-transparent {
    padding-top: 100px;
  }
}
.slider-style-6 .inner .title {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  font-size: 80px;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-6 .inner .title {
    font-size: 62px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6 .inner .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-6 .inner .title {
    font-size: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-6 .inner .title br {
    display: none;
  }
}
.slider-style-6 .inner .description {
  font-size: 22px;
  line-height: 43px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6 .inner .description {
    font-size: 20px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-6 .inner .description {
    font-size: 18px;
    line-height: 30px;
  }
}
.slider-style-6 .inner a.btn-default.btn-border {
  border-color: var(--color-border);
  color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-perosonal-portfolio.height-750 {
    padding-top: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-perosonal-portfolio.height-750 {
    padding-top: 180px;
  }
}
.slider-perosonal-portfolio .inner {
  margin-top: -100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-perosonal-portfolio .inner {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .slider-perosonal-portfolio .inner {
    margin-top: 0;
  }
}
.slider-perosonal-portfolio .inner .title {
  font-size: 100px;
  color: var(--color-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-perosonal-portfolio .inner .title {
    font-size: 58px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-perosonal-portfolio .inner .title {
    font-size: 46px;
  }
}
.slider-perosonal-portfolio .inner a.btn-default.btn-border,
.slider-perosonal-portfolio .inner button.btn-default.btn-border {
  border-color: var(--color-border);
  color: var(--color-white);
}

.slider-style-7 .inner .title {
  font-size: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-style-7 .inner .title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-7 .inner .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-7 .inner .title {
    font-size: 32px;
  }
  .slider-style-7 .inner .title br {
    display: none;
  }
}

.slider-style-8 {
  justify-content: center;
}
.slider-style-8 .inner .title {
  font-size: 100px;
  line-height: 1.1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-8 .inner .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-8 .inner .title {
    font-size: 46px;
  }
}
.slider-style-8 .inner .description {
  font-size: 22px;
  line-height: 43px;
}
@media only screen and (max-width: 767px) {
  .slider-style-8 .inner .description {
    font-size: 20px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-8 .inner .description br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-8 .inner .description br {
    display: none;
  }
}

.slider-style-4 .slick-active .inner > * {
  animation-name: fadeInUp;
}
.slider-style-4 .slick-active .inner .title {
  animation-delay: 0.5s;
}
.slider-style-4 .slick-active .inner .description {
  animation-delay: 1s;
}
.slider-style-4 .slick-active .inner .button-group {
  animation-delay: 1.2s;
}

.slider-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.slider-bg-image.bg-image-1 {
  background-image: url(/build/images/bg-image-1.e12a7844.jpg);
}
.slider-bg-image.bg-image2 {
  background-image: url(/build/images/bg-image-1.e12a7844.jpg);
}
.slider-bg-image.bg-image3 {
  background-image: url(/build/images/bg-image-3.e12a7844.jpg);
}
.slider-bg-image.bg-image4 {
  background-image: url(/build/images/bg-image-4.650964e5.jpg);
}
.slider-bg-image.bg-image5 {
  background-image: url(/build/images/bg-image-4.e12a7844.jpg);
}
.slider-bg-image.bg-image5 {
  background-image: url(/build/images/bg-image-5.e12a7844.jpg);
}
.slider-bg-image.bg-image6 {
  background-image: url(/build/images/bg-image-6.e12a7844.jpg);
}
.slider-bg-image.bg-image10 {
  background-image: url(/build/images/bg-image-10.e12a7844.jpg);
}
.slider-bg-image.bg-image11 {
  background-image: url(/build/images/bg-image-11.e12a7844.jpg);
}
.slider-bg-image.bg-image13 {
  background-image: url(/build/images/bg-image-13.e12a7844.jpg);
}
.slider-bg-image.bg-image14 {
  background-image: url(/build/images/bg-image-14.e12a7844.jpg);
}
.slider-bg-image.bg-image15 {
  background-image: url(/build/images/bg-image-15.e12a7844.jpg);
}
.slider-bg-image.bg-image16 {
  background-image: url(/build/images/bg-image-16.e12a7844.jpg);
}

/*-----------------------------
    Portfolio details  
--------------------------------*/
.rwt-portfolio-details .content-left .title {
  font-size: 38px;
  margin-bottom: 33px;
}
@media only screen and (max-width: 767px) {
  .rwt-portfolio-details .content-left .title {
    font-size: 25px;
    margin-bottom: 20px;
  }
}
.rwt-portfolio-details .content-left .single-list-wrapper {
  margin: -5px;
}
.rwt-portfolio-details .content-left .single-list-wrapper .single-list {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.rwt-portfolio-details .content-left .single-list-wrapper .single-list + .single-list {
  border-top: 1px solid var(--color-border);
}
.rwt-portfolio-details .content-left .single-list-wrapper .single-list label {
  font-size: 20px;
  color: #fff;
  display: inline-block;
  padding-right: 16px;
  margin: 0;
}
.rwt-portfolio-details .content-left .single-list-wrapper .single-list span {
  font-size: 18px;
}
.rwt-portfolio-details .content-right .subtitle {
  font-size: 25px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.rwt-portfolio-details .content-right .description p {
  margin-bottom: 10px;
}

.rwt-portfolio-details .alignwide {
  margin-left: -100px;
  margin-right: -100px;
  max-width: 1400px;
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rwt-portfolio-details .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rwt-portfolio-details .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .rwt-portfolio-details .alignwide {
    margin-left: 0;
    margin-right: 0;
  }
}

/*--------------------------------
    Single Demo  
-------------------------------*/
.single-demo {
  text-align: center;
  background: var(--color-lessdark);
  border-radius: 10px;
  padding: 20px;
  transition: 0.3s;
  position: relative;
}
.single-demo a {
  display: block;
}
.single-demo .thumbnail {
  position: relative;
}
.single-demo .thumbnail img {
  border-radius: 10px;
  width: 100%;
}
.single-demo .thumbnail .image-light {
  display: none;
}
.single-demo .thumbnail .label-badge {
  position: absolute;
  right: 20px;
  border-radius: 5px;
  background-color: transparent;
  background: #fff700;
  padding: 0 12px;
  color: #000;
  font-size: 14px;
  height: 29px;
  line-height: 29px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  width: 60px;
  bottom: 20px;
}
.single-demo .title {
  margin: 0;
  margin-top: 20px;
  font-size: 20px;
  letter-spacing: 0.5px;
  position: relative;
  display: inline-block;
}
.single-demo:hover {
  transform: translateY(-5px);
}

.react-image img {
  max-height: 150px;
}

/*-------------------------
    Darkmode Styles  
---------------------------*/
.rn-back-top i {
  transition: 0.3s;
}

.rn-back-top:hover i {
  color: var(--color-primary) !important;
}

body.active-light-mode {
  background-color: var(--color-white);
  color: #65676b;
}
body.active-light-mode h1,
body.active-light-mode h2,
body.active-light-mode h3,
body.active-light-mode h4,
body.active-light-mode h5,
body.active-light-mode h6,
body.active-light-mode .h1,
body.active-light-mode .h2,
body.active-light-mode .h3,
body.active-light-mode .h4,
body.active-light-mode .h5,
body.active-light-mode .h6,
body.active-light-mode .social-default li a,
body.active-light-mode .rbt-single-widget .title,
body.active-light-mode .rbt-single-widget.widget_recent_entries .inner a,
body.active-light-mode .tagcloud a,
body.active-light-mode .wp-block-tag-cloud a,
body.active-light-mode .rwt-portfolio-details .content-left .single-list-wrapper .single-list label,
body.active-light-mode .blog-details-content .category-meta span.text,
body.active-light-mode .demo-slider-area.slider-style-1 .inner .title,
body.active-light-mode .rn-back-top svg,
body.active-light-mode .popup-mobile-menu .inner .header-top .close-menu .close-button,
body.active-light-mode .footer-style-default .footer-top .rn-footer-widget .title,
body.active-light-mode .rn-call-to-action.style-8 .title,
body.active-light-mode .rn-newsletter-default .newsletter-section-title .title,
body.active-light-mode .rn-default-tab .tab-button .tabs__tab .nav-link,
body.active-light-mode .rn-comment-form .section-title .title {
  color: #181818;
}
body.active-light-mode .rn-default-tab .tab-button .tabs__tab .nav-link.active,
body.active-light-mode .slider-style-3 .thumbnail::after {
  background: #f5f8fa;
}
body.active-light-mode .slider-style-8 .inner a.btn-default.btn-border,
body.active-light-mode .slider-style-8 .inner button.btn-default.btn-border {
  border-color: #d8d8d8;
  color: #65676b;
}
body.active-light-mode .rn-slick-arrow button.slick-arrow {
  border: 2px solid #d8d8d8;
  color: #65676b;
}
body.active-light-mode .rn-slick-arrow.testimonial-activation button.slick-arrow.prev-arrow::before,
body.active-light-mode .rn-slick-arrow.testimonial-activation button.slick-arrow.next-arrow::before {
  filter: brightness(0);
}
body.active-light-mode .rn-slick-arrow button.slick-arrow:hover {
  border-color: transparent !important;
}
body.active-light-mode .rn-slick-arrow.testimonial-activation button.slick-arrow.prev-arrow:hover::before,
body.active-light-mode .rn-slick-arrow.testimonial-activation button.slick-arrow.next-arrow:hover::before {
  filter: brightness(1);
}
body.active-light-mode .slider-style-8 .inner a.btn-default.btn-border:hover,
body.active-light-mode .slider-style-8 .inner button.btn-default.btn-border:hover {
  color: #fff;
  border-color: transparent;
}
body.active-light-mode .slider-style-1.banner-company .inner .list-icon li .icon {
  background-color: #f5f8fa !important;
}
body.active-light-mode .single-demo .thumbnail .image-light {
  display: block;
}
body.active-light-mode .single-demo .thumbnail .image-dark {
  display: none;
}
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a.active {
  background: #d8d8d8;
}
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a.active {
  background: #d8d8d8;
}
body.active-light-mode p,
body.active-light-mode input[type=text],
body.active-light-mode input[type=password],
body.active-light-mode input[type=email],
body.active-light-mode input[type=number],
body.active-light-mode input[type=tel],
body.active-light-mode textarea,
body.active-light-mode .breadcrumb-inner ul.page-list li a,
body.active-light-mode .rn-address .inner p a,
body.active-light-mode .error-inner .description,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li a,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li a,
body.active-light-mode .rbt-single-widget.widget_archive ul li a,
body.active-light-mode .rbt-single-widget.widget_categories ul li a,
body.active-light-mode .rbt-single-widget.widget_meta ul li a,
body.active-light-mode .rbt-single-widget.widget_pages ul li a,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li a,
body.active-light-mode .rbt-single-widget.widget_recent_entries .inner span,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li span,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li span,
body.active-light-mode .rbt-single-widget.widget_archive ul li span,
body.active-light-mode .rbt-single-widget.widget_categories ul li span,
body.active-light-mode .rbt-single-widget.widget_meta ul li span,
body.active-light-mode .rbt-single-widget.widget_pages ul li span,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li span,
body.active-light-mode .post-page-banner .rn-meta-list li a,
body.active-light-mode .post-page-banner .rn-meta-list li,
body.active-light-mode .breadcrumb-inner ul.page-list li.rn-breadcrumb-item.active,
body.active-light-mode .slider-style-1.bg-transparent .inner .subtitle,
body.active-light-mode .slider-style-1.bg-transparent .inner .description,
body.active-light-mode .popup-mobile-menu .mainmenu li a,
body.active-light-mode .footer-style-default .footer-top .rn-footer-widget .footer-link li a,
body.active-light-mode .footer-style-default .footer-top .rn-footer-widget .subtitle,
body.active-light-mode .rn-call-to-action.style-8 .content .subtitle,
body.active-light-mode .rn-newsletter-default .newsletter-section-title .description,
body.active-light-mode .slider-style-5 .inner .subtitle,
body.active-light-mode .slider-style-5 .inner .title,
body.active-light-mode .single-progress .title,
body.active-light-mode .radial-progress .circle-text span.count,
body.active-light-mode .radial-progress .circle-text span.count::after,
body.active-light-mode .radial-progress-single .circle-info .subtitle {
  color: #65676b;
}
body.active-light-mode .header-transparent.sticky .mainmenu-nav .mainmenu > li > a,
body.active-light-mode .clltoaction-style-default.style-7 .content .subtitle,
body.active-light-mode .copyright-style-one .ft-menu li a,
body.active-light-mode .footer-style-default .footer-top .rn-footer-widget .text-big {
  color: #65676b;
}
body.active-light-mode .form-group input,
body.active-light-mode .form-group textarea,
body.active-light-mode .rn-accordion-style.rn-accordion-02 .card .card-header,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item,
body.active-light-mode .breadcarumb-style-1,
body.active-light-mode .rn-accordion-style.rn-accordion-03 .card .card-header,
body.active-light-mode .rn-accordion-style.rn-accordion-04 .card,
body.active-light-mode .rn-pricing .pricing-table-inner,
body.active-light-mode input[type=text],
body.active-light-mode input[type=password],
body.active-light-mode input[type=email],
body.active-light-mode input[type=number],
body.active-light-mode input[type=tel],
body.active-light-mode textarea,
body.active-light-mode .rbt-single-widget,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li + li,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li + li,
body.active-light-mode .rbt-single-widget.widget_archive ul li + li,
body.active-light-mode .rbt-single-widget.widget_categories ul li + li,
body.active-light-mode .rbt-single-widget.widget_meta ul li + li,
body.active-light-mode .rbt-single-widget.widget_pages ul li + li,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li + li,
body.active-light-mode .tagcloud a,
body.active-light-mode .wp-block-tag-cloud a,
body.active-light-mode .section-title-2 .title,
body.active-light-mode .popup-mobile-menu .mainmenu li + li,
body.active-light-mode .clltoaction-style-default.style-7 .content-wrapper,
body.active-light-mode .copyright-style-one,
body.active-light-mode .footer-style-default .newsletter-form .form-group input,
body.active-light-mode .newsletter-area .border-top-bottom,
body.active-light-mode .rn-comment-form .inner .rnform-group textarea,
body.active-light-mode .rwt-portfolio-details .content-left .single-list-wrapper .single-list + .single-list {
  border-color: rgba(0, 0, 0, 0.1411764706);
}
body.active-light-mode .service.service__style--1.with-working-process .line {
  background-color: rgba(0, 0, 0, 0.1411764706);
  width: 83%;
}
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
  background: #d8d8d8;
}
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover {
  background: #d8d8d8;
  color: var(--color-primary);
}
body.active-light-mode .btn-read-more span::after {
  background: rgba(0, 0, 0, 0.1411764706);
}
body.active-light-mode .breadcrumb-inner ul.page-list li a:hover,
body.active-light-mode .rn-address .inner p a:hover,
body.active-light-mode .post-page-banner .rn-meta-list li a:hover,
body.active-light-mode .header-top-bar .header-left p a:hover {
  color: var(--color-primary);
}
body.active-light-mode .social-default li a:hover,
body.active-light-mode .tagcloud a:hover,
body.active-light-mode .wp-block-tag-cloud a:hover,
body.active-light-mode .header-top-news .btn-read-more span {
  color: var(--color-white);
}
body.active-light-mode .tagcloud a:hover,
body.active-light-mode .wp-block-tag-cloud a:hover {
  border-color: var(--color-primary);
}
body.active-light-mode a.btn-default.text-underline,
body.active-light-mode button.btn-default.text-underline {
  border-color: #181818;
  color: #181818;
}
body.active-light-mode .single-demo .thumbnail .image-active-light-mode {
  display: block;
}
body.active-light-mode .single-demo .thumbnail .image-dark {
  display: none;
}
body.active-light-mode .footer-style-default .social-default li a,
body.active-light-mode .copyright-style-one .social-default.color-lessdark li a,
body.active-light-mode .rn-newsletter-default .rn-newsletter .form-group input {
  background: #fff;
}
body.active-light-mode .copyright-style-one .ft-menu li + li::after {
  background: #c5c1c1;
}
body.active-light-mode .copyright-style-one .ft-menu li a:hover,
body.active-light-mode .footer-style-default .footer-top .rn-footer-widget .footer-link li a:hover {
  color: var(--color-primary);
}
body.active-light-mode .header-transparent-with-topbar .header-top-bar,
body.active-light-mode .header-transparent-with-topbar .header-default {
  background: transparent;
}
body.active-light-mode [class*=col]:nth-child(2) .counter-style-2.color-variation .count-number {
  background-color: #f9f5f5;
}
body.active-light-mode [class*=col]:nth-child(3) .counter-style-2.color-variation .count-number {
  background-color: #eef2f3;
}
body.active-light-mode .service-wrapper > [class*=col]:nth-child(4) .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(235, 0, 139, 0.1411764706);
}
body.active-light-mode .logo a img.logo-light {
  display: none;
}
body.active-light-mode .rn-back-top i {
  color: #333;
}
body.active-light-mode .service-wrapper > [class*=col]:nth-child(5) .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(0, 154, 255, 0.1607843137);
}
body.active-light-mode .service-wrapper > [class*=col]:nth-child(6) .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(48, 0, 255, 0.1607843137);
}

body.active-light-mode .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(5, 157, 255, 0.0588235294);
}

body.active-light-mode .service-wrapper > [class*=col]:nth-child(2) .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(251, 83, 67, 0.1019607843);
}

body.active-light-mode .service-wrapper > [class*=col]:nth-child(3) .service.service__style--1.icon-circle-style .icon {
  background-color: rgba(101, 73, 213, 0.1215686275);
}

body.active-light-mode .timeline-dot::after {
  background: radial-gradient(50% 50% at 50% 50%, #f5f8fa 48.96%, #f5f8fa 100%);
}

body.active-light-mode .header-top-bar {
  background-color: var(--color-white);
}

body.active-light-mode .single-timeline .single-content,
body.active-light-mode .rn-card,
body.active-light-mode .clltoaction-style-default.style-5 .content-wrapper,
body.active-light-mode .header-default,
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu,
body.active-light-mode .service.gallery-style,
body.active-light-mode .rn-box-card .inner,
body.active-light-mode .team-style-default .inner,
body.active-light-mode .rn-accordion-style .card,
body.active-light-mode .section-title .subtitle,
body.active-light-mode .card-box.card-style-1,
body.active-light-mode .list-icon li .icon,
body.active-light-mode .counter-style-2 .count-number,
body.active-light-mode .rn-pricing.style-2 .pricing-table-inner,
body.active-light-mode .rn-default-tab .rn-tab-content,
body.active-light-mode .rn-default-tab .tab-button .react-tabs__tab.react-tabs__tab--selected,
body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button::before,
body.active-light-mode .advance-pricing .pricing-left,
body.active-light-mode .rn-pricing.style-5 .pricing-table-inner,
body.active-light-mode .about-style-5 .about-wrapper,
body.active-light-mode .rn-sub-badge,
body.active-light-mode .about-style-6 .wrapper::after,
body.active-light-mode .box-grid-layout .rwt-card,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper,
body.active-light-mode .social-default li a,
body.active-light-mode .clltoaction-style-default.style-4 .content-wrapper,
body.active-light-mode .rn-address,
body.active-light-mode .rbt-single-widget .title,
body.active-light-mode .frame-image,
body.active-light-mode .rn-back-top,
body.active-light-mode .popup-mobile-menu .inner,
body.active-light-mode .rn-call-to-action.style-8,
body.active-light-mode .bg-overlay-solid::before {
  background: #f5f8fa;
}

body.active-light-mode .rn-accordion-style .card p {
  color: var(--color-blackest);
}

body.active-light-mode .rn-header.sticky {
  background: #f5f8fa !important;
}

body.active-light-mode .bg-color-blackest {
  background: #f5f8fa !important;
}

body.active-light-mode .footer-style-default,
body.active-light-mode .copyright-style-one {
  background: #f5f8fa;
}

body.active-light-mode .slider-style-2 .list-icon li .icon {
  background: var(--color-blackest);
}

body.active-light-mode .rn-pricing .pricing-table-inner::before,
body.active-light-mode .rn-timeline-single.dark-line .rn-timeline .progress-dot .dot-level,
body.active-light-mode .rn-timeline-single.dark-line .rn-timeline .progress-line .line-inner,
body.active-light-mode .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.active-light-mode .advance-pricing,
body.active-light-mode .rn-pricing.style-5 .pricing-table-inner .pricing,
body.active-light-mode .popup-mobile-menu .inner .header-top .close-menu .close-button,
body.active-light-mode .hamberger-button {
  background: #d8d8d8;
}

body.active-light-mode .single-demo {
  background: #e9f8fe;
}

body.active-light-mode .rn-accordion-style .card .card-body,
body.active-light-mode .brand-style-1 li {
  border-color: #d8d8d8;
}

body.active-light-mode hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1411764706);
}

body.active-light-mode .rn-card {
  border-color: #f5f8fa;
}

body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-header,
body.active-light-mode .rn-pricing .pricing-table-inner .pricing-header {
  border-color: rgba(0, 0, 0, 0.0392156863);
}

body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button::after {
  border-left: 20px solid #f5f8fa;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

body.active-light-mode a.btn-default.btn-border,
body.active-light-mode button.btn-default.btn-border {
  border-color: rgba(0, 0, 0, 0.1411764706);
  color: #181818;
}

body.active-light-mode a.btn-default.btn-border:hover,
body.active-light-mode button.btn-default.btn-border:hover {
  border-color: var(--color-secondary);
  color: #fff;
}

body.active-light-mode .rn-box-card .inner .thumbnail,
body.active-light-mode .team-style-default .inner .thumbnail {
  border: 6px solid var(--color-white);
}

body.active-light-mode .filter-button-default button,
body.active-light-mode .testimonial-style-two .content .client-info .subtitle,
body.active-light-mode .single-timeline .single-content .inner .content .date-of-timeline,
body.active-light-mode .service.gallery-style .content p,
body.active-light-mode .rn-box-card .inner .social-icon li a,
body.active-light-mode .team-style-default .inner .social-icon li a,
body.active-light-mode .rn-accordion-style .card .card-body,
body.active-light-mode .mainmenu-nav .mainmenu > li > a,
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a,
body.active-light-mode .list-icon li,
body.active-light-mode ul li,
body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li,
body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li svg,
body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button .title,
body.active-light-mode .advance-pricing .pricing-left .subtitle,
body.active-light-mode .header-top-bar .header-left p a,
body.active-light-mode .header-top-bar .header-right .address-content p a,
body.active-light-mode .header-top-bar .social-default.icon-naked li a,
body.active-light-mode .progress-info .title,
body.active-light-mode .progress-info span.progress-number,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a,
body.active-light-mode .clltoaction-style-default.style-4 .content .subtitle {
  color: #65676b;
}

body.active-light-mode .card-box.card-style-1 .inner .content .description {
  color: #65676b !important;
}

body.active-light-mode .clltoaction-style-default.style-5 .content .subtitle,
body.active-light-mode .rn-accordion-style .card .card-header button,
body.active-light-mode .counter-style-2 .count-number,
body.active-light-mode .rn-pricing .pricing-table-inner .pricing-header .pricing span.price,
body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-header .title,
body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-header .pricing span.currency,
body.active-light-mode .rn-pricing.active .pricing-table-inner .pricing-header .pricing span.subtitle,
body.active-light-mode .rn-pricing .pricing-table-inner .pricing-header .pricing span.subtitle,
body.active-light-mode .rn-default-tab .tab-button .rn-tab-button button,
body.active-light-mode .rn-pricing .pricing-table-inner .pricing-header .pricing span.currency,
body.active-light-mode .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.active-light-mode .clltoaction-style-default.style-6 .content .title,
body.active-light-mode .rn-splite-style .split-inner .title,
body.active-light-mode .advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button .title,
body.active-light-mode .advance-pricing .pricing-left .price-wrapper .price-amount,
body.active-light-mode .btn-read-more span,
body.active-light-mode .section-title-2 .title,
body.active-light-mode .box-grid-layout .rwt-card .inner .content .title,
body.active-light-mode .clltoaction-style-default.style-5 .content .title,
body.active-light-mode .clltoaction-style-default.style-4 .content .title,
body.active-light-mode .hamberger-button {
  color: #181818;
}

body.active-light-mode .about-style-6 .maintitle {
  color: #181818 !important;
}

body.active-light-mode .slider-style-1 .inner .title {
  color: var(--color-white);
}

body.active-light-mode .testimonial-style-two .content::after {
  color: #f5f8fa;
}

body.active-light-mode .header-transparent .mainmenu-nav .mainmenu > li > a {
  color: var(--color-body);
}

body.active-light-mode .header-top-bar .header-right .address-content p a:hover {
  color: var(--color-primary);
}

body.active-light-mode .rn-pricing .pricing-table-inner .pricing-header .pricing span.currency {
  opacity: 0.8;
}

body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover,
body.active-light-mode .btn-read-more:hover span {
  color: var(--color-primary);
}

body.active-light-mode .CircularProgressbar .CircularProgressbar-text {
  fill: #181818 !important;
}

body.active-light-mode .logo-active-light-mode {
  display: none;
}

body.active-light-mode .logo-dark {
  display: block;
}

body.active-light-mode .slider-area.bg-transparent .inner a.btn-default.btn-border,
body.active-light-mode .slider-area.bg-transparent .inner button.btn-default.btn-border {
  border-color: rgba(0, 0, 0, 0.1411764706);
  color: #181818;
}

body.active-light-mode .slider-area.bg-transparent .inner a.btn-default.btn-border:hover,
body.active-light-mode .slider-area.bg-transparent .inner button.btn-default.btn-border:hover {
  color: var(--color-white);
}

:root {
  --color-primary: #E67E22;
  --color-primary-rgba: rgb(230, 126, 55);
  --color-primary-alt: #E67E22;
  --color-primary-light: #E67E22;
  --color-primary-lightest: #E67E22;
  --color-secondary: #ffb000;
  --color-tertiary: #ff4a00;
  --color-tertiary-alt: #6549D5;
  --color-pink: #E33FA1;
  --color-light-green: #36C6F0;
  --color-primary-darker: #011827;
  --color-tertiary-darker: #150b40;
  --color-secondary-darker: #190301;
  --color-light-green-darker: #001f27;
  --color-pink-darker: #2f011d;
  --color-heading: #ffffff;
  --color-body: #acacac;
  --color-dark: #060606;
  --color-darker: rgba(6, 6, 6, 0.7);
  --color-darkest: rgba(0, 0, 0, 0.3);
  --color-black: #000000;
  --color-blacker: rgba(15, 15, 17, 0);
  --color-blackest: #141414;
  --color-blacker-alpha: rgba(20, 20, 20, 0.695);
  --color-border: rgba(255,255,255,0.12);
  --color-lessdark: #1e1e1e22;
  --color-gray: #65676B;
  --color-midgray: #878787;
  --color-light: #f5f8fa;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-white: #ffffff;
  --color-success: #02d256;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;
  --border-width: 2px;
  --radius-small: 6px;
  --radius: 10px;
  --radius-big: 16px;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --shadow-primary: 0 0 20px 5px rgba(251,83,67,0.2);
  --shadow-light: 1px 1px 6px rgba(0,0,0,0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0,0,0,0.01);
  --transition: 0.3s;
  --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);
  --font-primary: "Inter", sans-serif;
  --secondary-font: "Inter", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;
  --line-height-b1: 1.67;
  --line-height-b2: 1.7;
  --line-height-b3: 1.6;
  --line-height-b4: 1.3;
  --h1: 56px;
  --h2: 44px;
  --h3: 36px;
  --h4: 24px;
  --h5: 18px;
  --h6: 16px;
  --color-lounge-available: var(--color-success);
  --color-lounge-requests: var(--color-warning);
  --color-lounge-blocked: var(--color-danger);
}

.card, .card-body {
  border-radius: 0 !important;
}

.bg-dark-transparent {
  background-color: rgba(30, 30, 30, 0.8);
  padding: 0.75rem;
}

.user-dropdown .user-card {
  background-color: #6610f2;
  border: 1px solid white;
  padding: 0.5rem 1rem;
}
.user-dropdown .user-card .user-image {
  width: 25px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}
.user-dropdown .user-card .user-placeholder {
  background-image: url(/build/images/user_image_placeholder.1956bf01.png);
}
.user-dropdown .user-card .user-info {
  color: #fff !important;
}
/*!****************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/rocket.scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************/
@charset "UTF-8";
/* purgecss start ignore */
body {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}
body main.page-wrapper {
  margin-top: -1px;
}

footer {
  margin-top: auto;
}

.swal2-popup.swal2-modal.swal2-show {
  z-index: 10000;
}
.swal2-popup.swal2-modal.swal2-show button.swal2-cancel {
  padding: 12px 20px !important;
}

.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem 1.5rem;
  background-color: var(--color-darker);
  z-index: 10;
}
.loading-indicator span {
  font-size: 80px;
}

body div.breadcrumb-area .breadcrumb-inner .page-list {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 7px 14px;
  margin-bottom: 12px;
  box-shadow: var(--shadow-lighter);
  border-radius: 4px;
}
body div.breadcrumb-area .breadcrumb-inner .page-list li {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

body .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  transform: scale(0.65) translateY(-0.75rem) translateX(0.75rem);
}

body .form-floating > .form-control:focus ~ label,
body .form-floating > .form-control:not(:placeholder-shown) ~ label,
body .form-floating > .form-select ~ label {
  transform: scale(0.65) translateY(-0.75rem) translateX(0.75rem);
}

.bg-color-dynamic, .times .time-btn, body .swal2-popup.swal2-modal, div .modal-dialog .modal-content .modal-body .ticket, div .modal-dialog .modal-content .modal-body .drink, div .modal-dialog .modal-content .modal-body .package, div .modal-dialog .modal-content .modal-body .payment-methods .pay-option, div .modal-dialog .modal-content .modal-body .checkout-event {
  background-color: var(--color-blackest);
}

body div .bg-danger {
  background: var(--color-danger) !important;
}

body div .bg-success {
  background: var(--color-success) !important;
}

body div .bg-warning {
  background: var(--color-warning) !important;
}

body div .bg-mothernote {
  background: var(--color-vimeo) !important;
}

div .modal-dialog .modal-content {
  border: 0px solid white;
  padding: 0;
  background: var(--color-black);
}
div .modal-dialog .modal-content .modal-header {
  border-bottom: 0px;
  padding: 20px;
}
div .modal-dialog .modal-content .modal-header .modal-title {
  margin: 0 auto;
}
div .modal-dialog .modal-content .modal-header .btn-close, div .modal-dialog .modal-content .modal-header .btn-back {
  margin: 0;
  padding: 0;
  height: 30px;
  width: 30px;
  color: var(--color-body);
  font-size: 30px;
}
div .modal-dialog .modal-content .modal-header .btn-back {
  border: 0px;
  display: none;
  background: none !important;
}
div .modal-dialog .modal-content .modal-body #shop {
  height: 100%;
}
div .modal-dialog .modal-content .modal-body .auth-title {
  margin-bottom: 0px;
}
div .modal-dialog .modal-content .modal-body .small-text {
  font-size: 13px;
  line-height: 15px;
}
div .modal-dialog .modal-content .modal-body .small-text .btn-link-underlined span {
  line-height: 20px;
}
div .modal-dialog .modal-content .modal-body .small-text .btn-link-underlined span::before {
  bottom: -1px;
}
div .modal-dialog .modal-content .modal-body .small-text .btn-link-underlined span::after {
  bottom: 0px;
}
div .modal-dialog .modal-content .modal-body .checkout-event {
  box-shadow: var(--shadow-lighter);
  padding: 20px;
  border-radius: 5px;
}
div .modal-dialog .modal-content .modal-body .payment-methods .pay-option {
  box-shadow: var(--shadow-lighter);
  border-radius: 5px;
  height: 100%;
}
div .modal-dialog .modal-content .modal-body .payment-methods .pay-option.active {
  position: relative;
}
div .modal-dialog .modal-content .modal-body .payment-methods .pay-option.active:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 5px;
  border: 2px solid var(--color-success);
}
div .modal-dialog .modal-content .modal-body .payment-methods .pay-option img {
  height: 30px;
}
div .modal-dialog .modal-content .modal-body #progressbar .active {
  color: var(--color-primary);
}
div .modal-dialog .modal-content .modal-body #progressbar li.active:before,
div .modal-dialog .modal-content .modal-body #progressbar li.active:after {
  background: var(--color-primary);
}
div .modal-dialog .modal-content .modal-body .register-step-info {
  margin-bottom: 20px;
}
div .modal-dialog .modal-content .modal-body .checkout,
div .modal-dialog .modal-content .modal-body .lounge-persons-container {
  margin-bottom: 40px;
}
div .modal-dialog .modal-content .modal-body .checkout #notes,
div .modal-dialog .modal-content .modal-body .lounge-persons-container #notes {
  display: block;
  width: 100%;
  min-height: 80px;
  box-sizing: border-box;
}
div .modal-dialog .modal-content .modal-body .checkout-legal-text {
  font-size: 12px;
  margin: 8px 0;
}
div .modal-dialog .modal-content .modal-body .checkout-missing-infos {
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  padding: 10px 10px 10px 45px;
  margin: 20px 0px;
  position: relative;
}
div .modal-dialog .modal-content .modal-body .checkout-missing-infos svg {
  color: var(--color-danger);
  position: absolute;
  top: 12px;
  left: 10px;
}
div .modal-dialog .modal-content .modal-body .checkout-missing-infos h6 {
  color: var(--color-danger) !important;
  font-weight: bold;
}
div .modal-dialog .modal-content .modal-body .checkout .ticket,
div .modal-dialog .modal-content .modal-body .checkout .package,
div .modal-dialog .modal-content .modal-body .checkout .drink,
div .modal-dialog .modal-content .modal-body .checkout .lounge-data-info {
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px dashed;
}
div .modal-dialog .modal-content .modal-body .ticket, div .modal-dialog .modal-content .modal-body .drink, div .modal-dialog .modal-content .modal-body .package {
  box-shadow: var(--shadow-lighter);
  padding: 20px;
  border-radius: 5px;
}
div .modal-dialog .modal-content .modal-body .ticket .ticket-pricing, div .modal-dialog .modal-content .modal-body .drink .ticket-pricing, div .modal-dialog .modal-content .modal-body .package .ticket-pricing {
  line-height: 20px;
}
div .modal-dialog .modal-content .modal-body .ticket .ticket-pricing .ticket-price, div .modal-dialog .modal-content .modal-body .drink .ticket-pricing .ticket-price, div .modal-dialog .modal-content .modal-body .package .ticket-pricing .ticket-price {
  font-size: 16px;
  font-weight: bold;
  display: block;
  color: var(--color-white);
}
div .modal-dialog .modal-content .modal-body .package {
  border: 0px;
}
div .modal-dialog .modal-content .modal-body .drink {
  border: 0px;
}
div .modal-dialog .modal-content .modal-body .btn-greensuccess {
  background: var(--color-success) !important;
  color: var(--color-white) !important;
}
div .modal-dialog .modal-content .modal-body .btn {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: var(--shadow-light);
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  height: 50px;
  line-height: 47px;
  padding: 0 28px;
  transition: 0.4s;
}
div .modal-dialog .modal-content .modal-body .btn:hover {
  box-shadow: var(--shadow-primary);
  filter: brightness(125%);
  color: var(--color-white);
  text-decoration: none;
}
div .modal-dialog .modal-content .modal-body .btn.next-btn {
  background: transparent;
  color: var(--color-white);
  font-weight: bold;
  font-size: 18px;
}
div .modal-dialog .modal-content .modal-body .subtitle {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);
  box-shadow: var(--shadow-lighter);
}
div .modal-dialog .modal-content .modal-body .subtitle span {
  background: linear-gradient(95deg, var(--color-primary) 0%, var(--color-secondary) 45%, var(--color-tertiary) 100%) 98%/200% 100%;
  text-transform: capitalize;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
div .modal-dialog .modal-content .modal-body .buy-sums {
  line-height: 24px;
}
div .modal-dialog .modal-content .modal-body .buy-sums .buy-sums-price {
  font-size: 12px;
}
div .modal-dialog .modal-content .modal-body .buy-sums .buy-sums-price b {
  font-size: 20px;
}
div .bg-dark-transparent {
  background-color: var(--color-blackest);
  padding: 0.75rem;
}

body .swal2-popup.swal2-modal {
  color: var(--color-body);
  font-size: 16px;
}
body .swal2-popup.swal2-modal button.swal2-confirm.swal2-styled,
body .swal2-popup.swal2-modal button.swal2-deny.swal2-styled {
  border: 0px !important;
  padding: 12px 20px;
}
body .swal2-popup.swal2-modal button.swal2-confirm.swal2-styled:focus,
body .swal2-popup.swal2-modal button.swal2-deny.swal2-styled:focus {
  box-shadow: none !important;
}
body .swal2-popup.swal2-modal button.swal2-confirm.swal2-styled:hover,
body .swal2-popup.swal2-modal button.swal2-deny.swal2-styled:hover {
  box-shadow: var(--shadow-primary);
  filter: brightness(125%);
  color: var(--color-white);
  text-decoration: none;
}

body input[type=text]:disabled,
body input[type=tel]:disabled {
  -webkit-text-fill-color: var(--color-body);
  opacity: 1;
}

body .form-select:focus {
  box-shadow: 0 0 0 0.25rem var(--color-primary);
}

body.active-light-mode div #user #UserDetails .user-image {
  background-color: #b0b0b0;
}
body.active-light-mode div #user #UserDetails .image-edit-btn g {
  fill: #b0b0b0;
}
body.active-light-mode div #user #UserDetails small {
  color: #999;
}
body.active-light-mode div .modal-dialog .modal-content {
  background: var(--color-white);
}
body.active-light-mode div .modal-dialog .modal-content .modal-body .auth-title {
  margin-bottom: 0px;
}
body.active-light-mode div .modal-dialog .modal-content .modal-body .ticket, body.active-light-mode div .modal-dialog .modal-content .modal-body .package,
body.active-light-mode div .modal-dialog .modal-content .modal-body .drink {
  box-shadow: var(--shadow-lighter);
}
body.active-light-mode div .modal-dialog .modal-content .modal-body .ticket .ticket-pricing .ticket-price, body.active-light-mode div .modal-dialog .modal-content .modal-body .package .ticket-pricing .ticket-price,
body.active-light-mode div .modal-dialog .modal-content .modal-body .drink .ticket-pricing .ticket-price {
  color: var(--color-black);
}
body.active-light-mode .btn-link-underlined span {
  color: var(--color-blackest);
}
body.active-light-mode .btn-link-underlined span:hover {
  color: var(--color-primary);
}

.btn-link-underlined span {
  position: relative;
  color: var(--color-heading);
  display: inline-block;
  line-height: 35px;
  transition: 0.3s;
}
.btn-link-underlined span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: var(--color-border);
  transition: 0.3s;
  width: 100%;
  bottom: 3px;
}
.btn-link-underlined span::before {
  position: absolute;
  content: "";
  left: auto;
  bottom: 2px;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}
.btn-link-underlined:hover span {
  color: var(--color-primary);
}
.btn-link-underlined:hover span::before {
  width: 100%;
  left: 0;
  right: auto;
}

#userWidget {
  position: relative;
  min-width: 60px;
  height: 40px;
  float: left;
  border-radius: 8px;
  padding: 7px 15px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
#userWidget #user-widget {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

body ul li #logout-mobile {
  color: red;
}

.login-status {
  display: none !important;
}

.login-status.logged-in {
  display: initial !important;
}

#side-menu .header-top {
  border-bottom: 0px;
}

div.popup-mobile-menu .mainmenu li + li {
  border-top: 0px;
}

#side-menu {
  z-index: 1059 !important;
}
#side-menu .nav-link i {
  margin-right: 10px;
}
#side-menu .side-menu-user-login {
  padding: 0 20px;
  display: inline !important;
}
#side-menu .side-menu-user-login.logged-in {
  display: none !important;
}

.side-menu-user-info {
  margin: 20px 0 5px 0;
  border-bottom: 1px solid var(--color-border);
}
.side-menu-user-info .side-menu-user-card {
  padding: 10px;
  border-radius: 6px;
  margin: 0px 5px;
}
.side-menu-user-info .side-menu-user-image {
  width: 70px;
  height: 70px;
  background: var(--color-blackest);
  border-radius: 35px;
  display: inline-block;
  background-size: contain;
}
.side-menu-user-info .side-menu-user-name {
  margin-left: 20px;
  width: 100%;
}
.side-menu-user-info .side-menu-user-edit a {
  color: #fff;
}

.btn-appstore-badge {
  background: url(/build/images/app-store-badge.8f62d0fe.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
  height: 60px;
  transition: none !important;
}
.btn-appstore-badge:hover, .btn-appstore-badge:focus {
  border: none !important;
}

.btn-playstore-badge {
  background: url(/build/images/google-play-badge.335c666c.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 201px;
  height: 60px;
  transition: none !important;
}
.btn-playstore-badge:hover, .btn-playstore-badge:focus {
  border: none !important;
}

div.bg-overlay-dark::before {
  background: #000000;
  content: "";
  opacity: 0.75 !important;
  background-image: none;
}

body .mainmenu-nav ul.mainmenu {
  display: flex;
  margin: 0;
  padding: 0;
}
body .mainmenu-nav ul.mainmenu > li > a {
  color: #fff;
}

body.active-light-mode .bg-color-dynamic, body.active-light-mode .times .time-btn, .times body.active-light-mode .time-btn, body.active-light-mode div .modal-dialog .modal-content .modal-body .checkout-event, div .modal-dialog .modal-content .modal-body body.active-light-mode .checkout-event, body.active-light-mode div .modal-dialog .modal-content .modal-body .payment-methods .pay-option, div .modal-dialog .modal-content .modal-body .payment-methods body.active-light-mode .pay-option, body.active-light-mode div .modal-dialog .modal-content .modal-body .ticket, div .modal-dialog .modal-content .modal-body body.active-light-mode .ticket, body.active-light-mode div .modal-dialog .modal-content .modal-body .package, div .modal-dialog .modal-content .modal-body body.active-light-mode .package, body.active-light-mode div .modal-dialog .modal-content .modal-body .drink, div .modal-dialog .modal-content .modal-body body.active-light-mode .drink, body.active-light-mode .swal2-popup.swal2-modal {
  background-color: var(--color-light);
}
body.active-light-mode .mainmenu-nav ul.mainmenu {
  display: flex;
  margin: 0;
  padding: 0;
}
body.active-light-mode .mainmenu-nav ul.mainmenu > li > a {
  color: var(--color-gray);
}
body.active-light-mode .rn-office-badge {
  background: #f5f8fa;
}

body a.btn-default,
body button.btn-default,
body div.btn-default {
  background: linear-gradient(95deg, var(--color-primary) 0%, var(--color-secondary) 45%, var(--color-tertiary) 100%) 98%/200% 100%;
}

/* Theme Gradient */
.theme-gradient {
  background: linear-gradient(95deg, var(--color-primary) 0%, var(--color-secondary) 45%, var(--color-tertiary) 100%) 98%/200% 100%;
  text-transform: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-gradient {
    color: var(--color-secondary);
    background: none !important;
  }
}
/* Theme Gradient */
.theme-gradient,
.counter-style-1 .count-number {
  background: linear-gradient(95deg, var(--color-primary) 0%, var(--color-secondary) 45%, var(--color-tertiary) 100%) 98%/200% 100%;
  text-transform: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-theme-gradient, #UserDetails .btn,
#UserSettings .btn, div .modal-dialog .modal-content .modal-body .btn, body .swal2-popup.swal2-modal button.swal2-confirm.swal2-styled,
body .swal2-popup.swal2-modal button.swal2-deny.swal2-styled {
  background-color: transparent;
  background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-gradient,
  .counter-style-1 .count-number {
    color: var(--color-secondary);
    background: none !important;
  }
}
.height-550 {
  height: 550px;
  display: flex;
  align-items: center;
}

#user_module {
  display: inline !important;
}

.form-control.is-invalid {
  border-color: var(--color-danger) !important;
}

.form-control.is-valid {
  border-color: var(--color-success) !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-550 {
    height: auto;
    padding: 120px 0;
  }
}
@media only screen and (min-width: 768px) {
  body .w-md-75 {
    width: 75% !important;
  }
}
@media only screen and (max-width: 991px) {
  body .form-floating > label {
    padding: 1rem 1.2rem;
  }
  #userWidget .user-submenu {
    display: none;
  }
  #user_module.logged-in {
    display: none !important;
  }
  .copyright-left > p:nth-child(1) {
    text-align: center;
  }
}
.shop-footer {
  position: sticky;
  bottom: 0;
  left: 0;
}

.shop-footer-spacing {
  height: 100px;
  width: 1px;
}

@media only screen and (max-width: 767px) {
  .height-550 {
    height: auto;
    padding: 80px 0;
  }
  div .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 18px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div .modal-dialog .modal-content .shop-footer {
    position: fixed;
    bottom: 0;
  }
  div .modal-dialog .modal-content .shop-footer-spacing {
    height: 100px;
    width: 1px;
  }
}
@media only screen and (max-width: 991px) {
  #my_switcher {
    display: none;
  }
}
.popup-mobile-menu .inner {
  display: flex;
  flex-direction: column;
}
.popup-mobile-menu .inner .container {
  padding-left: 7px;
  padding-right: 7px;
}
.popup-mobile-menu .inner .mainmenu {
  padding: 15px 12px;
}

ul .nav-link {
  text-decoration: none;
  color: var(--color-body);
}
ul .nav-link:hover {
  color: var(--color-primary);
}

body a.btn-default,
body button.btn-default,
body div.btn-default {
  transition: none !important;
}
body input[type=checkbox] ~ label,
body input[type=radio] ~ label {
  line-height: 29px;
  padding-left: 28px;
}
body input[type=checkbox] ~ label::before,
body input[type=radio] ~ label::before {
  width: 20px;
  height: 20px;
}
body input[type=radio] ~ label::after {
  width: 14px;
  height: 14px;
}
body input[type=checkbox] ~ label::after {
  top: 10px;
  left: 4px;
  width: 13px;
  height: 8px;
}

body input:valid:not(:-moz-placeholder-shown):not(.is-invalid):not([type=file]), body select:valid:not(:-moz-placeholder-shown):not(.is-invalid):not(#saved_addresses):not([name=status]) {
  border-color: var(--color-success);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  -moz-transition: none;
  transition: none;
}

body input:valid:not(:placeholder-shown):not(.is-invalid):not([type=file]),
body select:valid:not(:placeholder-shown):not(.is-invalid):not(#saved_addresses):not([name=status]) {
  border-color: var(--color-success);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  transition: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

body .form-select,
body .form-control {
  border-color: var(--color-border);
}

body label {
  color: var(--color-body);
}

body form {
  margin-top: 20px;
}

body .form-floating > label {
  padding: 1rem 2.1rem;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date] {
  color: var(--color-body);
  background: transparent;
  height: 50px !important;
  padding-left: 1.7rem !important;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=date]:focus {
  color: var(--color-body);
  background: transparent;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-primary), 0.25);
  border-color: var(--color-primary);
}

textarea.form-control {
  color: var(--color-body);
  background: transparent;
  height: 50px;
  padding-left: 1.7rem !important;
}
textarea.form-control:focus {
  color: var(--color-body);
  background: transparent;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-primary), 0.25);
  border-color: var(--color-primary);
}

input[type=file] {
  color: currentColor;
  background: transparent;
  height: 55px !important;
  padding-top: 13px !important;
}
input[type=file]:focus {
  background: transparent;
}

body select.form-select {
  border: var(--border-width) solid var(--color-border);
  color: currentColor;
  height: 50px !important;
  border-radius: var(--radius);
  background-color: transparent;
  font-size: 14px;
  padding-left: 12px;
}
body select.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--color-primary), 0.25);
  border-color: var(--color-primary);
}

body.active-light-mode select.form-select,
body.active-light-mode input[type=date] {
  border: var(--border-width) solid rgba(0, 0, 0, 0.12);
  color: #65676b;
}

.form-error {
  background: var(--color-danger);
  color: var(--color-white);
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 400;
}

.plus,
.minus {
  user-select: none;
  /* standard syntax */
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

.times .time-btn {
  box-shadow: var(--shadow-lighter);
  border-radius: 5px;
  line-height: 20px;
  padding: 15px 20px;
  cursor: pointer;
}
.times .time-btn span {
  font-weight: bold;
  font-size: 22px;
}
.times .time-btn.active {
  position: relative;
}
.times .time-btn.active:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 5px;
  border: 2px solid var(--color-success);
}

body div.value {
  text-align: center;
  max-width: 60px;
  border-radius: var(--radius);
}

body div.persons-picker {
  transform: scale(1.25);
  margin-bottom: 30px;
}

body input.ticket-count,
body input.person-count {
  height: 35px !important;
  width: 60px !important;
  padding: 15px 10px !important;
  border-radius: var(--radius) !important;
  border: var(--border-width) solid var(--color-success);
  color: var(--color-success);
  background: transparent;
  font-size: 16px;
  font-weight: 400;
}

body input.ticket-count:disabled,
body input.person-count:disabled {
  -webkit-text-fill-color: var(--color-success);
  opacity: 1;
}

body div.minus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: var(--border-width) solid currentColor;
  border-radius: var(--radius);
  margin-right: 0.5rem;
  cursor: pointer;
}

body div.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--border-width) solid var(--color-gray);
  width: 35px;
  height: 35px;
  border-radius: var(--radius);
  margin-left: 0.5rem;
  cursor: pointer;
}

body div.minus.minusable,
body div.plus.plusable {
  border: var(--border-width) solid var(--color-success);
  color: var(--color-success);
}

.phone-verified-status {
  position: absolute;
  right: 80px;
  top: 10px;
}

.verify-phone-box {
  margin: 30px 0px;
}

.verify-form-phonenumber {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

#verify-code-form {
  margin-bottom: 30px;
}
#verify-code-form #code {
  height: 50px;
}

#UserDetails .btn,
#UserSettings .btn {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: var(--shadow-light);
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  height: 50px;
  line-height: 47px;
  padding: 0 28px;
  transition: 0.4s;
}
#UserDetails .btn:hover,
#UserSettings .btn:hover {
  box-shadow: var(--shadow-primary);
  filter: brightness(125%);
  color: var(--color-white);
  text-decoration: none;
}

body.active-light-mode header.header-transparent:not(.sticky) .logo a img.logo-light {
  display: block;
}

body.active-light-mode header.header-transparent:not(.sticky) .logo a img.logo-dark {
  display: none;
}

body.active-light-mode .header-transparent .mainmenu-nav .mainmenu > li > a {
  color: var(--color-light);
}

.order-status {
  border-radius: 5px;
  padding: 3px 8px;
  text-align: center;
}

.order-status.order-succeeded {
  background: var(--color-success);
}

.order-status.order-cancelled {
  background: var(--color-danger);
}

#my_switcher-mobile {
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: 20px;
}

.rn-office-badge {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-size: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: inline-block;
  padding: 10px 16px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
.rn-office-badge span {
  text-transform: none !important;
}

.rn-card.box-card-style-default {
  transform: translateZ(0);
}

.timeline-dot .time-line-circle {
  background: radial-gradient(50% 50% at 50% 50%, rgba(144, 144, 144, 0) 40.62%, rgba(144, 144, 144, 0.5) 100%);
}
.timeline-dot::before {
  box-shadow: 0 0 15px rgba(144, 144, 144, 0.5);
}

body .form-control:focus,
body .form-select:focus,
body .form-check-input:focus,
body .btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--color-primary), 0.25);
}

#share-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 0 0 5px;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
#share-btn i {
  font-size: 18px;
}

@media all and (max-width: 767px) {
  #share-btn {
    display: none !important;
  }
}
#cookies-eu-banner {
  position: fixed;
  background-color: var(--color-blackest);
  color: currentColor;
  z-index: 200;
  padding: 1rem 8rem;
  z-index: 1055;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
}

#cookies-eu-accept,
#modal-cookies-eu-accept {
  height: 40px;
  line-height: 35px;
  background-color: var(--color-primary);
  border-radius: var(--radius);
  border: none;
}
#cookies-eu-accept:hover,
#modal-cookies-eu-accept:hover {
  filter: brightness(125%);
  box-shadow: 0 0 10px var(--color-primary);
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.slide-fade-up-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-up-enter-from,
.slide-fade-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-right-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-right-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-right-enter-from,
.slide-fade-right-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-left-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-left-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-left-enter-from,
.slide-fade-left-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-left-500-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-left-500-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-left-500-enter-from,
.slide-fade-left-500-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-left-700-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-left-700-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-left-700-enter-from,
.slide-fade-left-700-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

/* CSS für Custom Tooltip */
.custom-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

body.active-light-mode .custom-tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

/* LANG-PICKER */
.lang-picker-button {
  position: fixed;
  bottom: 30px;
  left: 30px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  z-index: 999 !important;
  background-color: var(--color-blackest);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lang-picker-button .dropdown-toggle::after {
  display: none;
}
.lang-picker-button #lang-options {
  background-color: var(--color-blackest);
  border-radius: var(--radius);
  max-width: 80px;
}
.lang-picker-button #lang-options li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}
.lang-picker-button #lang-options li:hover {
  background-color: var(--color-primary);
}
.lang-picker-button #lang-options .dropdown-item {
  width: -moz-fit-content;
  width: fit-content;
}
.lang-picker-button #lang-options .dropdown-item:hover {
  background-color: var(--color-primary);
}

/* LANG-PICKER ENDE */
/* purgecss end ignore */
/*!***************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/fonts.scss ***!
  \***************************************************************************************************************************************************************************************************************************************************************************************************************/
/* inter-300 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url(/build/fonts/inter-v11-latin-300.f69634fb.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-300.f69634fb.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-300.71cb4fb8.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-300.e7bf730f.woff) format("woff"), url(/build/fonts/inter-v11-latin-300.4ecd3ff2.ttf) format("truetype"), url(/build/images/inter-v11-latin-300.4d10f6d3.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(/build/fonts/inter-v11-latin-regular.ff3afbb2.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-regular.ff3afbb2.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-regular.c96fe5ff.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-regular.513f22e1.woff) format("woff"), url(/build/fonts/inter-v11-latin-regular.c859cc4f.ttf) format("truetype"), url(/build/images/inter-v11-latin-regular.b8db5cf5.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url(/build/fonts/inter-v11-latin-500.725188c5.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-500.725188c5.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-500.aa212193.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-500.9d13296f.woff) format("woff"), url(/build/fonts/inter-v11-latin-500.ed934f8e.ttf) format("truetype"), url(/build/images/inter-v11-latin-500.bea79ba7.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/inter-v11-latin-600.41348600.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-600.41348600.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-600.ee12ccf9.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-600.cf69c3be.woff) format("woff"), url(/build/fonts/inter-v11-latin-600.afd672b8.ttf) format("truetype"), url(/build/images/inter-v11-latin-600.9a42e1a5.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url(/build/fonts/inter-v11-latin-700.862844a1.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-700.862844a1.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-700.65a568f2.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-700.90e1ac1b.woff) format("woff"), url(/build/fonts/inter-v11-latin-700.fc638342.ttf) format("truetype"), url(/build/images/inter-v11-latin-700.0a85e1f2.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-800 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url(/build/fonts/inter-v11-latin-800.53040d94.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-800.53040d94.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-800.0a74b414.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-800.af14b68e.woff) format("woff"), url(/build/fonts/inter-v11-latin-800.1722cc2b.ttf) format("truetype"), url(/build/images/inter-v11-latin-800.382996fb.svg#Inter) format("svg"); /* Legacy iOS */
}
/* inter-900 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url(/build/fonts/inter-v11-latin-900.b40760bb.eot); /* IE9 Compat Modes */
  src: local(""), url(/build/fonts/inter-v11-latin-900.b40760bb.eot?#iefix) format("embedded-opentype"), url(/build/fonts/inter-v11-latin-900.273eaefe.woff2) format("woff2"), url(/build/fonts/inter-v11-latin-900.e562b180.woff) format("woff"), url(/build/fonts/inter-v11-latin-900.0b5eab40.ttf) format("truetype"), url(/build/images/inter-v11-latin-900.f7d84ce7.svg#Inter) format("svg"); /* Legacy iOS */
}
/*!***********************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/footer/footer.scss ***!
  \***********************************************************************************************************************************************************************************************************************************************************************************************************************/
footer {
  width: 100%;
  background-color: var(--color-blackest);
}
footer .footer-links {
  justify-content: center;
}
footer .footer-links a {
  text-decoration: none;
  margin-left: 1rem;
  margin-right: 1rem;
}
footer .footer-links a i {
  width: 30px;
  height: 30px;
}
